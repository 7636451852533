<template>
<svg version="1.1" id="logo-mutschellenfit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 2583 677.3" style="enable-background:new 0 0 2583 677.3;" xml:space="preserve">
<path class="st0" d="M1848.6,249.6c-1.6-2-5.1-1.4-8.2-1.9c-1.4,0-2.7,0-4.1,0.1c-5.1,0-13.7-1.1-15.7,2.2
c-1.4,2.2,0.3,11.7-0.2,15.4c-1.7,11-2.1,21.6-4.2,32.2c-3.6,18.2-6.3,37-10.3,55.1c-1.8,8.3-2.8,17-4.7,25
c-1.9,8.1-2.6,16.1-4.4,24c-3.5,14.7-5.7,33.6,2.5,44.8c5.8,7.9,19.9,12,34.4,9.7c5.1-0.8,12.2-0.3,14.6-3.5
c2.1-2.7,0.4-15.4-1-18.6c-0.4-0.9-1.3-1.8-2.3-2.2c-1.8-0.7-4.1,0.9-5.6,1.2c-6.7,1.3-12.7-0.7-15.1-4.2
c-4.8-6.9,0.9-26.8,2.5-34.7c4.7-22.8,7.8-45.9,12.7-68.3c1.8-8.2,2.5-16.4,4.3-24.5c1.7-7.8,2.2-15.2,3.7-23.4
c0.7-3.7,2.1-9,1.4-13.2c-0.7-4.5,2.4-11.4-0.5-15.1L1848.6,249.6z M1495.8,308c-7.1,1.8-13.4,4.2-18.6,7.9
c-2.7,1.9-4.9,4.3-7.4,6.4c-2,1.6-4.2,3.5-5.8,5.6c-0.4,0-0.9,0-1.3-0.1c-0.3-0.2-0.6-0.4-0.8-0.6c-0.7-3,0.6-6,1.2-8.5
c0.7-3.3,0.9-6.5,1.6-9.5c2.9-12.6,4.2-25.2,6.4-38.6c0.8-4.7,2.2-19-1-21.6c-2.7-2.2-15.7-1.3-20.9-1.2c-3,1.1-5.8-0.2-6.8,2.9
c0.3,3.2,0.6,6.5,0.8,9.7c-0.6,3.8-0.2,8.4-1,12.6c-1.2,7.8-2.3,15.5-3.5,23.3c-9.2,52-18.4,104.1-27.6,156.1
c2.3,4.5,15.8,3.4,21.6,2.4c3.5-0.6,5.7,0.4,7.1-2.4c1.5-9.1,3-18.2,4.6-27.4c5.6-27.6,8.2-55.4,21.5-75.4
c5.4-8.1,11.4-13.4,21.8-16.6c2.1-0.6,5.6-1.3,8.6-0.8c6.8,1.1,12.3,3,15.6,7.6c4.9,6.7,3.2,20.4,1.3,29.4
c-3.6,20.4-7.1,40.7-10.7,61.1c-1,5.4-1.9,10.7-2.9,16.1c-0.3,1.6-1.7,4.3-1.1,6.2c1.2,4,17.6,3.3,22.6,2.4c2.4-0.5,4.3-0.1,5.5-1.7
c1.6-2,1.3-6.5,1.9-9.4c1.3-7.6,2.6-15.3,4-22.9c3.7-20.6,7.4-41.2,11-61.8c2.3-13.7,1.3-28.9-4.4-38
C1532,310,1514,303.5,1495.8,308L1495.8,308z M1747.8,387.3c1.6-7.2,2-13.9,3.6-21.2c3.9-18,6.8-37.1,10.6-55.2
c3.1-14.6,4.5-28.6,7-44c0.6-3.8,1.2-14.8-0.5-17.2c-2.3-3.1-12.1-2-17.4-1.9c-3.4,1.3-8.2-1-10.3,1.8c-1.7,2.2,0,14.4-0.7,18.4
c-2.3,12.8-2.8,25.7-5.5,37.9c-6.4,29.4-10.6,59.7-16.7,89c-2.4,11.5-7.3,29.1-3.4,42.2c1.4,4.6,3.8,8.1,6.5,11.4
c4.5,5.6,13.3,6.5,22.1,7.9c1.4,0,2.7,0,4.1-0.1c5.6,0,10.9-0.6,15.5-1.6c1.2-0.2,2.5-0.4,3.7-0.6c1.6-0.8,2.6-2.5,3-4.6
c0.8-4.6-1.7-17.2-4.3-18c-1.9-0.6-4.5,1-6.2,1.3c-2.1,0-4.2,0-6.2-0.1c-1.6-0.3-2.7,0-4-0.5c-3.8-1.4-5.8-5.3-6.6-9.8
c-0.7-4.3,0.9-8.8,1.7-12.2c1.7-7.8,2.5-15.4,4.2-22.9L1747.8,387.3z M869.9,251.5c-1.3,0-2.6,0-3.8,0.1c-3.8,0-8.7-0.4-11.3,1.1
c-2.4,1.4-3.3,4.7-4.8,7.1c-3.5,6.2-7,12.3-10.4,18.5c-12.3,20.8-23.9,42.2-36.4,63c-4.1,6.8-7.8,13.6-11.8,20.4
c-1.6,2.8-4,9-6.6,10.4c-3.4-0.2-3.1-4.6-3.8-7.4c-1.5-6.8-3-13.7-4.6-20.5c-6.1-22.3-9.6-45.7-15.1-68.3
c-1.2-5.2-2.4-10.3-3.6-15.5c-0.7-2.8-0.4-6-2.4-7.4c-1.5-1.1-4.8-1-7-1.3h-6.7c-4.9,0-10.6-0.6-14.8,0.6c-0.8,0.2-1.8,1.1-2.3,1.7
c-1.8,2.4-2.7,12-3.6,15.7c-3.3,13.2-5.3,26.5-8.6,39.8c-7.9,31.6-13.3,64-21.1,95.8c-2.4,11-4.8,21.9-7.2,32.9
c-1.1,4.6-3.2,9.2-3.1,14.5c0.6,0.7,1.3,1.9,2.2,2.3c3.9,1.8,22.7,1,24.5-1.3c2.2-2.8,3.3-14.4,4.4-18.7c2.5-9,3.3-18.3,5.6-27.6
c5.3-21.5,9-44.2,14.4-65.8c1.6-7.6,3.2-15.2,4.8-22.8c0.6-2.4,1.2-7.8,2.6-9c0.7,0,1.4,0,2-0.1c1,1.8,1,4.5,1.6,6.7
c1.3,5.6,2.6,11.2,3.8,16.8c4.1,18,8.2,35.9,12.2,53.9c1.6,6.6,2.7,12.9,4.3,19.4c0.8,3.4,0.7,6.4,4.2,7.1
c4.5,0.9,16.2,1.1,19.4-0.8c2.3-1.4,3.4-4.5,4.8-6.8c3.5-5.8,6.6-11.6,10.1-17.5c10.8-18.5,21.6-37.3,32.5-55.8
c3.2-5.4,6-10.8,9.1-16.1c1.1-1.9,2.3-5.4,4.1-6.5c0.4,0,0.9,0,1.3-0.1c1.3,1.8,0.8,6.1,0.2,8.8c-1.4,7-1.5,14.1-2.9,21.4
c-3.9,28.4-7.8,56.8-11.8,85.2c-1,7-1.9,13.9-2.9,20.9c-0.4,2.1-1.3,5.9-0.2,7.3c1.6,2.2,4.4,1.3,7.8,1.8c3.7,0.6,14.7,0.6,17.8-0.4
c3.7-1.2,2.4-7,3.4-11c2.7-11.9,2.5-24.9,4.9-37.2c0.4-3.4,0.8-6.9,1.2-10.3c0.6-3.1,1.3-6.2,1.9-9.2c0.9-4.6,0.4-8.8,1.2-13.2
c1-5.3,1-11.6,2-17.3c3.5-19.7,4.5-40.6,7.9-60.6c1.7-9.9,2.7-20.5,4-30.6c0.4-3.6,0.7-7.2,1.1-10.8c-1.6-4.3-9.9-2.3-14.8-3
L869.9,251.5z M1155.5,322c0.4-1.6,1.3-7.4,0.6-9.5c-0.9-2.6-4.2-2.5-7.9-2.4h-18.7c-2.1,0-5.9,0.7-7.2-0.2c-2.3-1.6-1-5.5-0.4-8.3
c0.6-4.2,1.3-8.5,1.9-12.7c0-1,0-1.9-0.1-2.9c0.4-2.3,0.9-4.8,0.5-7.3c-0.6-3.7,0.5-9-3.6-9.2c-2,0.9-4.6,0.4-7,0.8
c-5.2,0.9-16.7,2.4-18,6.7c0,3.1,0,6.2,0.1,9.4c-0.9,5.4-1.2,11.1-2.3,16.1c-0.6,2.7-0.1,5.6-1.9,7c-3,2.4-14.3-0.3-19.3,0.7
c-5.1,1.1-4.9,9.4-5.9,14.6c-0.2,2.1-0.3,4.2-0.5,6.2c0.2,0.8,1.5,2.1,2.3,2.4h12c1.9,0,3.8,0,5.8-0.1c3.8,1.2,0.8,9.4,0.1,12.5
c-3.8,21.7-7.7,43.4-11.5,65.2c-1.7,9.5-2.3,23.5,1.8,30.7c5.8,10.1,14.2,12.3,27.8,14.5h7c6.5,0,12.2,0,17.5-1.1
c2.3-0.5,5.5-0.6,6.8-2.2c2.1-2.4,1.3-16.2,0-19.4c-1.5-3.7-7.9-1.3-11.5-0.6c-8,1.4-16.1-0.4-19.4-4.8c-3-3.9-2.6-16.2-1.1-22
c2-7.5,2.4-15.2,4.2-23.3c1.8-9.4,3.7-18.9,5.5-28.3c1-4.6,1.5-9.4,2.4-13.8c0.2-1.7,0.3-3.4,0.5-5c1.2-3,5.4-2.4,9.6-2.4h18.1
c2.4,0,4.8,0,7.2-0.1C1154.3,332.2,1154.6,325.7,1155.5,322L1155.5,322z M1257.6,311.2c-7.5-2.3-16.4-4.2-25.8-4.6
c-2.9,0-5.8,0-8.6,0.1c-6.6,1.2-13.4,1.4-19.2,3.5c-13.8,4.9-24.9,13.2-30,26.6c-1.2,3.1-1.2,6.3-1.9,10c-0.9,4.7-0.6,11.4,0.8,16.1
c1.3,4.3,3.6,8.1,6.1,11.3c6.6,8.5,20.6,13.5,31.2,18.1c8.7,3.8,18.4,8.3,15.8,22.9c-1.8,10.2-8.8,15.1-18.2,17.5
c-2.8,0.3-5.5,0.6-8.3,1c-3.8,0.7-8.7,0.7-12.5,0c-3.9-0.7-7.4-0.3-10.9-1.1c-3.9-0.9-8.6-1.8-12.2-3.1c-1.3-0.5-3.3-2.1-5-1.7
c-1.9,0.5-2.5,2.3-3.2,4c-1.5,3.5-7,16.9-2.2,19.7c3.1,1.8,7.3,2,11,3c9.5,2.4,24.9,4.6,37,2.5c4.5-0.8,8.4-0.6,12.5-1.7
c18.1-4.5,31.8-13.6,38.4-29.6c2-4.8,4.9-15.4,3.6-23c-3.5-20.8-16.3-26.5-33-33.7c-9.1-4-25.1-9.3-22.1-23.9
c3.2-15.9,26.3-17.4,43.4-13.3c3.8,0.9,13.2,5.3,15.8,3.2c1.8-1.4,2.7-4.9,3.7-7.2c0.8-1.9,4-9.2,3.2-11.5
C1265.8,312.9,1261,312.3,1257.6,311.2L1257.6,311.2z M1390.7,309.6c-7.8-2-16.1-3.1-26.4-2.9c-2.1,0.2-4.2,0.4-6.2,0.6
c-3.3,0.6-6.6,1.2-9.8,1.8c-11.8,3.5-20.7,7.9-29.2,14.5c-17.1,13.5-26.6,32.2-32.8,56.8c-0.4,3.1-0.8,6.2-1.2,9.2
c-1.2,6.5-1.3,13.6,0,20.4c0.9,4.6,1.1,8.6,2.4,12.6c4.8,15.2,15,26.4,30,31.3c14.4,4.7,37.4,4.6,52.6,0.7
c4.5-1.1,10.6-1.3,12.7-4.8c1.6-2.6-1.6-14.8-2.4-16.9c-0.6-1.5-2-4.2-4.2-4c-1.7,0.6-3.4,1.2-5.2,1.8c-6.2,1.8-12.9,3-20.9,3h-5.3
c-18.6-3.1-25.9-9-29.8-27c-0.8-3.6-1.6-9.5-0.8-14.2c1.2-7.2,1.5-14.1,3.5-20.5c6-19.4,19.4-36.9,40.7-40.9
c8.2-1.6,18.5-0.5,25.3,1.8c2.5,0.8,7.5,3.4,10,1.8c3.5-2.2,5.3-11,6.6-15.7c0.7-2.6,0.9-5.5-1-7c-2.1-1.7-5.6-1.8-8.6-2.5
L1390.7,309.6z M1690.8,327c-5.8-13.4-21.4-20.6-41-20.3c-2.6,0.3-5.2,0.6-7.8,1c-5.8,1.2-11.3,2.1-16.2,4.2
c-22.4,9.5-38.1,27.3-47.5,49.8c-5.9,14.2-9.6,36.1-5.6,54.5c0.6,2.8,0.7,5.4,1.7,7.8c5.6,14.3,15.6,25.4,30.6,30.1
c17.8,5.6,42.4,3.5,59.8-0.8c4.6-1.2,13.4-2.5,15.2-6c1-1.8-1.5-13.8-2-15.6c-2.2-7.2-6.8-4.5-12.6-2.6c-5.2,1.6-10.8,3.2-16.6,4.3
c-2.7,0.5-5.3,0-8.3,0.6c-1.9,0.2-3.8,0.4-5.8,0.6c-5.3-0.9-11-1-15.5-2.8c-12.6-5-17.2-16-19.4-31.4c-0.7-4.7,12.6-4.7,16.7-5.5
c17.5-3.4,36.2-6.6,49.4-14.4c10.4-6.1,20.1-13.1,24.8-24.7c1.1-2.8,1.7-6.1,2.3-9.4c0.7-3.7,0.3-8.6-0.4-11.8
C1692,331.8,1691.8,329.4,1690.8,327L1690.8,327z M1664.3,347.1c-0.6,2.7-1.1,4.8-2.4,6.7c-7,10.4-23.6,14-37.7,17.6
c-4.3,1.1-9,1.8-13.7,2.8c-1.8,0.2-3.6,0.5-5.4,0.7c-0.9-0.3-1.5-1.5-2-2.2c0.5-4.8,3.2-9.2,4.9-13.1c4.8-10.8,14.3-22.3,25.1-27
c2.6-0.8,5.2-1.7,7.8-2.5c1.6-0.2,3.1-0.5,4.7-0.7c11.1,0,21.5,5.7,18.7,17.6V347.1z M1989.7,321.3c-7.8-10.7-20.8-14.9-39.7-14.6
c-3.8,0.6-7.7,1.3-11.5,1.9c-5.4,1.4-10.5,3.1-15.1,5.2c-3.9,1.7-7.4,4.5-10.7,6.8c-15.4,10.9-25.9,25.7-33.4,44.5
c-2.2,5.6-3.5,12.6-4.8,18.8c-1.4,6.9-1.8,15.2-0.7,22.3v3.8c0.8,4.3,1.3,9.1,2.5,13.1c4.6,15.1,16.7,26.5,31.8,31.1
c17.3,5.3,41.6,3.3,58.3-1c4.6-1.2,14-2.7,15.7-6.2c1-2-0.8-11.2-1.3-13.1c-0.8-2.7-1.8-6.9-4.7-7.4c-1.6-0.3-11.2,3.3-13.3,4
c-9,2.8-22.2,5.3-33.5,3.1c-17.5-3.3-24.4-14.3-27.4-32.2c-0.4-2.4,0.9-4.1,2.3-4.7c5.4-0.8,10.7-1.6,16.1-2.4
c18.7-3.3,37.2-7.3,51.1-15.7c14.2-8.6,29.5-25.4,22.4-48.6c-1-3.4-2.3-6.2-4.2-8.8L1989.7,321.3z M1966.3,349.5c-0.7,2-1.8,4-3,5.5
c-7.8,10.1-25.3,13.7-40.2,17c-4.8,1.1-10.4,2.7-15.7,2.6c-0.8-0.7-1.3-1.3-1.9-2.2c0.5-4.7,3.1-9.9,5.2-13.4
c5.4-9.3,10.5-16.6,19.1-22.7c1.8-1.3,3.5-2.8,5.6-3.7c2.9-0.9,5.8-1.8,8.6-2.6c1.3-0.2,2.6-0.4,3.8-0.6c3.7,0,7.3,0,10.1,1.2
c6.1,2.5,11.6,9.6,8.4,18.8L1966.3,349.5z M2111.1,306.7c-3.1,0.3-6.2,0.6-9.4,1c-7.6,1.9-14,4.8-19.8,8.4c-4.8,3-9.9,7.3-13.4,11.6
c-1.6,2-3.3,3.9-4.9,5.9c-0.4,0-0.7,0-1.1,0.1c-0.3-0.2-0.6-0.5-0.8-0.7c-0.4-1.8,0-4.3,0.2-6c0.6-3.4,0.1-8.1-0.4-11
c-0.2-1.5-0.3-3-0.5-4.4c-2-4.2-12.6-2.7-18-2.6c-3.3,0-6.9-0.1-8.3,1.8c-1.5,2,0.7,8.2,0.1,11.9c-1.3,8.3-0.9,16.5-2.6,24.5
c-4,22.6-8,45.1-12,67.7c-2.3,12.6-4.6,25.2-6.8,37.8c0.7,0.8,1.4,2,2.5,2.4c5.2,1.6,24.6,0.8,26.3-2.4c0.4-2.8,0.9-5.6,1.3-8.4
c1.6-7.6,2.1-15.6,4-23.2c1.6-8.4,3.1-16.9,4.7-25.3c4.3-18.3,10.2-34.8,20-47.5c4.9-6.4,16.4-18.3,28.8-16.2
c15.7,2.7,18.6,14.8,15.4,32.4c-3.7,21.3-7.4,42.6-11,64c-1.1,6-2.2,12-3.4,18c-0.3,1.7-1.6,5.3-0.7,6.8c0.7,0.6,1.4,1.3,2,1.9
c3.8,1.4,16.2,0.8,20.3,0.1c1.1,0,2.2,0,3.4-0.1c3.7-1.1,3-7.1,4-11c2.7-11.7,3.7-23.5,6.1-35.6c2.8-14.4,5.3-29.5,8.2-44
c1-5.4,1.3-11.5,2.3-16.7c0.9-4.7-0.4-10.3-1.2-14c-3.6-17.2-14.4-27.2-35.2-26.9L2111.1,306.7z M1041.9,309.4c-0.9,0-1.8,0-2.8-0.1
c-4.9-0.8-17.2-1-21.2,0.6c-0.7,0.6-1.4,1.2-2,1.8c-1.6,2.3-1.5,9.1-2.3,12.4c-2,11.6-4.1,23.2-6.1,34.8
c-4.1,20.5-9.2,38.2-18.6,53.2c-1.5,2.4-3.5,4.3-5.2,6.5c-6.6,8.6-22.1,20-36.2,11.2c-7.4-4.7-8.5-16.7-6.5-28.1
c3.8-21.4,7.6-42.8,11.4-64.2c1.2-6.6,2.3-13.3,3.5-19.9c0.5-1.9,1.3-5.4,0.1-7.1c-1.5-2-7.1-1.9-10.6-1.8c-1.4,0-2.8,0-4.2,0.1
c-4.4,0-10.5-0.2-12.5,2.4c-1.7,2.2-1.4,6.8-2,10c-1.3,7.4-2.6,14.9-4,22.3c-1.2,5.4-1.4,10.9-2.8,16.6c-3.7,15.7-5.8,32-8.6,48.4
c-2.2,12.9-0.4,28.7,5.2,36.6c7.2,10.1,25.3,16.3,42.6,11.2c13.1-3.9,28.9-14.8,34.8-25.7c0.5,0,1,0,1.4,0.1
c1.1,0.6,1.1,2.5,0.8,4.1c-0.7,4.1-1,10.4-0.2,14.6c0.4,2.2,0.6,4.1,2,5.2c3.2,2.5,14.9,1.9,19.4,1c1.6-0.3,3.5,0,4.6-0.8
c1.5-1.1,1.4-6.6,1-9.6c-0.8-5.1,0.3-11.1,1.1-15.6c0.1-1.8,0.2-3.5,0.4-5.3c3.9-20.6,6.5-41.3,10.9-61.4c1.7-7.6,2.2-15.2,4-22.6
c1.8-7.6,2.3-15.4,4.1-22.8c0.7-3.1,1.3-6.6-1.4-7.8L1041.9,309.4z M2251.9,362.5c-0.5-1.8-2.3-2.5-4.8-2.4
c-21.7,0-43.4,0.2-65.2,0.2c-3.7,1.7-3.5,9.2-4.6,13.6c-0.4,1.6-1.8,9-1.3,10.7c1.3,4.4,10.7,1.6,15.7,2.4c13.9,0,27.8,0,41.8,0.1
c4.9,0,12.2,1,14.3-1.9c0.9-1.2,0.9-3.1,1.3-4.7c1.2-4,1.5-8.5,2.4-13.2C2251.7,366.2,2252.3,364,2251.9,362.5L2251.9,362.5z"/>
<path class="st1" d="M1547.4,496.8c5.9,0,11.4,5,9.1,11.6c-1.9,5.4-6.3,9.4-9.2,14c-3.9,6.2-6.8,12.9-9.5,20.4c-1.1,3-5,14-1.2,16.2
c1.3,0.7,4.7,0.2,6.5,0.2h15.4c4.7,0,9,0.1,13.4-0.6h19c8.6,0,18.5,0.7,26.2-0.6h7c2.1-0.1,4.2-0.2,6.2-0.4c1.5-0.3,3.1-1.7,5-1.3
c3.2,0.6,7.2,3.6,6,8.4c-0.6,2.2-3.8,4.8-7,4c-1.8-0.5-2.9-2-4.3-2.9c-2.1-1.3-6.6-1.8-10.2-1.2c-7.7,1.3-18,0.8-26.6,0.8h-10.1
c-7.3,1.3-16.7,1.8-24.5,3.1c-3.5,0-7,0-10.6-0.1c-1.8,0.2-3.5,0.4-5.3,0.6h-7.4c-2.6,1-3.3,6.7-2.6,10.7c0.7,4.6,0.6,8.9,1.9,13.1
c0.8,1.7,1.5,3.4,2.3,5.2c0.9,2.2,1.2,4.8,4.3,4.8c2.7-1.4,8.4-5.1,10,0.5c0.9,3.3-2.1,6.2-3.2,7.8c-4.7,6.3-10.2,11.2-18.4,4.6
c-7.7-6.2-6.2-19.4-6.2-32.6c0-4.4,0-8.8-0.1-13.2c-1.1-2.1-5.5-3-9-2.2c-5.9,1.5-10.2,5.7-15.4,7.9c-10.7,4.7-20.1,10.7-29.5,16.4
c-1.5,0.8-3,1.7-4.6,2.5c-4.9,3.5-9.4,7.5-14.3,11.2c-5.5,4.2-10.3,8.7-16.3,12.5c-2.3,1.5-5.5,3.5-9.7,2.6
c-12.7-2.6-10.7-22.8-8.2-35.3c0.2-2.2,0.5-4.3,0.7-6.5c0.4-2,2.7-10.6,1.8-12.2c-0.6-0.6-1.1-1.2-1.7-1.8c-1.8-0.8-14-0.9-16.8-0.5
c-6.4,1.1-13.9,0.9-20.2,1.9c-3.8,0.2-7.6,0.3-11.4,0.5c-8.1,1.4-15.2,2.5-23.2,3.8c-2,0-4,0.2-6,0.2c-4.9,0.9-11.3,1.2-16.3,2.2
c-5.6,0.4-11.1,0.9-16.7,1.3c-2.9,0.5-6-1-7.9,0.5c-2.9,2.3-2.3,10-3.2,14.6c-1,4.9-0.3,11.7-1.2,17.2v5.3c-0.8,5-0.7,11.5-0.7,17.5
v7.3c0.6,1.9,1.1,3.8,1.7,5.8c-0.5,6-10.5,9-13.2,2.5c-1.3-3,0.5-6.8,1.1-9.5c1.2-5.7,0.6-13.4,1.6-19.2c0-2,0-3.9,0.1-5.9
c0.8-4.6,0.5-10.3,0.5-15.6v-7.4c0.7-4.2,0.1-9.9-0.5-13.4c0-1.5,0-3,0.1-4.6c-0.9-1.7-1.8-3.4-2.8-5.2c-0.5-2.5,1.1-4.4,1.6-6.1
V554c0-8.2-0.1-15.5-1.3-22.7c-0.9-5.2-1.1-9.5,3.4-10.7c3.8-1.1,7.8,1,10.4,1.9c4,1.4,6.7,3,10.3,4.4c3.2,1.2,6.5,1.7,10.3,2.3
c3.1,0.5,9.7,0.7,12,0c1.9-0.6,4-3,6.5-2c2.1,0.8,6.7,6.7,5.4,10.6c-1.8,5.5-8.3,0.3-13,1.1c-5.8,0.9-10.6,1.7-16.3,2.6
c-3.5,0.6-6.5,0.1-8.2,2.4c-0.3,0.5-0.6,1-0.8,1.6c-0.1,2.6-0.2,5.3-0.4,7.9c-0.3,2-0.6,3.9-1,5.9c0.2,0.8,1.4,2,2.2,2.3
c1.4,0,2.9,0,4.3,0.1c4.5,0.7,9.6,0.7,14.3,0.4c1.5,0,3,0,4.4-0.1c4,0.6,10.9,0.8,15.1,0.1c2.8,0,5.7,0,8.5,0.1
c3.1,0.4,6.6-0.6,9.1-1.1c1.8,0.1,3.5,0.2,5.3,0.4c10.6-1.9,22.7-1.1,32.5-2.9c1.2,0,2.4,0,3.6,0.1c3.7-0.6,7.5-1,11.6-1h8.3
c4.8-0.9,8.7-4,14-0.5c3.2,2.1,5.3,7.4,4,13.2c-0.8,3.3-2.7,6-4,9c-1.3,5-2.6,9.9-4,14.9c-0.9,3.7-2.8,15.1,3.2,13.9
c2.6-0.5,4.5-2.4,6.5-3.6c3.7-2.4,7.4-4.7,11.2-7.1c2.2-1.9,4.4-3.8,6.6-5.6c8.8-5.6,17.6-11.1,26.4-16.7c4.4-2.6,9.3-4.4,13.7-7
c5.4-2.7,10.7-5.4,16.1-8c3-1.3,6.6-2,8.2-4.7c0.6-1.1,0.3-2.9,0.6-4.3c0.6-4.5,1.2-9,1.8-13.4c2.8-13.2,6.6-32.2,15.8-38.8
c1.2-0.6,2.5-1.3,3.7-1.9c1.1-0.2,2.2-0.3,3.2-0.5L1547.4,496.8z"/>
<path class="st1" d="M1717.5,525.2c4.6,0,10,6.7,9.1,11.3c-0.6,3.1-3.7,6.5-7,6.8c-4.6,0.5-13.4-7.3-11.4-12.1
c1.7-4.1,5.2-4.2,9.2-6H1717.5z"/>
<path class="st1" d="M1435.9,525.4c9.6-0.2,13.5,15.9,2.9,17.9c-5,1-13.6-7.5-11.5-12.6C1428.9,526.9,1432.2,527.1,1435.9,525.4
L1435.9,525.4z"/>
<path class="st1" d="M2384.8,556.4c-2.8,0.7-4.9,3.4-7.4,4.7c-4.5,2.3-9.9,3.5-14.8,5.6c-7.6,3.3-14.3,8.1-21,12.2
c-13.5,8.5-26.6,16.8-41.2,24.2c-5.3,2.7-10.7,5.5-17,7.1c-2.3,0.6-6.1,1.1-8-0.1c-5.2-3.2-1.3-14.3,2.2-16.8
c2.1-1.6,8.6-1.6,11.4-2.5c10.6-3.3,19.8-8.7,25.3-17c1.9-2.9,5-7.7,3-12.6c-2.4-5.8-11-10-20.3-8.3c-4.2,0.8-7,3.2-11.2,4.2
c-2.5,0.3-5,0.6-7.6,1h-4.8c-1.8,0.4-3.5,0.9-5.3,1.3c-4.2,0.8-9.1-0.1-12.6-0.7h-19.6c-2.7-0.5-6.5-1-9.7-0.5
c-1.9,0.3-5.4,0.8-6.7-0.1c-2.6-1.9,0.5-13.5,1.3-16.9c1.4-6,2.8-13.1,5.8-17.8c2.7-4.3,7.1-6.9,8.5-12.5
c2.7-10.5-0.9-19.4-11.8-19.2c-6.1,2.7-10.2,6-12.5,12.4c-0.9,2.4-0.5,5.2-1.1,8.4c-0.7,4.3-1.2,9.2-1.9,13.7
c-0.7,4.1,0,7.8-0.6,11.5v7.3c-0.3,2.4-0.6,4.7-1,7.1c-0.1,1.3-0.2,2.6-0.4,3.8c-2.2,4-12.7-0.7-16.2,4c-1,1.4-0.3,4.1-1.4,5.5
c-0.8,1.1-2.4,1.3-3.6,2c-4.3,2.5-8.8,4.7-13.4,7.1c-9.9,5-19.4,11.3-28.3,17.6c-2.3,1.9-4.6,3.8-6.8,5.8c-3.4,2.6-7.1,4.6-10.8,7
c-1.7,1.1-3.4,2.5-5.5,3.1c-7.3,2.2-5.6-8.7-4.4-13.3c1.3-4.8,2.6-9.7,3.8-14.5c1.3-3.3,3.3-6.3,4.2-10c1.8-7.5-2.1-13.4-7.8-14.5
c-6.4-1.2-7.8,3.3-10.9,5.6c-1.5,1.1-7.9,3-10.1,3.5c-5.4,1.2-9.1,2.4-13.4,4.1c-2.7,0.9-5.4,1.8-8.2,2.6c-4.4,2.2-8.8,4.5-13.2,6.7
c-3.6,1.6-7.1,3.2-10.7,4.8c-2,1.3-4,2.6-6,4c-3.3,1.6-6.6,3.3-9.8,4.9c-4.5,2.8-9,5.6-13.4,8.4c-2.4,1.2-4.7,2.4-7.1,3.6
c-3.4,2.1-6.7,4.2-10.1,6.2c-3.1,1.6-6.2,3.2-9.2,4.8c-2.1,1.4-4.2,2.7-6.2,4.1c-0.5,0.1-1.1-0.2-1.6-0.4c-2-3,0.4-16,1-19.3
c0.9-5.8,1.8-11.7,2.6-17.5c1.1-4.3,2.8-8.1,4.1-12.1c0.9-2.8,1-5.7,1.8-8.8c0.9-3.5,1.8-6.6,2.5-10.8c1.5-8.6,3.8-22-3-26
c-1.1-0.4-2.2-0.8-3.4-1.2c-10.3-2.3-10.8,6.5-12.2,13.8c-0.2,1.6-0.5,3.1-0.7,4.7v8.5c-1.1,6.3-0.7,14.6-0.7,21.6v13.1
c-0.9,5.6,0,12.3-1.1,18.4c-0.8,5.1-1.3,10.8-1.3,16.6s0,10.5-1.1,15.4c-1.3,6.3-5.3,12.6,1.2,16.6c3.2,1.9,7,0,8.6-2
c2.1-3,4.2-6.1,6.4-9.1c2.4-2.6,4.9-5.2,7.3-7.8c1.7-1.9,3.4-3.8,5-5.8c2.8-2.3,6.1-4.7,8.4-7.6c1.4-1.6,2.8-3.2,4.2-4.8
c3.8-3,7.7-5.7,11.6-8.6c1.6-1.4,3.2-2.8,4.8-4.2c3.4-2.4,7.2-3.9,10.8-6.1c2.8-2,5.7-4.1,8.5-6.1c3.6-2.3,7.6-3.8,11.4-6
c1.2-0.7,2.3-1.4,3.5-2.2c9.4-4.1,19.5-7.6,29.4-10.9c1.9-0.5,3.8-1,5.8-1.4c4.4,0.2,1.8,7.2,1.2,9.8c-0.2,1.5-0.3,3-0.5,4.4
c-2.5,12.7-4.8,33,6.4,37.6c2.5,1,6.5,0.4,8.5-0.6c6.4-3.2,11.4-8.2,16.8-12.5c8.1-6.4,16.2-12.9,25.1-18.5
c4.2-2.4,8.4-4.8,12.6-7.2c2.3-1,4.6-2.1,6.8-3.1c6.8-4,14-8.6,21.7-11.4c1.4,0,2.7,1,3,2c-0.1,1.1-0.2,2.2-0.4,3.4
c-0.8,4.5-0.8,9-1.7,12.8c-0.2,1.4-0.3,2.7-0.5,4.1c-0.8,5.3-1.6,10.6-2.4,15.8c-0.1,2.4-0.2,4.9-0.4,7.3
c-0.9,5.3,0.2,11.7-0.6,16.6v29.9c-0.4,2.3-1.7,3.9-2.3,6c-1.5,5,1,9.8,4.2,11.3c5.3,2.3,13.5-2.1,12.5-7.9
c-0.6-2.6-1.3-5.1-1.9-7.7c-1.3-6.6-1.2-13.6-2.3-19.9c0-3.2,0-6.5-0.1-9.7c-0.3-1.5-0.6-3-0.8-4.6c0.3-2.5,0.6-5,0.8-7.6
c0-11.1,1.8-20.8,4-30.1c1.2-5.3,1.8-18.9,5.4-21.1c1.7-1.1,6-0.1,8.2-0.6c4.2-0.9,9.6,0.6,14.4-0.2c6.5-1.1,16.6-1.3,23.5-0.1
c3.4,0.6,8.1-1.8,10.9,0.1c2.6,1.7-3.9,10-5,12.1c-5,9.1-13.4,31.5-1.9,40.4c8.9,6.9,21.3,0.7,28.3-3.7c7.1-4.4,13.8-9.5,20.8-13.8
c5.2-3.1,10.4-6.2,15.6-9.2c9.3-6.3,18.6-13.5,29.4-18.2c3.8-1.7,7.6-3.1,11.5-4.8c1.9-0.8,3.7-1.8,5.9-2.3c5.2-1,7.7,3.1,11.5,0.2
c0.9-0.7,1.8-1.5,2.2-2.6c1.3-4.1-2.6-9.8-6.7-8.8L2384.8,556.4L2384.8,556.4z M2290.1,574.2c2.7-2.1,4.9-4.8,7.9-6.6
c1.7-0.7,3.4-1.4,5-2c2.1,0,2.9,0.7,3.5,2c0,2.6-2.2,3.8-3.4,5.3c-3.5,4.5-7.8,7.8-13.4,10.2c-1.5,0.6-4.1,1.9-6.2,1.3
c-0.9-0.4-1.1-1.1-1.4-2.2C2283.9,578.9,2287.3,576.4,2290.1,574.2L2290.1,574.2z"/>
<path class="st1" d="M1916.9,561.3c-0.4-1.6-1.3-2.5-2.3-3.5c-4-3.9-7.6,1.3-11.2,2.4c-3.9,0.8-7.8,1.5-11.6,2.3
c-15.8,4.4-30.4,10.5-45,16.6c-6.2,2.5-12.4,5-18.6,7.6c-3.2-1.3-3.5-5.7-4.6-9.1c-0.8-2.2-1.5-4.3-2.3-6.5c-0.6-2-1.9-5.6-1.1-7.9
c2.1-5.9,13.6-6.8,17.2-11.6c3.6-4.9-0.3-9.2-6.1-9.1c-1.6,0.3-3.3,0.6-4.9,0.8c-4.2,1.3-8.6,2.9-12.1,5c-3.9,2.4-7.2,7.2-11.3,9
c-21.8,9.7-42.5,20-61.1,32.9c-7,4.8-13.2,10.2-20.6,14.8c-1.4,0.9-6,4.3-8.5,3.2c-3.9-1.7-2.5-10.3-1.2-14.2
c1.3-3.6,1.8-7.8,3-11.6c1.6-3.9,3.2-7.8,4.8-11.6c2.2-7.8-2.2-14.1-8.4-15.1c-3.8-0.6-6.8,1.4-8.2,3.4c-2.2,3.3-2.2,8.3-3.2,12.8
c-2,9.2-6.3,32.1-2.4,41c0.8,1.8,2,3.5,3.2,4.8c8.6,8.9,18.8-2.3,25-6.7c7-5,13.3-11.4,20.5-16.2c10.6-7.1,22-13.1,33.8-19.1
c4.7-2.4,8.9-5,13.9-7.3c1.2-0.6,4.7-2.1,6.4-1.4c2.4,1,2.5,4,3.5,6.4c2.2,5.5,4,10.1,5.2,16.8c0.3,1.9,0.8,6.5,0,8.3
c-1.9,4-7.9,6.2-11.4,8.6c-6.3,4.4-12.1,9.2-16.7,15.4c-3.3,4.4-6,11.3,0,15c4.6,2.8,13.5,1.3,18.1-0.2
c12.1-4.1,24.7-13.2,29.3-24.8c1-2.5,2.4-7,1.8-10.3c-0.2-1.4-1.4-3.5-1-5.2c0.6-0.8,1.2-1.6,1.8-2.4c9.1-6.8,21.1-9.8,32.5-14.3
c3.2-1.4,6.5-2.9,9.7-4.3c8.4-3.6,17.1-7.4,27-9.1c2.2-0.4,5.5-0.2,7.1,0.6c1.2,0.6,2.9,2.1,4.8,1.8c3.3-0.6,6.3-3.4,5.2-7.7
L1916.9,561.3z M1805.7,617.6c-2.9,7.1-8.6,10.6-16.2,13.1c-1.3,0-2.1-0.9-2.5-1.9c-0.8-2.3,2.3-5.2,3.2-6.5
c2.8-3.7,6.8-7.3,10.6-10c1.4-0.9,2.9-1.8,4.3-2.8c1,0,1.7,0.4,2,1.1C1808.2,612.1,1806.2,616.2,1805.7,617.6L1805.7,617.6z"/>
<path class="st1" d="M411.1,200.1c39.6-0.5,67.8,9.2,91.7,24.2c11.2,7,21.9,15.9,27.5,28.4c3.4,7.7,5.2,15.7,7,25.6
c0.1,2.2,0.2,4.5,0.4,6.7c0.5,3.3,0.4,8.1-0.1,11.3c-0.2,2.4-0.3,4.9-0.5,7.3c-0.9,5.4-1.5,10.9-2.6,15.8
c-7.3,30.9-17.6,57.4-31.8,81.4c-5,8.4-9.8,16.6-15.6,24.1c-1,1.4-4.6,6.4-6.6,5.5c-0.1-4.6,2.3-8.9,3.7-12.5
c2.3-6.9,4.6-13.8,7-20.6c8.5-27,15.1-65.4,2.8-93.5c-10.1-23.1-31.2-35.3-57.7-42.1c-6.8-1.7-13.7-2-21.2-3.2
c-4.8-0.4-9.5-0.7-14.3-1.1h-13.4c-3.6,0.1-7.3,0.2-10.9,0.4c-2.6,0.1-5.3,0.2-7.9,0.4c-7.2,0.7-14.4,1.4-21.6,2.2
c-10,1.9-19.7,3-28.9,5.8c-19.4,5.8-38,11.3-55.9,18.4c-16.5,6.5-32.6,13.4-48.6,20.4c-7.6,3.6-15.2,7.1-22.8,10.7
c-11.7,5.2-23.1,10.9-34.3,16.7c-8,4.1-16,8.2-24,12.2c-13.4,7-26.8,14.1-40.2,21.1c-22.9,13.4-46.6,25.3-69.2,38.8
c-5.4,3-10.9,6.1-16.3,9.1c-1.9,1-4.4,2.6-6.8,1.1c0.2-2.5,2.8-3.8,4.4-5c5.2-3.8,10.4-7.6,15.6-11.4c21.3-15.2,42.2-30.9,63.6-46.3
c68.5-49.3,139.5-96.2,224.3-129.2c21-8.2,43.2-13.9,67.6-18.8c7.7-1.6,15.8-1.7,24-3c4-0.2,8.1-0.4,12.1-0.6L411.1,200.1z"/>
<path class="st1" d="M728.5,0c-0.2,2.4-2.4,4.2-3.6,5.8c-3.4,4.4-7.4,8.4-11.8,11.9c-4.1,3.8-8.2,7.6-12.2,11.4
c-11.6,9.4-22.3,19.9-34.1,29.2c-9.9,7.8-19.3,16.2-29.4,23.8c-22.8,17.2-46,33.7-71,48.7c-19.1,11.5-39.7,22.5-62.8,29.8
c-9.1,2.9-18.4,4.5-28.9,6.2c-6.4,1.1-18.3,0.7-24.5-0.4c-5.4-0.9-10.5-0.6-15.5-1.6c-15-3-27.9-6.1-38.8-13
c-12.6-7.9-20.6-20.3-24.7-36.7c-1.4-5.4-1.3-11-2.3-17.2c-1.1-7-1.2-16.7,0.1-23.5c1.2-6.5,2.4-13.9,4.4-19.9c0.5-1,1-1.9,1.4-2.9
c0.4-1.7,0.2-2.7,0.8-4.1c0.5-0.9,1-1.8,1.4-2.6c2.3-9.2,6.5-17.4,10.9-24.8c1.3-2.2,3.1-6.9,5.8-7.6v1.7
c-2.4,8.4-3.2,17.2-4.8,26.2c-0.3,4.2-0.6,8.3-1,12.5c-1.9,30.3,10.5,51,28.1,61.9c5.3,3.3,11.4,5.2,17.6,7.7
c14.9,5.8,38.8,7.6,57.2,3.5c17.1-3.8,32.6-8.3,47.3-14.5c7.9-3.3,15.6-7.1,23.3-10.4c9.7-4.3,19.3-9.1,28.2-14.3
c7.4-4,14.7-8.1,22.1-12.1c18.4-11,36.7-21.8,54.4-33.5c15-10,30.1-19.5,44.8-29.8c3.9-2.7,7.8-5.4,11.8-8.2c1.6-1.1,3.2-3,5.6-3.1
L728.5,0z"/>
<path class="st1" d="M480.3,7.3c4.6,0.2,10.1-0.6,14.3,0.4c14.1,3.1,23.8,11,30.2,21.8c2,3.4,3.5,7.6,4.6,11.9
c2.1,8.3,0.8,18.7-1.8,25.3c-6.1,15.5-17.4,25.1-35.5,28.6c-8.7,1.6-17.7-0.8-23.5-3.4c-13.9-6-26.6-19.8-26.6-39.6
c0-2.5,0.2-5,0.2-7.4c0.5-2.5,1.5-4.6,2-6.6c0-0.7,0-1.4,0.1-2c3.5-11.5,14-21.4,24.6-25.9c2.3-1,5.8-1.9,8.2-2.5
C478.2,7.7,479.3,7.6,480.3,7.3L480.3,7.3z"/>
<path class="st1" d="M2453.2,249.1c6.5-0.1,20-1.2,22.1,2.8c0.8,1.6,0.3,5.8,0,7.7c-1,5.9-2.6,20.6-7.1,22.2
c-4,1.5-10.4,0.5-15.2,0.5h-8.5c-3-0.5-5.1-0.1-6.5-2c-1.2-1.7-0.4-6,0-8.3c1-5.5,2.2-19,5.6-21.5c2.3-1.7,6.6-0.2,9.6-1.3
L2453.2,249.1z"/>
<path class="st1" d="M2312.8,252.4c24.8,0,49.7,0.2,74.5,0.2h17.5c3,0,5.7-0.6,7,1.3c1.2,1.8-0.3,11.2-0.8,13.4
c-0.9,3.9-0.9,9-3.7,10.9c-1.9,1.3-10.9,0.5-14.4,0.5c-13,0-26.1,0-39.1,0.1c-5.4,0-10.7,0-16.1-0.1c-0.9,0.2-2.2,1.4-2.5,2.2
c-1.3,2.8-1,7.3-1.8,10.6c-2.6,10.4-3.5,21.8-5.8,32.3c-0.7,3.3-3,11.8,0.5,12.8c4.6,0,9.1,0,13.7,0.1c9.9,0,19.8,0,29.8-0.1
c4.6,0,13.4-1.3,14.6,2.2c0.7,2-1,8.4-1.3,10.3c-0.8,4.2-1.7,12.5-5.2,13.7c-14.4,0-28.9,0-43.3-0.1c-2.7,0-13.1-0.2-14.5,0.7
c-3.2,2-3.9,16.5-4.9,21.4c-2.9,16.6-5.8,33.1-8.8,49.7c-1,4.9-1.6,17.6-4.7,19.9c-1.5,1.1-4.1,0.4-6.5,0.7
c-4.3,0.6-10.7,0.8-15.2,0.1c-1.6,0-3.3,0-4.9-0.1c-3.3-1.2-1.5-7.5-0.7-10.9c2-11.5,4-23,6-34.4c7.2-40.1,14.3-80.2,21.5-120.4
c1.9-8.3,2.9-17,4.4-25.2c1-5.4,0-9.8,4.8-11.8L2312.8,252.4z"/>
<path class="st1" d="M2547,269.1c4.9,0,4.2,6.5,4.2,11.3c0,9.8-2.5,18.4-3.6,27c0.6,0.7,1.1,1.9,2,2.3c7.9,0,15.8,0.2,23.6,0.2
c4.1,0,9.1-0.7,9.8,2.6c-0.3,2.7-0.6,5.4-1,8c-0.7,4.3-1.1,11.6-4.9,12.7c-8,0-15.9,0.2-23.9,0.2c-2.5,0-7.2-0.7-8.8,0.5
c-3.1,2.3-3.3,14.8-4.6,19.7c-1.8,7-2.2,14.3-3.8,21.7c-1.9,10-3.8,20-5.8,30c-0.1,1.8-0.2,3.6-0.4,5.4c-1,5.8-1.6,13,1.6,17.4
c3.1,4.3,12.2,5.9,20,4.4c1.9-0.4,6.8-2.3,8.3-1.8c4.1,1.7,5.9,19.5,1.8,22.7c-3.3,2.5-12.2,2.6-18,2.6c-16.4,0-25.8,0-35.2-7.4
c-7.1-5.7-11.1-18.7-8.6-31.8c3.7-20.8,7.4-41.7,11-62.5c1.4-5.5,2.4-11.7,3.4-17.2c0.3-1.7-1.1-3.3-2.2-3.6
c-4.9-1.6-19.2,2.2-20.3-2.4c0.3-2,0.6-3.9,0.8-5.9c0.9-5.3,1.3-14.7,6.1-15.6c4.1-0.8,16.2,1,18.6-0.7c3.8-2.8,3.7-18.2,4.7-24.5
c0-2.7,0-5.4,0.1-8c1.5-3.7,10.8-5.3,15.5-6.1c3.1-0.5,6.7-0.1,9.2-1.3L2547,269.1z"/>
<path class="st1" d="M2429.4,309.7h28.8c2.2,0,9-0.6,10.3,0.2c0.8,0.5,1.1,1.4,1.7,2.2c-1.3,4-1.4,8.5-2.3,13
c-1.4,7.3-2.5,15-4.1,22.8c-4.6,23.3-7.6,47.7-12.6,70.9c-1.8,8.3-2.4,16.8-4.2,24.8c-0.8,3.7-0.7,8.7-3.2,10.6
c-2,1.5-20.9,2-24.7,1.1c-2.8-0.7-2.4-4.3-1.8-7.2c1.6-7.1,2.4-14.4,3.8-21.7c4.2-21.8,7.8-43.8,11.6-66.1c0.8-3.8,1.5-7.5,2.3-11.3
c0.2-1.9,0.5-3.8,0.7-5.8c0.4-1.7,1.9-5.4,1.2-7.3c-1.4-3.2-7-2.4-11.5-2.4c-1,0-2.1,0-3.1-0.1c-4.2-2.1-0.3-19.4,1.8-22
C2425.4,309.8,2427.1,310.6,2429.4,309.7z"/>
</svg>
</template>