<template>


  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">
    {{ this.templateData.data }}
    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>id: {{ this.templateData.data.id }}</p>
      <p>Invoiceid: {{ this.templateData.data.Invoiceid }}</p>
      <p>Customerid: {{ this.templateData.data.Customerid }}</p>
      <p>Contractid: {{ this.templateData.data.Contractid }}</p>
      <p>Aboid: {{ this.templateData.data.Aboid }}</p>
      <p>Currency: {{ this.templateData.data.Currency }}</p>
      &nbsp;

      <p>Price: {{ this.templateData.data.Price }}</p>
      <p>Rates: {{ this.templateData.data.Rates }}</p>
      <p>Interval: {{ this.templateData.data.Interval }}</p>
      <p>DateInvoice: {{ this.templateData.data.DateInvoice }}</p>
      <p>DateStart: {{ this.templateData.data.DateStart }}</p>

      &nbsp;

      <p>arrInvoice: {{ this.templateData.data.arrInvoice }}</p>
      <p>arrCustomer: {{ this.templateData.data.arrCustomer }}</p>
      <p>arrContract: {{ this.templateData.data.arrContract }}</p>
      <p>arrAbo: {{ this.templateData.data.arrAbo }}</p>
      <p>arrRates: {{ this.templateData.data.arrRates }}</p>
      <p>arrDatesent: {{ this.templateData.data.arrDatesent }}</p>


    </div>
    <!-- /subhide -->

    &nbsp;

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>id: {{ id }}</p>
      <p>invoiceId: {{ invoiceId }}</p>
      <p>customerId: {{ customerId }}</p>
      <p>contractId: {{ contractId }}</p>
      <p>aboId: {{ aboId }}</p>

      &nbsp;

      <p>price: {{ price }}</p>
      <p>rates: {{ rates }}</p>
      <p>interval: {{ interval }}</p>
      <p>dateInvoice: {{ dateInvoice }}</p>
      <p>dateStart: {{ dateStart }}</p>

      &nbsp;

      <p>arrInvoice: {{ arrInvoice }}</p>
      <p>arrCustomer: {{ arrCustomer }}</p>
      <p>arrContract: {{ arrContract }}</p>
      <p>arrAbo: {{ arrAbo }}</p>
      <p>arrRates: {{ arrRates }}</p>
      <p>arrDatesent: {{ arrDatesent }}</p>

    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->
 
  <DxPopup
    v-model:visible="payPopupVisible"
    title="Rate Bezahlen"
    position="center"
    :drag-enabled="false"
    :show-close-button="false"
    :show-title="true"
    :width="500"
    :height="300"
  >
    <div class="grid-x grid-margin-x">
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod === ''">
        <button class="cx-button medium" @click="paymentMethod = 'cash'">Barzahlung</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod === ''">
        <button class="cx-button medium" @click="paymentMethod = 'card'">Kartenzahlung</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod !== ''">
        <button class="cx-button medium" @click="confirmPayment">Zahlung abschliessen</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod === 'cash'">
        <button class="cx-button medium" @click="openDrawer">Kasse öffnen</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod !== ''">
        <button class="cx-button medium" @click="printReceipt">Quittung Drucken</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px" v-if="paymentMethod !== ''">
        <button class="cx-button medium" @click="paymentMethod = ''">Zurück</button>
      </div>
      <div class="shrink cell" style="padding: 2px 2px; margin: 1px">
        <button class="cx-button medium" @click="() => {payPopupVisible = false; paymentMethod = ''; console.log('h')}">Abbrechen</button> 
      </div>
    </div>
  </DxPopup>

  <form 
    style="padding: 0;"
    :id="id"
    @submit="handleSubmit"
  >

    <div class="-hide">

      <DxForm 
        :id="id"
        :form-data="this.templateData.data"
        :read-only="false"
        @initialized="saveFormInstance"
        :show-colon-after-label="true"
        :show-validation-summary="true"
        validation-group="currentUserData"
      >
        <DxSimpleItem 
          data-field="id"
          help-text=""
          css-class="hide"
        >
        </DxSimpleItem>

        <DxSimpleItem 
          data-field="Invoiceid"
          help-text=""
          css-class="hide"
        >
        </DxSimpleItem>

        <DxSimpleItem 
          data-field="Customerid"
          help-text=""
          css-class="hide"
        >
        </DxSimpleItem>

        <DxSimpleItem 
          data-field="Contractid"
          help-text=""
          css-class="hide"
        >
        </DxSimpleItem>

        <DxSimpleItem 
          data-field="mastercontent"
          help-text=""
          template="mastercontent"
        >
          <DxLabel 
            :text="'mastercontent'"
            :visible="false"
          />
        </DxSimpleItem>

        <template #mastercontent>

          <div class="master-content">

            <!-- SUBMIT BUTTON  -->
            <div class="grid-x grid-margin-x align-middle title-area-content">
              <div class="auto cell">
                <h5>Rechnung bearbeiten</h5>
              </div>
              <div class="shrink cell">
                <button :id="'submitform'+id" class="cx-button"><i class="fa-light fa-file-invoice"></i> {{currentUser.Translation.vueappInvoiceFormSubmit}}</button>
              </div>
            </div>
            <!-- /SUBMIT BUTTON  -->

            <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

              <!-- cell Invoice -->
              <div class="cell cell-margin-y large-3">
                <div class="dx-card no-paddings">

                  <label class="ck-label hide">Invoice</label>

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Preis ({{this.templateData.data.Currency}})</p>
                    </div>
                  </div>
                  <DxNumberBox
                    :id="'Price'+id"
                    :value="price"
                    :input-attr="{ 'aria-label': 'Price' }"
                  />

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Raten</p>
                    </div>
                  </div>
                  <DxNumberBox
                    :id="'Rates'+id"
                    :value="rates"
                    :input-attr="{ 'aria-label': 'Rates' }"
                  />

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Interval</p>
                    </div>
                  </div>
                  <DxNumberBox
                    :id="'Interval'+id"
                    :value="interval"
                    :input-attr="{ 'aria-label': 'Interval' }"
                  />

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Datum Rechnung</p>
                    </div>
                  </div>
                  <DxDateBox
                    :id="'DateInvoice'+id"
                    :value="dateInvoice"
                    :input-attr="{ 'aria-label': 'Date' }"
                    displayFormat="dd.MM.yyyy"  
                    type="date"
                  />

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Fällig 1. Rate</p>
                    </div>
                  </div>
                  <DxDateBox
                    :id="'DateStart'+id"
                    :value="dateStart"
                    :input-attr="{ 'aria-label': 'Date' }"
                    displayFormat="dd.MM.yyyy"  
                    type="date"
                  />

                  <div class="dx-card-end  hide">
                    &nbsp;
                  </div>

                </div><!-- dx-card-->

              </div>
              <!-- /cell Invoice-->

              <!-- cell Rates-->
              <div class="cell cell-margin-y large-3 -hide">
                <div class="dx-card no-paddings">

                  <label class="ck-label hide">Rates</label>

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Raten</p>
                    </div>
                  </div>

                  <!-- legend -->
                  <div class="grid-x -grid-margin-x align-middle row-rates legend">
                    <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                      <div class="ck-overflow-ellipsis hide"><p>id</p></div>
                    </div>
                    <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                      <div class="ck-overflow-ellipsis"><p>Betrag</p></div>
                    </div>
                    <div class="shrink cell" style="width: 120px; margin-right: 5px;">
                      <div class="ck-overflow-ellipsis"><p>Fällig</p></div>
                    </div>
                    <div class="shrink cell" style="width: 80px; margin-right: 10px;">
                      <div class="ck-overflow-ellipsis"><p>Bezahlt</p></div>
                    </div>
                    <div class="auto cell">
                      &nbsp;
                    </div>
                  </div>
                  <!-- /legend -->

                  <!-- listing -->
                  <template v-for="item in arrRates" :key="item.id">

                    <div class="grid-x -grid-margin-x align-middle row-rates">

                      <div class="shrink cell" style="width: 20px; margin-right: 5px;">
                        <!-- <div class="ck-overflow-ellipsis">{{ item.id }}</div> -->
                        <p class="summary-label auto-width">{{ item.id }}</p>
                      </div>

                      <div class="shrink cell" style="width: 80px; margin-right: 5px;">
                        <div v-if="item.Price" class="ck-overflow-ellipsis hide">{{ this.templateData.data.Currency }} {{ formatPrice(item.Price) }}</div>

                        <DxNumberBox
                          :id="'PriceRate'+item.id+id"
                          :value="Math.round(item.Price * 100) / 100"
                          :input-attr="{ 'aria-label': 'Price' }"
                          height="24"
                        />

                      </div>

                      <div class="shrink cell" style="width: 120px; margin-right: 5px;">

                        <div v-if="item.Date" class="grid-x align-middle">
                          <div class="shrink cell" style="margin-right: 5px;">

                            <span v-if="functionStatus(item.Date,item.Valuta) == 1" class="-grey-color">
                              <i class="fa-light fa-hourglass-clock"></i>
                            </span>
                            <span v-if="functionStatus(item.Date,item.Valuta) == 2" class="warning-color">
                              <i class="fa-light fa-triangle-exclamation"></i>
                            </span>
                            <span v-if="functionStatus(item.Date,item.Valuta) == 3" class="alert-color">
                              <i class="fa-light fa-triangle-exclamation"></i>
                            </span>
                            <span v-if="functionStatus(item.Date,item.Valuta) == 4" class="success-color">
                              <i class="fa-light fa-light fa-circle-check"></i>
                            </span>

                          </div>
                          <div class="auto cell">
                            <DxDateBox
                              :id="'DateRate'+item.id+id"
                              :value="item.Date"
                              :input-attr="{ 'aria-label': 'Date' }"
                              displayFormat="dd.MM.yyyy"  
                              type="date"
                              height="24"
                            />

                          </div>
                        </div>

                      </div><!-- cell -->


                      <div class="shrink cell" style="width: 100px; margin-right: 10px;">
                        <DxDateBox
                          :id="'DateValuta'+item.id+id"
                          :value="item.Valuta"
                          :input-attr="{ 'aria-label': 'DateValuta' }"
                          displayFormat="dd.MM.yyyy"  
                          type="date"
                          height="24"
                        />
                      </div>

                      <div class="auto cell">
                        &nbsp;
                      </div>

                      <div class="shrink cell text-right">

                        <div style="display: inline-block; margin-left: 10px;">
                          <!-- <a :href="apihost+'/de/mail/invoice/'+customerId+'/'+id+'/'+item.id+'/'" target="_blank"> -->
                          <a @click="showMore(customerId, id, item.id)">
                            <i class="fa-regular fa-chevron-right"></i>
                          </a>
                        </div><!-- /inline-block -->

                      </div><!-- /cell -->

                    </div><!-- /grid-x -->

                    <div :id="customerId+id+item.id" class="hide more">

                      <div class="dx-card-label grid-x align-middle -hide">
                        <div class="auto cell">
                          <p>Rechnung</p>
                        </div>
                        <div class="shrink cell">

                        </div>
                      </div>

                      <div v-if="invoiceId">

                        <div class="grid-x align-middle row-rates" v-if="functionStatus(item.Date,item.Valuta) !== 4" style="height: 30px;">

                          <div class="auto cell">
                            <a @click="showPaymentPopup(item.id)">
                              <i class="fa-light fa-cash-register"></i>
                              &nbsp;Rate bezahlen
                            </a>
                          </div>
                          <div class="shrink cell">
                            &nbsp;
                          </div>

                        </div>

                      </div><!-- v-if="invoiceId -->


                      <div class="grid-x align-middle row-rates" style="height: 30px;">

                        <div class="auto cell">

                          <div v-if="invoiceId">
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+invoiceId+'/'+item.id+'&dest=send'" target="_blank">
                            <i class="fa-light fa-file-pdf"></i>
                            &nbsp;RE-{{ id }}.pdf
                            </a>
                          </div>

                          <div v-else>
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+id+'/'+item.id+'&dest=send'" target="_blank">
                            <i class="fa-light fa-file-pdf"></i>
                            &nbsp;RE-{{ id }}-{{ item.id }}.pdf
                            </a>
                          </div>

                        </div>

                        <div class="shrink cell">
                          &nbsp;
                        </div>

                      </div>

                      <div v-if="invoiceId">
                      
                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a @click="sendEmail(customerId, invoiceId, item.id, 'invoice')">
                              <i class="fa-light fa-envelope"></i>
                              &nbsp;E-Mail senden
                            </a>
                          </div>

                          <div class="shrink cell">
                            <p v-if="arrDatesent[item.id]">
                              <!-- {{ arrDatesent[item.id]['invoice'] }} -->
                              {{ formatDatefromTime(arrDatesent[item.id]['invoice']) }}
                            </p>
                            <!-- <p>{{ datesentInvoice }}</p> -->
                          </div>

                        </div>

                      </div><!-- v-if="invoiceId -->


                      <div class="dx-card-label grid-x align-middle -hide" :class="!item.Reminder1  ? 'hide' : ''">
                        <div class="auto cell">
                          <p>Zahlungserinnerung</p>
                        </div>

                        <div class="shrink cell">
                          <div v-if="rateReminder1" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                            <a @click="showMore(customerId, invoiceId, rateId, 'rem1')">
                              <i class="fa-light fa-file-invoice"></i>
                            </a>
                          </div><!-- /inline-block -->
                        </div>
                      </div>


                      <div :id="customerId+invoiceId+rateId+'rem1'" class="more -hide" :class="!item.Reminder1  ? 'hide' : ''">

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+id+'/'+item.id+'?case=rem1&dest=send'" target="_blank">
                              <i class="fa-light fa-file-pdf"></i>
                              &nbsp;RE-{{ id }}-{{ item.id }}.pdf
                            </a>
                          </div>

                          <div class="shrink cell">
                            &nbsp;
                          </div>

                        </div>

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a @click="sendEmail(customerId, invoiceId, item.id, 'rem1')">
                              <i class="fa-light fa-envelope"></i>
                              &nbsp;E-Mail senden
                              <!-- an info@kohlio.ch -->
                            </a>
                          </div>

                          <div class="shrink cell">
                            <p v-if="arrDatesent[item.id]">
                              <!-- {{ arrDatesent[item.id]['rem1'] }} -->
                              {{ formatDatefromTime(arrDatesent[item.id]['rem1']) }}
                            </p>
                            <!-- <p>{{ datesentRem1 }}</p> -->
                          </div>

                        </div>

                      </div>

                      <div class="dx-card-label grid-x align-middle -hide" :class="!item.Reminder2  ? 'hide' : ''">
                        <div class="auto cell">
                          <p>1. Mahnung</p>
                        </div>
                        <div class="shrink cell">
                          <div v-if="rateReminder2" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                            <a @click="showMore(customerId, invoiceId, rateId, 'rem2')">
                              <i class="fa-light fa-file-invoice"></i>
                            </a>
                          </div><!-- /inline-block -->
                        </div>
                      </div>

                      <div :id="customerId+invoiceId+rateId+'rem2'" class="more -hide" :class="!item.Reminder2  ? 'hide' : ''">

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+id+'/'+item.id+'?case=rem2&dest=send'" target="_blank">
                              <i class="fa-light fa-file-pdf"></i>
                              &nbsp;RE-{{ id }}-{{ item.id }}.pdf
                            </a>
                          </div>

                          <div class="shrink cell">
                            &nbsp;
                          </div>

                        </div>

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a @click="sendEmail(customerId, invoiceId, item.id, 'rem2')">
                              <i class="fa-light fa-envelope"></i>
                              &nbsp;E-Mail senden
                              <!-- an info@kohlio.ch -->
                            </a>
                          </div>

                          <div class="shrink cell">
                            <p v-if="arrDatesent[item.id]">
                              <!-- {{ arrDatesent[item.id]['rem2'] }} -->
                              {{ formatDatefromTime(arrDatesent[item.id]['rem2']) }}
                            </p>
                            <!-- <p>{{ datesentRem2 }}</p> -->
                          </div>

                        </div>

                      </div>

                      <div class="dx-card-label grid-x align-middle -hide" :class="!item.Reminder3  ? 'hide' : ''">
                        <div class="auto cell">
                          <p>2. Mahnung</p>
                        </div>
                        <div class="shrink cell">
                          <div v-if="rateReminder3" style="display: inline-block; margin-left: 10px; font-size: 14px;">
                            <a @click="showMore(customerId, invoiceId, rateId, 'rem3')">
                              <i class="fa-light fa-file-invoice"></i>
                            </a>
                          </div><!-- /inline-block -->
                        </div>
                      </div>

                      <div :id="customerId+invoiceId+rateId+'rem3'" class="more -hide" :class="!item.Reminder3  ? 'hide' : ''">

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a :href="pdfhost+'/api.php?src='+apihost+'/de/pdf/invoice/'+customerId+'/'+id+'/'+item.id+'?case=rem3&dest=send'" target="_blank">
                              <i class="fa-light fa-file-pdf"></i>
                              &nbsp;RE-{{ id }}-{{ item.id }}.pdf
                            </a>
                          </div>

                          <div class="shrink cell">
                            &nbsp;
                          </div>

                        </div>

                        <div class="grid-x align-middle row-rates" style="height: 30px;">

                          <div class="auto cell">
                            <a @click="sendEmail(customerId, invoiceId, item.id, 'rem3')">
                              <i class="fa-light fa-envelope"></i>
                              &nbsp;E-Mail senden
                              <!-- an info@kohlio.ch -->
                            </a>
                          </div>

                          <div class="shrink cell">
                            <p v-if="arrDatesent[item.id]">
                              <!-- {{ arrDatesent[item.id]['rem3'] }} -->
                              {{ formatDatefromTime(arrDatesent[item.id]['rem3']) }}
                            </p>
                            <!-- <p>{{ datesentRem3 }}</p> -->
                          </div>

                        </div>

                      </div>

                    </div>


                  </template>
                  <!-- /listing -->

                  <div class="dx-card-end  hide">
                    &nbsp;
                  </div>

                </div><!-- dx-card-->

              </div><!-- /cell -->

              <!-- /cell Rates-->


              <!-- cell Betreff-->
              <div class="cell cell-margin-y large-3 hide">

                <div class="dx-card no-paddings">

                  <label class="ck-label hide">Betreff</label>

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                    </div>
                  </div>

                  <ul :id="'tabsLang'+id" class="tabs" data-tabs>
                    <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                    <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                    <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                    <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                    <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
                  </ul>

                  <div class="tabs-content" :data-tabs-content="'tabsLang'+id">

                    <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+id">
                      <DxTextBox
                        :id="'Subject_de'+id"
                        :value="this.templateData.data.Subject_de"
                        :input-attr="{ 'aria-label': 'Subject_de' }"
                      />
                    </div>
                    <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+id">
                      <DxTextBox
                        :id="'Subject_en'+id"
                        :value="this.templateData.data.Subject_en"
                        :input-attr="{ 'aria-label': 'Subject_en' }"
                      />
                    </div>
                    <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+id">
                      <DxTextBox
                        :id="'Subject_fr'+id"
                        :value="this.templateData.data.Subject_fr"
                        :input-attr="{ 'aria-label': 'Subject_fr' }"
                      />
                    </div>
                    <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+id">
                      <DxTextBox
                        :id="'Subject_es'+id"
                        :value="this.templateData.data.Subject_es"
                        :input-attr="{ 'aria-label': 'Subject_es' }"
                      />
                    </div>
                    <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+id">
                      <DxTextBox
                        :id="'Subject_it'+id"
                        :value="this.templateData.data.Subject_it"
                        :input-attr="{ 'aria-label': 'Subject_it' }"
                      />
                    </div>

                  </div><!-- /tabs-content-->

                  <!-- description-->
                  <label class="ck-label hide">Beschreibung</label>

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                    </div>
                  </div>
                  <DxTextBox
                    :id="'Description'+id"
                    :value="this.templateData.data.Description"
                    :input-attr="{ 'aria-label': 'Description' }"
                  />
                  <!-- /description-->

                </div><!-- dx-card-->

              </div><!-- /cell -->

              <!-- /cell Betreff-->

              <!-- cell Itemkey hide-->
              <div class="cell cell-margin-y hide">
                <div class="dx-card no-paddings">

                  <label class="ck-label">Itemkey</label>
                  <div class="ck-input-container">
                    <input type="text" class="dx-texteditor-input ck-input" autocomplete="off" spellcheck="false" name="Itemkey" :id="'Itemkey'+id" :value="this.templateData.data.Itemkey" >
                  </div>

                </div><!-- dx-card-->

              </div>
              <!-- /cell Itemkey hide-->

            </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->

          </div><!-- master-form -->

        </template><!-- mastercontent -->

        <DxButtonItem
          horizontal-alignment="left"
          :button-options="buttonOptions"
          css-class="hide"
        />

      </DxForm>

    </div><!-- hide -->

  </form>

</template>
<script>


import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

//import { DxRadioGroup } from 'devextreme-vue/radio-group';

import { DxPopup } from 'devextreme-vue/popup';
import { ref } from 'vue';
import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxDateBox from 'devextreme-vue/date-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";

import { 
  apihost, 
  pdfhost,
} from "../api";
//console.log(apihost);
//console.log(pdfhost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

let contractId;
let aboId;

let price;
let rates;
let interval;

let dateInvoice;
let dateStart;

let arrInvoice;
let arrCustomer;
let arrContract;
let arrAbo;
let arrRates;


let id;
let invoiceId;
let rateId;
let customerId;
// let invoiceCase;
// let ratePrice;
// let rateDate;
// let rateValuta;
let rateReminder1;
let rateReminder2;
let rateReminder3;
// let rateFee1;
// let rateFee2;
// let rateFee3;
// let rateDayspaid;
// let rateDaysdue;
// let reference;
let arrDatesent;
let datesentInvoice;
let datesentRem1;
let datesentRem2;
let datesentRem3;

let payPopupVisible = ref(false);
let paymentMethod = ref('');
let currentRateId = ref(0);

let printerDevice = ref(undefined);


auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

// POS

async function connectUsb(device) {
  await device.open();

  // select configuration to use
  try {
    const configValue = device.configurations[0].configurationValue;
    await device.selectConfiguration(configValue);
  } catch (error) {
    console.log("This device has no configurations.");
    throw new Error();
  }

  // choose and claim interface
  let alternate = undefined;
  let interfaceNum = undefined;
  try {
    const interfaces = device.configuration.interfaces;
    let interfaceNumber = undefined; 
    let alternateNumber = undefined;
    for (const i of interfaces) {
      for (const a of i.alternates) {
        if (a.interfaceClass == 0x07) { 
          alternate = a;
          alternateNumber = a.alternateSetting; 
          break;
        }
      }

      if (alternateNumber != undefined) {
        interfaceNumber = i.interfaceNumber;
        break;
      }
    }

    if (interfaceNumber == undefined) {
      throw new Error();
    }

    await device.claimInterface(interfaceNumber);
    //await device.selectAlternateInterface(interfaceNumber, alternateNumber);
    interfaceNum = interfaceNumber;
  } catch (e) {
    console.log("Failed to process or claim the interface of the device: " + e.toString())
    throw new Error();
  }

  // choose endpoint and send data
  try {
    let endpointNumber = undefined;
    for (const e of alternate.endpoints) {
      if (e.direction == "out") {
        endpointNumber = e.endpointNumber;
        break;
      }
    }

    if(endpointNumber == undefined) {
      throw new Error();
    }

    return { endpoint: endpointNumber, interface: interfaceNum };
  } catch (error) {
    console.log("Couldn't find endpoint");
    throw new Error();
  }
}

async function connectNetwork(host, port, websocketPort) {
  const clientSocket = new WebSocket(`ws://localhost:${websocketPort}`);
  const UPPERBOUND = 3000;
  let time = 0;
  let testDone = false;
  let testRes = false;

  while(clientSocket.readyState == 0) {
    if(time > UPPERBOUND) {
      throw new Error("Websocket client timed out"); 
    }

    await new Promise(r => setTimeout(r, 100)); // wait for 100s
    time += 100;
  }

  if(clientSocket.readyState != 1){
    throw new Error("Couldn't connect to the websocket server.");
  }
  
  // test connection to printer
  clientSocket.addEventListener("message", (event) => {
    const responseObject = JSON.parse(event.data);
    
    if(responseObject.type == "test") {
      testDone = true; 
      testRes = (responseObject.status == "OK");
    }
  });

  const sendData = {
    type: "test",
    host: host,
    port: port,
  };

  clientSocket.send(JSON.stringify(sendData));
  
  time = 0;
  while(!testDone) {
    await new Promise(r => setTimeout(r, 100));

    time += 100;
    if(time > UPPERBOUND) {
      throw new Error("Test timed out.")
    }
  }
  
  if(!testRes) {
    throw new Error("Connection test with printer failed.");
  } 

  return clientSocket;
}

function getReceiptDataArray(firstname, lastname, productText) {
  let escCommands = [];
  const initialize = new Uint8Array([0x1B, 0x40]); // initialize command.  
  const feed = (n) => new Uint8Array([0x1B, 0x64, n]); // feed n lines 
  const cut = new Uint8Array([0x1B, 0x6D]); // full cut
  const move = (low, high) => new Uint8Array([0x1B, 0x24, low, high]); // move by low + 256 * high
  const lineSpacing = (n) => new Uint8Array([0x1B, 0x33, n]); // set line spacing by n
  const longLine = (n) => new Uint8Array(n + 1).fill(0xC4, 0, n).fill(0x0A, n, n + 1); // line that is n units long 
  const justify = (n) => new Uint8Array([0x1B, 0x61, n]); // line justification. n = 0 (left), n = 1 (center), n = 2 (right)

  const datetimeLabel = "Datum/Zeit";
  const receiptText = "Beleg\n";
  
  const now = new Date();
  const datetimeText = `${String(now.getDate()).padStart(2, 0)}.${String(now.getMonth() + 1).padStart(2, 0)}.${now.getFullYear()} ` +
    `${String(now.getHours()).padStart(2, 0)}:${String(now.getMinutes()).padStart(2, 0)}:${String(now.getSeconds()).padStart(2, 0)}\n`

  const encoder = new TextEncoder();

  escCommands.push(initialize, lineSpacing(100), 
    encoder.encode(datetimeLabel), move(0xE0, 0x01), encoder.encode(receiptText), 
    encoder.encode(datetimeText),
    longLine(48)); 

  escCommands = escCommands.concat(productText);
  
  escCommands.push(justify(1), encoder.encode(`\n\nVielen Dank!\n${firstname} ${lastname}\n`), feed(3), cut);

  // fuse every uint8array 
  const length = escCommands.reduce((sumLength, array) => {
    return sumLength + array.byteLength;
  }, 0);

  const data = new Uint8Array(length);

  let offset = 0;
  for (const array of escCommands) {
    data.set(array, offset);
    offset += array.byteLength;
  }
  
  return data;
}

async function loadPrinterSettings() {
  const item = localStorage.getItem("webshop-printer-store");

  if(item === null) {
    printerDevice.value = undefined;
  } else {
    const printer = JSON.parse(item);

    // test connection
    try {
      let device = undefined;
      switch(printer.type) {
        case "USB":
          device = await navigator.usb.getDevices();

          if(device.length !== 1) {
            throw Error("Multiple usb devices connected");
          }
          printer.settings.device = device[0];

          device = await connectUsb(printer.settings.device);
          await printer.settings.device.releaseInterface(device.interface); 
          await printer.settings.device.close();
          break;
        case "Network":
          device = await connectNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort);
          device.close();
          break;
        case "Serial":
          device = await navigator.serial.getPorts();
          if(device.length !== 1) {
            throw Error("Multiple serial devices connected");
          }
          printer.settings.port = device[0];

          await device[0].open({ baudRate: 38400 });
          device[0].close();
          break;
      }
    } catch (error) {
      console.log("Connection test failed: " + error.toString());
    } 

    printerDevice.value = printer;
  }
}

async function connectAndSendUSB(device, data) {
  try{
    const deviceData = await connectUsb(device);
    await device.transferOut(deviceData.endpoint, data);
    await device.releaseInteface(deviceData.interface);
    await device.close();
  } catch (e) {
    console.log("Couldn't find endpoint or failed to send data.");
    return;
  }

}

async function connectAndSendSerial(port, data) {
  try {
    await port.open({ baudRate: 38400 });
    const writer = port.writable.getWriter();
    await writer.write(data);
    writer.releaseLock();
    await port.close();
  } catch(error) {
    console.log("Couldn't send data: " + error.toString());
    return;
  }

}

async function connectAndSendNetwork(host, port, websocketPort, data) {
  try {
    const clientSocket = await connectNetwork(host, port, websocketPort);
    const dataArray = Array.from(data);

    const sendObject = {
      type: "send",
      host: host,
      port: port,
      data: dataArray,
    };

    clientSocket.send(JSON.stringify(sendObject));
    clientSocket.close();
  } catch (error) {
    console.log("Couldn't connect to websocket: " + error.toString());
    return;
  }
}

// POS END

export default {
  name: 'invoice-item-template',

  props: {
    templateData: Object,
  },

  components: {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxTextBox,
    DxNumberBox,
    DxDateBox,
    DxPopup,
    //DxGroupItem,
    //DxRadioGroup,
    //DxFileUploader,
  },

  mounted() {

    //foundation
    this.tabsLang = new Foundation.Tabs($('#tabsLang'+id), {
      matchHeight: false
    })

    this.id = this.templateData.data.id;
    this.invoiceId = this.templateData.data.Invoiceid;
    this.rateId = this.templateData.data.rateId;
    this.customerId = this.templateData.data.Customerid;
    this.contractId = this.templateData.data.Contractid;
    this.aboId = this.templateData.data.Aboid;

    this.price = this.templateData.data.Price;
    this.rates = this.templateData.data.Rates;
    this.interval = this.templateData.data.Interval;

    this.dateInvoice = this.templateData.data.DateInvoice;
    this.dateStart = this.templateData.data.DateStart;

    this.arrInvoice = this.templateData.data.arrInvoice;
    this.arrCustomer = this.templateData.data.arrCustomer;
    this.arrContract = this.templateData.data.arrContract;
    this.arrAbo = this.templateData.data.arrAbo;
    this.arrRates = this.templateData.data.arrRates;

    this.arrDatesent = this.templateData.data.arrDatesent;
    this.datesentInvoice = this.templateData.data.datesentInvoice;
    this.datesentRem1 = this.templateData.data.datesentRem1;
    this.datesentRem2 = this.templateData.data.datesentRem2;
    this.datesentRem3 = this.templateData.data.datesentRem3;

    this.rateReminder1 = this.templateData.data.rateReminder1;
    this.rateReminder2 = this.templateData.data.rateReminder2;
    this.rateReminder3 = this.templateData.data.rateReminder3;

    loadPrinterSettings().then();

  },

  data() {
    return {

      apihost,
      pdfhost,

      currentUser,
      colCountByScreen,

      id,
      invoiceId,
      rateId,
      customerId,
      contractId,
      aboId,

      price,
      rates,
      interval,

      dateInvoice,
      dateStart,

      arrInvoice,
      arrCustomer,
      arrContract,
      arrAbo,
      arrRates,

      rateReminder1,
      rateReminder2,
      rateReminder3,


      arrDatesent,
      datesentInvoice,
      datesentRem1,
      datesentRem2,
      datesentRem3,

      //save Button
      buttonOptions: {
        text: currentUser.Translation.vueappInvoiceFormSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },

      payPopupVisible,
      paymentMethod,
      currentRateId,

    };

  },
  methods: {

    functionStatus(dateInvoice, dateValuta){

      const today = new Date().getTime();
      const invoice = new Date(dateInvoice).getTime();
      const valuta = dateValuta;
      let status = 0;

      if (today <= invoice) {
        //console.log('hourglass');
        status = 1;
      }

      if (today > invoice) {
        //console.log('warning');
        status = 2;
      }

      if (today > invoice ) {
        //console.log('alert');
        status = 3;
      }

      if(valuta){
        //console.log('success');
        status = 4;
      }

      return status;

    },

    formatDatefromTime(timestamp) {
      var d = new Date(timestamp * 1000);
      const datetime = 
        ("00" + d.getDate()).slice(-2) + "." +
          ("00" + (d.getMonth() + 1)).slice(-2) + "." +
          d.getFullYear() + " " +
          ("00" + d.getHours()).slice(-2) + ":" +
          ("00" + d.getMinutes()).slice(-2) + ":" +
          ("00" + d.getSeconds()).slice(-2)
      ;
      return datetime;
    },


    formatPrice(value) {
      value = parseFloat(value);
      //auf 5 Rappen runden
      value = Math.round(value * 20) / 20;
      //zwei Stellen anzeigen
      value = value.toFixed(2);
      return value;
    },

    printTimestamp: function () {
      return Date.now();
    },

    showMore(customerId, invoiceId, rateId) {
      const showElement = document.getElementById(customerId+invoiceId+rateId);
      if(showElement.classList.contains('hide')){
        showElement.classList.remove("hide");
      } else {
        showElement.classList.add("hide");
      }
    },

    sendEmail(customerId, invoiceId, rateId, invoiceCase) {

      fetch(apihost+'/de/mail/invoice/'+customerId+'/'+invoiceId+'/'+rateId+'?case='+invoiceCase)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result);
          //console.log(data);

          //Update Frontend
          this.arrDatesent = data.arrDatesent;

          //this.fetchUser = data;
        })
        .catch(() => { throw 'Network error' });

      notify({
        message: 'E-Mail wurde versandt',
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);


    },

    saveFormInstance(e) {
      this.formInstance = e.component;
    },

    showPaymentPopup(id) {
      payPopupVisible.value = true;
      currentRateId.value = id;
    },

    async confirmPayment(e) {
      //console.log(this.arrRates);
      const id = this.formInstance.getEditor('id').option('value');
      const current = new Date();
      const year = current.getFullYear();
      const month = String(current.getMonth() + 1).padStart(2, '0'); 
      const day = String(current.getDate()).padStart(2, '0');

      this.arrRates[currentRateId.value - 1].Valuta = `${year}-${month}-${day}`;
      document.getElementById("DateValuta"+this.arrRates[currentRateId.value - 1].id+id).getElementsByTagName("input")[0].value = `${year}-${month}-${day}`;

      await this.handleSubmit(e);

      const contractObj = await (await fetch(`${apihost}/vue/contract/listinf/?Contractid=${this.contractId}`)).json();
      const contract = contractObj.data[0];
      const abo = await (await fetch(`${apihost}/vue/abo/getabo/${contract.Aboid}/get`)).json();

      const customerId = `?customerId=${this.customerId}`;
      const method = `&method=${paymentMethod.value}`;
      const products = `&products=${encodeURIComponent(JSON.stringify([{
        id: abo.Id, 
        price: this.arrRates[currentRateId.value - 1].Price, 
        pic: abo.Picthumb, subject: abo.Subject
      }]))}`;
      const total = `&total=${this.arrRates[currentRateId.value - 1].Price}`;
      const redeem = `&redeem=0`;
      const location = `&location=${contract.locationid}`

      try {
        await (await fetch(apihost+"/vue/shoppingcart/getshoppingcart/0/insert"+customerId+method+products+total+redeem+location)).json();
        notify("Zahlung erfolgreich abgeschlossen!", "success", 5000);

        if(paymentMethod.value == "cash") {
          this.openDrawer();
        }
      } catch {
        notify("Ein Fehler ist aufgetreten.", "error", 5000);
      }

      payPopupVisible.value = false;
    },

    async printReceipt() {
      const printer = printerDevice.value;

      const encoder = new TextEncoder();
      let itemLines = [];

      const move = (low, high) => new Uint8Array([0x1B, 0x24, low, high]);
      const longLine = (n) => new Uint8Array(n + 1).fill(0xC4, 0, n).fill(0x0A, n, n + 1); // line that is n units long 
      const formatPrice = (value) => { 
        let val = (value/1).toFixed(2).replace('.', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
      };

      const itemString = `1x Rate ${this.arrAbo[0].Subject}`.replace(/[\u{0080}-\u{FFFF}]/gu, "");
      const price = formatPrice(this.arrRates[currentRateId.value - 1].Price); 

      itemLines.push(encoder.encode(itemString));
      itemLines.push(move(0xC0, 0x01));
      itemLines.push(encoder.encode(price + "\n"));

      itemLines.push(longLine(48));
      itemLines.push(encoder.encode("Summe"));
      itemLines.push(move(0x20, 0x01));
      itemLines.push(encoder.encode("CHF"));
      itemLines.push(move(0xC0, 0x01));
      itemLines.push(encoder.encode(formatPrice(price) + "\n"));

      const data = getReceiptDataArray(this.arrCustomer[0].Subject, "", itemLines);

      console.log(this.arrAbo);

      if(printer === undefined) {
        notify("Es herrscht keine Verbindung mit der Kasse.", "error", 3000);
        return;
      }

      switch(printer.type) {
        case "USB": 
          await connectAndSendUSB(printer.settings.device, data);
          break;
        case "Serial":
          await connectAndSendSerial(printer.settings.port, data);
          break;
        case "Network":
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, data);
          break;
      }
    },

    async openDrawer() {
      const printer = printerDevice.value; 
      const pulse1 = new Uint8Array([0x1B, 0x70, 0x00, 0x14, 0x14]); 
      const pulse2 = new Uint8Array([0x1B, 0x70, 0x01, 0x14, 0x14]);
      
      if(printer === undefined) {
        notify("Es herrscht keine Verbindung mit der Kasse.", "error", 3000);
        return;
      }

      switch(printer.type) {
        case "USB":
          await connectAndSendUSB(printer.settings.device, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendUSB(printer.settings.device, pulse2);
          break;
        case "Serial":
          await connectAndSendSerial(printer.settings.port, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendSerial(printer.settings.port, pulse2);
          break;
        case "Network":
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, pulse1);
          await new Promise(r => setTimeout(r, 200));
          await connectAndSendNetwork(printer.settings.host, printer.settings.tcpPort, printer.settings.websocketPort, pulse2);
          break;
      }
    },

    async handleSubmit(e) {
      e.preventDefault();

      const id = this.formInstance.getEditor('id').option('value'); 
      const Invoiceid = this.formInstance.getEditor('Invoiceid').option('value'); 
      const Customerid = this.formInstance.getEditor('Customerid').option('value'); 
      const Contractid = this.formInstance.getEditor('Contractid').option('value'); 

      let thisid;
      if(id){
        thisid = id;
      }
      if(Invoiceid){
        thisid = Invoiceid;
      }

      const Price = document.getElementById("Price"+id).getElementsByTagName("input")[0].value;
      const Rates = document.getElementById("Rates"+id).getElementsByTagName("input")[0].value;
      const Interval = document.getElementById("Interval"+id).getElementsByTagName("input")[0].value;
      const DateInvoice = document.getElementById("DateInvoice"+id).getElementsByTagName("input")[0].value;
      const DateStart = document.getElementById("DateStart"+id).getElementsByTagName("input")[0].value;
      const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
      const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
      const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
      const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
      const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;
      const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

      //let objString = '?id=' + id;
      let objString = '?id=' + thisid;

      if(Customerid){
        objString = objString + '&Customerid=' + Customerid;
      } else {
        objString = objString + '&Customerid=';
      }
      if(Contractid){
        objString = objString + '&Contractid=' + Contractid;
      } else {
        objString = objString + '&Contractid=';
      }
      if(Price){
        objString = objString + '&Price=' + Price;
      } else {
        objString = objString + '&Price=';
      }
      if(Rates){
        objString = objString + '&Rates=' + Rates;
      } else {
        objString = objString + '&Rates=';
      }
      if(Interval){
        objString = objString + '&Interval=' + Interval;
      } else {
        objString = objString + '&Interval=';
      }
      if(DateInvoice){
        objString = objString + '&DateInvoice=' + DateInvoice;
      } else {
        objString = objString + '&DateInvoice=';
      }
      if(DateStart){
        objString = objString + '&DateStart=' + DateStart;
      } else {
        objString = objString + '&DateStart=';
      }
      if(Subject_de){
        objString = objString + '&Subject_de=' + Subject_de;
      } else {
        objString = objString + '&Subject_de=';
      }
      if(Subject_en){
        objString = objString + '&Subject_en=' + Subject_en;
      } else {
        objString = objString + '&Subject_en=';
      }
      if(Subject_fr){
        objString = objString + '&Subject_fr=' + Subject_fr;
      } else {
        objString = objString + '&Subject_fr=';
      }
      if(Subject_es){
        objString = objString + '&Subject_es=' + Subject_es;
      } else {
        objString = objString + '&Subject_es=';
      }
      if(Subject_it){
        objString = objString + '&Subject_it=' + Subject_it;
      } else {
        objString = objString + '&Subject_it=';
      }
      if(Description){
        objString = objString + '&Description=' + Description;
      } else {
        objString = objString + '&Description=';
      }

      let rateString = '';
      let valutaString = '';
      let priceString = '';
      for (let i = 0; i < this.arrRates.length; i++) { 
        rateString += '&DateRate'+this.arrRates[i].id+'='+document.getElementById("DateRate"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
        valutaString += '&DateValuta'+this.arrRates[i].id+'='+document.getElementById("DateValuta"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
        priceString += '&PriceRate'+this.arrRates[i].id+'='+document.getElementById("PriceRate"+this.arrRates[i].id+id).getElementsByTagName("input")[0].value;
      }
      //console.log(valutaString);

      //Get Duedate Valuta and Price
      objString = objString + rateString + valutaString + priceString;

      //console.log(objString);

      try {
        const data = await (await fetch(apihost+'/de/vue/invoice/getinvoice/'+thisid+'/save'+objString)).json();
        this.arrRates = data.arrRates;
        //console.log(data);

        //Update List-Title

        //start titleDateinvoice
        const titleDateinvoice = document.getElementById('titleDateinvoice'+id);
        if(titleDateinvoice){
          const timeInvoice = new Date(DateInvoice).getTime();
          const stringInvoice = new Intl.DateTimeFormat('default', {dateStyle: 'medium'}).format(timeInvoice);
          titleDateinvoice.innerHTML = stringInvoice;
        }
        //end titleDateinvoice

        //start titlePrice
        const titlePrice = document.getElementById('titlePrice'+id);
        if(titlePrice){
          const val = (Price/1).toFixed(2).replace('.', '.');
          const price = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
          titlePrice.innerHTML = this.templateData.data.Currency + ' ' + price;
        }
        //end titlePrice

        //start titleRates
        const titleRates = document.getElementById('titleRates'+id);
        if(titleRates){
          let textRates = '';
          if(Rates > 1){
            textRates = Rates + ' Raten'
          } else {
            textRates =  Rates + ' Rate'
          }
          //titleRates.innerHTML = textRates;
          //titleRates.innerHTML = "I have changed!";
        }
      } catch {
        throw 'Network error'
      }

      notify({
        message: currentUser.Translation.vueappInvoiceFormSubmitSuccess,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);

    },    

  },

};

</script>

<style>

</style>
