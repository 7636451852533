<template>
<svg version="1.1" id="logo-aesthetikline"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1752.5 562.2"
style="enable-background:new 0 0 1752.5 562.2;" xml:space="preserve">
<path class="st0" d="M237.7,24.7c1,1.6,2.1,3.2,3.1,4.8c0.3,0.5,1,1,1.2,1.6c0.4,4.4,0.9,8.9,1.3,13.3c0,0,0,0,0.1,0.1
c3.2-0.3,6.4-0.6,9.6-0.8c0,0,0,0,0.1-0.1c-0.4-4.5-0.9-9-1.3-13.4c0.6-2,1.8-4.3,2.6-6.2c1.9-4.4,3.4-9,5.3-13.3
c0.9-2.1,1.6-4.1,2.4-6.1c0.3-0.8,0.4-1.8,1-2.4c-0.2-0.3,0,0-0.2-0.2c-0.9,0.6-6.3,0.6-8,1c-0.9,0.2-1.9-0.1-2.5,0.2
c-0.7,0.3-1.3,3.4-1.7,4.3c-1.2,2.9-2,6-3.1,9.1c-0.5,1.2-1.3,2.7-1.4,4.1c-0.9-0.5-1.9-3.2-2.5-4.2c-1.7-2.7-3.2-5.6-4.9-8.4
c-0.7-1.1-1.5-2.1-2-3.4c-3.8,0-7.5,0.9-11.2,1.1c0.7,2,3,4.5,4.1,6.2c2.7,4.3,5.3,8.6,8.2,12.8L237.7,24.7z M319.9,47.4
c2.8,0.9,7.6,1.7,11.2,0.7c2.4-0.7,4.5-1.5,6.5-2.6c2.1-1.2,5.6-4.9,6.6-7.2c0.6-3,1.2-6.1,1.8-9.1c-7-1.4-14-2.8-21-4.2
c-0.5,1.3-0.5,2.7-0.8,4.2c-0.2,0.9-0.6,1.9-0.6,3c1.4,0,2.8,0.5,4.1,0.8c1.4,0.2,2.9,0.5,4.3,0.7c1.1,0.3,2.4,0.7,3.7,0.7v0.5
c-0.6,0.5-0.9,1.3-1.4,1.9c-0.7,0.7-1.9,1.7-2.8,2.2c-5.4,2.8-12.7-0.8-14.8-4.7c-0.7-1.4-0.8-3-1.2-4.8c-0.3-1.3,0.4-3.9,0.7-4.8
c1.5-4.8,3.2-7,8.3-8.3c1.1-0.3,2.8-0.3,4.1,0c0.7,0.2,1.7,0.4,2.4,0.7c2.4,1.1,3.5,2.7,4.7,5c3.5,0.7,7,1.4,10.6,2
c0-6.4-4.3-11.8-8.4-14c-1.8-1-3.5-1.5-5.6-2.2c-3-1-8-1.5-11.5-0.5c-6.3,1.8-10.4,5.6-13.1,11c-0.5,1-0.6,2-1,3.1
c-0.8,2.1-1.6,6.7-1,9.6c2,8.6,6.2,13.6,14.3,16.1L319.9,47.4z M186.6,57.9c2.1-0.7,4.2-1.2,6.1-1.9c2.4-0.9,4.8-1.2,7.1-2.2
c5.4-2.2,9.3-5.9,11.5-11.3c0.9-2.1,2-6.7,1.2-10c-1.8-7.4-4.7-12.8-10.7-16c-0.7-0.4-1.4-0.4-2.2-0.7c-2.5-1.1-7.3-1.8-10.8-1
c-3,0.8-6,1.6-8.8,2.6c-2.3,0.9-4.7,1.3-7,2.2c-0.7,0.3-1.5,0.2-2,0.6c-0.1,0-0.2,0-0.4,0.1c4,12.9,8,25.8,12,38.6
c1.4-0.1,2.7-0.8,3.8-1.2L186.6,57.9z M193.9,23.6c4.9,1.2,6.9,4.6,8.3,9.4c0.8,2.7,0.5,6.5-0.7,8.5c-1.4,2.3-3.7,3.8-6.4,4.9
c-1.4,0.6-4.1,0.8-5.2,1.7c-0.8-0.4-1.5-4.5-1.9-5.6c-1.4-3.8-2.4-7.9-3.7-11.9c-0.5-1.5-1.5-3.3-1.6-5c3.3-0.5,6.7-3.1,11.2-1.9
L193.9,23.6z M372.2,63.4c0.8,0.4,1.6,1,2.8,1.1c0.2-1.9,1.8-4.3,2.5-6s1.5-3.4,2.2-5c0.2-0.4,0.1-0.9,0.4-1.2
c0.4-0.5,1.2-0.8,1.7-1.2c1.4-1,2.7-2.3,4.1-3.4c0.7-0.5,1.2-1.3,1.9-1.8c2.8-2.1,5.5-4.4,8.2-6.7c2.5-2.2,5.5-4,7.8-6.4
c-2.3-1-4.6-2-6.8-3c-1-0.4-2.3-0.6-3.1-1.3c-1.3,0.3-2.8,2.6-3.8,3.4c-2.4,1.8-4.4,4-6.6,6c-1.1,1-2.4,1.9-3.5,3h-0.2
c0.2-1.1,0.1-3.7-0.1-4.9c-0.3-1.2,0.1-2.8-0.1-3.8c-0.3-1.4,0.2-3.3-0.1-4.6c-0.2-1.1,0.4-3-0.1-3.8v-1.4c-0.2-0.3-2.3-0.9-2.8-1.1
c-2.4-1-4.9-2.3-7.4-3.2c-0.2,0.7,0,2.8,0.2,3.7c0.6,2.5,0,5.5,0.6,7.9v1.9c0.6,2.5,0.2,5.5,0.7,8v1.8c0.3,1.4,0,3.1,0.4,4.7
c0.1,0.4,0.3,1.8,0.2,2.3c-0.1,0.6-0.6,1.3-0.8,1.8c-0.7,1.7-1.4,3.4-2.2,5c-0.6,1.4-1.4,4.4-2.3,5.4c0.4,0.6,1.3,0.6,2,1
c1.4,0.6,2.9,1.3,4.4,1.9L372.2,63.4z M129,52.5c0.8,3.3,0.9,6.9,1.7,10.1c0,0.8,0.2,1.6,0.2,2.4c0.6,2.4,0.8,5.2,1.4,7.6
c0,0.8,0.2,1.6,0.2,2.4c0.5,1.8,0.5,3.8,1,5.8c0.3,1.1,0.6,2.4,0.7,3.6c1.3-0.3,2.4-1.3,3.5-1.9c1.6-0.9,4.1-1.8,5.4-3
c0.3-0.6-0.7-4.5-1-5.4c-0.1-0.5,0-0.9-0.2-1.2c0-1.6,1.8-1.8,2.9-2.4c2.3-1.2,4.6-2.8,6.8-4.1c1-0.6,2.3-0.9,3.1-1.7
c0.7,0.1,0.6,0.4,1.1,0.7c0.9,0.7,1.6,1.4,2.5,2c0.8,0.6,1.3,1.7,2.3,2c0,0,1.8-0.8,2.2-1c1.4-0.8,2.9-1.8,4.3-2.6
c0.8-0.5,1.7-0.7,2.4-1.3h0.1c-0.3-1-1.7-1.7-2.5-2.3c-2-1.5-3.8-3.5-5.9-5c-1.2-0.9-2.2-2.3-3.5-3.2c-3.4-2.5-6.3-5.5-9.6-8
c-0.8-0.6-1.5-1.6-2.3-2.2c-1.9-1.4-3.5-3.3-5.4-4.7c-1.1-0.8-2.5-1.7-3.2-2.8c-0.9,0.2-1.5,0.8-2.2,1.2c-1.4,0.8-2.8,1.4-4.2,2.2
c-1.2,0.6-2.4,1.8-3.7,2.2c0,1.7,0.5,3.4,0.8,4.9c0.5,2,0.5,3.9,1,5.8L129,52.5z M140,51.1c1.7,1.3,3,3,4.7,4.2
c0.3,0.2,0.6,0.3,0.8,0.5c1.1,1.1,2.2,2.4,3.5,3.2c-0.4,0.6-1.2,0.7-1.8,1.1c-1.6,0.9-3.3,1.9-4.8,2.9c-0.6,0.4-1.3,0.4-1.7,1
c-0.2,0-0.2,0-0.4-0.1c-0.6-0.9-0.5-3.3-0.8-4.6c-0.6-2.4-0.8-5-1.4-7.6c-0.2-0.8-0.6-1.9-0.6-2.9C138.5,49.3,139.1,50.4,140,51.1
L140,51.1z M399.8,76.3L399.8,76.3c0.2,0.8,1.4,1.2,2.1,1.7c0.8,0.6,1.7,1.5,2.5,2c1,0.7,2.3,1.2,3,2.2c0.8-0.2,1-1.1,1.4-1.7
c0.8-1.1,1.7-2.3,2.5-3.5c2.1-2.9,4.4-5.7,6.5-8.6c1.3-1.8,2.4-3.8,3.8-5.4l0,0c0-0.1,0-0.1,0.1-0.1c-0.1,2.1-1.3,5.1-1.9,7
c-1.1,3.5-1.8,7.1-2.9,10.4c-0.6,1.7-0.9,3.6-1.4,5.2c-0.2,0.7-0.1,1.3-0.5,1.8c0.1,0.9,1.2,1.2,1.8,1.7c0.7,0.5,1.3,1.1,1.9,1.6
c0.8,0.6,1.9,1,2.5,1.8c1.4-0.3,4.2-2.8,5.5-3.6c3.4-2.1,6.6-4.5,10-6.6c1.9-1.2,4.4-2.4,5.9-3.8h0.1c-0.1,0.6-0.5,0.8-0.8,1.2
c-1.2,1.7-2.4,3.3-3.6,4.9c-2.3,3.2-4.4,6.6-7,9.6c-0.9,1.1-1.5,2.6-2.5,3.6c0.3,0.7,1.3,1,1.9,1.4c1.4,1,2.8,2.2,4.2,3.2
c0.6,0.4,1.2,0.7,1.7,1.2c0.9-0.4,1.4-1.8,1.9-2.5c1.5-2.1,3.1-4.3,4.7-6.5c4-5.6,8.4-11.1,12.4-16.7c1.1-1.6,2.3-3.1,3.4-4.7
c0.5-0.7,0.9-1.6,1.6-2.2c-0.2-1-1.6-1.4-2.3-1.9c-1.6-1.1-3.2-2.3-4.8-3.5c-0.7-0.5-1.3-1.3-2.2-1.6c-0.5-0.1-1.2,0.7-1.7,1
c-1.5,1-3.1,1.9-4.6,2.9c-3.4,2.2-6.8,4.2-10.3,6.2c-0.9,0.5-1.8,1.4-2.6,1.9c-1.9,1.2-4.7,2.4-6.2,3.8h-0.2
c1.7-6.5,3.1-13.4,5-19.8c0.6-2,0.9-3.9,1.4-5.8c0.3-1,0.2-2.2,0.7-3c0-1.5-1.3-1.7-2.2-2.3c-1.6-1.2-3.3-2.4-4.8-3.7
c-0.7-0.6-1.5-0.8-2-1.4h-0.5c-0.8,1.4-2,2.5-2.9,3.8c-1.4,2-2.7,3.9-4.2,5.8c-0.6,0.8-1,1.7-1.6,2.5c-0.4,0.6-1.1,1.2-1.6,1.8
c-2.1,3-4.1,5.9-6.2,8.9c-1.5,2.2-3.2,4.4-4.8,6.6c-0.7,1-1.9,1.9-2.3,3.1L399.8,76.3z M83,77.1c1.5,2.1,3.4,3.9,4.9,5.9
c4.4,5.9,9.7,11.2,14,17.2c1.1,1.4,2.4,2.7,3.5,4.2c0.5,0.7,1.4,1.1,1.7,2c0.7-0.3,1-0.9,1.6-1.3c1.2-0.9,2.3-1.7,3.5-2.6
c0.2-0.3,0.5-0.6,0.7-0.8c1.7-1.3,3.3-2.7,5-4c0.2-0.3,0.5-0.6,0.7-0.8c2-1.5,4.3-3.1,6-4.9c0.2-0.2-0.7-1.3-1-1.6
c-1.1-1.4-2.6-2.7-3.6-4.3c-1.2,0.3-2,1.6-2.9,2.3c-1.6,1.2-2.9,2.6-4.4,3.7c-0.8,0.6-1.9,1.2-2.5,2c-0.9-0.1-1.3-1.2-1.8-1.8
c-1-1.4-2.2-2.6-3.2-4c-0.5-0.7-1.4-1.3-1.9-2c-2.5-3.3-5.2-6.2-7.7-9.5c-0.5-0.7-1.4-1.3-1.9-2c-1.1-1.5-2.5-2.8-3.6-4.3
c-0.4-0.6-0.6-1.2-1.3-1.4c-0.9,1.2-3.4,2.9-4.7,3.8c-0.8,0.6-1.5,1.8-2.6,2.2c0.1,1,1.1,1.5,1.6,2.2L83,77.1z M369.2,451.8
c5.1-2.6,10.1-5.2,14.6-8.4c2.1-1.5,4-3.3,6-4.8c2.4-1.8,5.6-4.4,7.4-6.8c0.9-1.2,2.3-2.2,3.4-3.2c1.4-1.5,2.7-3.2,4-4.9
c0.8-1.1,2-2.1,2.8-3.2c2.4-3.3,4.3-6.6,5.8-10.9c0.7-2.1,1.5-5.6,0.7-8.4c-1.9-7.1-6.7-10.8-13-13.6c-0.7-0.2-1.4-0.3-2-0.5
c-2.4-0.8-5.1-1.5-7.6-2.2c-0.6,0-1.2-0.2-1.8-0.2c-5.4-1.5-12-1.8-17.4-3.1c-1.3-0.3-2.6-0.2-3.7-0.5c-2.7-0.6-5.5-0.4-8-1
c-0.8,0-1.6,0-2.4-0.1c-3.5-0.8-8.2-0.3-11.4-1.1c-4.9-0.2-9.8-0.4-14.6-0.6c-2.5,0-5,0-7.4-0.1c-1-0.2-2.5-0.1-3.7-0.1h-7.1
c-2.1,0-6.8-0.4-8.4,0.1l-0.2-0.2c0-3,0.1-6.3,0.1-9.1c0-2.3-0.2-4.7,0.2-6.7v-2.8c0.3-1.5,0.2-3.6,0.5-5v-1.8
c0.6-2.9,0.5-5.9,1.2-8.8c0.9-3.7,1.5-7.4,2.6-10.9c1.1-3.6,2.5-7.4,3.8-10.9c1.4-3.9,2.2-8,3.4-12.1c1-3.4,0.6-7.3,1.4-10.9
c0.2-1.1,0-2.3,0.2-3.2c0.3-1.4,0-2.9,0.2-4.1c0.4-1.9-0.4-6.4,0.4-7.7c0.5-1,2.2-1.5,3.1-2.2c1.6-1.1,3.8-2.9,5.2-4.3
c4.1-4.1,7-9.4,8.9-15.7c1.8-5.8,1.1-14.8-0.7-19.9c-0.4-1-0.4-1.8-0.7-2.8c-1.7-4.4-3.9-8.5-6.5-12.1c-1.6-2.2-3.6-4-5.2-6.1
c-0.8-0.7-1.5-1.4-2.3-2.2c-0.8-1-1.7-1.7-2.6-2.6c-1.7-1.6-3.3-3.4-5.2-4.8c-0.6-0.4-1-1.2-1.6-1.7c-0.7-0.6-1.5-1.1-2.2-1.7
c-1.8-1.6-3.5-3.3-5.5-4.8c-1.2-0.9-2.2-2.5-3.7-2.9V200c17.3,0.3,28.8,7.7,37.1,17c2.1,2.3,4.1,5.3,5.9,7.9c0.7,1.1,1.2,2.3,2,3.2
l0,0c0,0.1,0.2,0.1,0.2,0.1c-0.1-1.2-1-2.1-1.4-3c-1.3-2.5-2.7-4.7-4.2-6.8c-6.6-9.4-14.7-16.5-27.4-19.9c-2.7-0.7-5.6-0.6-8.4-1.2
c-1.3-0.3-2.6,0-3.7-0.2c-1.4,0-2.9,0-4.3-0.1c-4.4-1-8.9-3.3-12.4-5.4c-1.2-0.7-2.9-1.1-3.7-2.2h-0.2c2.7,1.9,4.9,4.5,7.1,6.7
c3.5,3.5,7.1,7,10.7,10.6c0.8,0.8,1.8,1.6,2.8,2.3c0.4,0.5,0.9,1,1.3,1.4c1,0.7,1.8,1.5,2.8,2.3c1.3,1,2.4,2.3,3.7,3.4
c0.4,0.4,0.7,0.8,1.1,1.2c4.2,3.2,8.2,7.4,10.7,12.4c0.3,0.9,0.6,1.8,1,2.8c0.9,3.3,0,6.9-1.3,9c-2.8,4.6-6.7,6.9-12,9.1
c-1.9,0.8-3.8,1-6,1.7c-0.6,0.2-2.3,0.6-2.8,0.4c-0.5-0.3-0.3-2.6-0.5-3.4c-0.8-3.2-1.5-6.1-2.6-8.8c-2.4-5.4-6.6-9-10.3-13.1
c-1.3-1.4-2.9-2.5-4-4.1h-0.1c0.3,1,1.1,1.8,1.2,2.9c-1.4-0.3-2.8-1.4-4.1-1.9c-2.8-1.2-6-1.6-9.1-2.4c-1.7-0.4-3.6-0.3-5.3-0.7
c-1-0.2-2.1,0.2-2.9-0.2H261c0.3,0.4,3,0.7,3.7,1c2.7,1,5.6,1.9,8,3.2c6.5,3.4,13.2,8.3,16.7,14.6c0.8,1.4,1.2,2.7,1.9,4.2
c1,2,2.7,6.2,2.6,9c-8.2,0-15.1-2.3-20.6-5.2c-1.6-0.9-3.2-1.4-4.7-2.4c-9.8-6.2-17.4-15.1-23.2-25.3c-0.9-1.5-2-3.1-2.9-4.7
c-1.3-2.3-2.1-4.7-3.4-7.1c-0.6-1.2-2.1-3.4-2.2-4.9c5.1,0,9.1,0,13.2,1c1.5,0.4,3.7,0.3,4.8,1h0.6c-1.6-0.5-3.1-1.2-4.6-1.7
c-2.2-0.7-4.5-0.9-6.8-1.4c-1.5-0.1-3-0.2-4.4-0.4c-0.7-0.1-1.7,0.2-2.2-0.1h-1.2c-0.4-0.2-1.7-3.5-2-4.1h0.1c0.3-0.7,1.6-0.6,2.3-1
c2-0.9,3.9-2.2,5.5-3.5c5-3.8,8.6-12.7,8.6-21.6c0-2.1,0.3-4.4-0.1-6.4c-0.2-1,0.2-2.4-0.4-3.1v-0.2h0.2c1.9,4,4.4,7.5,6.8,10.9
c1.5,2.1,3,4.6,5.2,6c0.8,0.5,2.2,1.3,3.7,1c2.1-0.5,5.5-2.9,6.1-4.8h0.2c0,2.5-0.3,5.3,0.2,7.6c0.3,1.3,0.8,2.8,1,4.1
c0.3-0.4,0.1-1.5,0.1-2.2v-6c0.3-1.2,0-2.6,0.4-3.8c0.6-2.5,1.1-5.1,1.9-7.2c0.4-1.1,0.6-2.6,1.4-3.2c0,0,1.2,0.1,1.6,0.2
c1.3,0.4,2.8,0.4,4.3,0.8c3.3,0.9,10.8,1.3,13.6-0.2c1.1-0.6,2-2,2.4-3.4v-1c0.1-0.5,0.2-3.1-0.2-3.7v-1c0.2-0.5,0.9-0.7,1.3-1
c0.8-0.6,1.3-1.7,2-2.3c0.1-1.3,0-2.2-0.7-3c0-1.7,1.7-1.8,2.2-3.7c-0.2-0.3-0.2-0.7-0.5-1c-0.5-0.5-1.2-0.9-1.7-1.6
c-0.3-0.4-0.3-0.8-0.7-1.1c0-0.5-0.2-0.7,0.1-1.1c0.3-0.8,2.5-1.7,3.2-2.3c0.9-0.6,2.2-1.5,2.8-2.4c0.2-0.4,0.2-0.9,0.5-1.2
c0-0.2,0-0.2-0.1-0.4c-0.3-0.3-1.8,1.5-2.2,1.7c-0.7,0.4-1.6,0-2.5,0.2c-1.3,0.3-4.5,0.9-4.9,1.9h-0.1c0,0.3,0.1,0.3,0.2,0.5
c0.8,0,3.1-0.7,3.6-1.1h1.1l-1.9,1.9c-1,1.6,0.4,3.9,1.3,4.4c0,0.3-0.1,0.3-0.2,0.5c-1.4,0.2-3.5,0.4-4.9,1.1c-1,0.5-1.9,1.3-3,1.7
c-0.9,0.3-1.6,0-2.4,0.2c0,0,0,0.2,0.1,0.2c2.5,1,6.2,0.6,8.6,1.8c0,0.3,0,0.3,0.2,0.5c0,2.7-2.7,3.6-4.9,2.8
c-0.3,0.9,0.6,1.5,1,2.2c1.1,1.8,1.7,5.8-0.2,7.2c-2.3,1.7-8.3,0.4-10.8-0.5c-4-1.4-7.8-3-11.3-5c-2.3-1.3-4.3-3.2-6.4-4.8
c-1.4-1.1-3-2.2-4.1-3.6h-0.4c0,4.7-3.9,7.9-7.8,8.9c-3.4,0.9-8.1-1-9.1-2.9c-0.3-0.6-0.2-1-0.1-1.7c0.1,0,0.2,0,0.4-0.1
c3.9,4.2,11.4,3.4,13.9-1.6c2.1-4.1-1.1-9-3.8-10.3c-0.8-0.4-2.2-0.5-3.2-0.5v-0.2c0.6-0.2,1.1-0.6,1.8-0.7c2-0.5,4.8,1.6,5.4,2.6
h0.2c-0.3-1.1-0.8-2.5-1.4-3.4v-0.2c1.2,0.1,2,1,2.9,1.4c2.2,1.2,4.8,2.1,7.3,2.9c1.3,0.4,2.8,0.6,4.1,1c0.9,0.2,2,0,2.8,0.4h0.1
v-0.2c-4.4-2-7.3-6.7-8.9-11.6c-0.3-0.9-1.4-4.3-0.7-5.2c0.3-0.7,1.1-0.9,1.7-1.3c1-0.8,2.1-1.7,3-2.6s0.8-1.1,1.3-1.3
c0.1,0,0,0,0.2-0.1c0.6,0.6,1.3,1,1.9,1.4c0.8,0.6,1.6,1.6,2.5,2.2c3.2,1.9,7.1,2.8,11.4,1.4c1.1-0.3,2.4-0.4,3.2-1.1
c0-0.1,0,0,0.1-0.2c-1-0.4-2.2-0.2-3.2-0.6c-2.4-0.8-6.9-2.6-8.3-4.3c-0.5,0-1,0-1.4-0.1c-1.3,0.3-3.6,1.5-5,0.4c0,0-0.2,0-0.2-0.1
c0.4-1.1,1.6-1.6,2.3-2.5c0.7-0.9,2.1-2.9,3-3.4c1-0.5,3.1,0,4.1,0.2c3.2,1,5.6,2.2,8,4c1.3-0.2,1.9-1.4,2.6-2.2
c0-1.2-0.9-1.2-1.7-1.7c-2.1-1.4-4.6-2.5-7.3-3.2c-0.6-0.2-3.1-0.4-3.6-0.1h-0.2c0.2-1.3,2-3.7,2.6-4.9c1.8-3.3,2.9-7.1,2.8-12
c1.5,0.3,4,3,4.9,4.2s2.1,2.4,2.9,3.7c1.4,2.3,2.1,5.2,2.9,8c0.3,1.2,0.3,3.6,0,4.8c-0.3,1-1.1,2.4-0.7,4c0.6,2.4,1.6,3.9,2.6,5.6
c1,0,1-0.9,1.4-1.6c0.9-1.3,1.8-2.3,2.6-3.7c1.5-2.4,2.5-5.1,3.4-8.3c0.3-1,0-1.8,0.2-2.9c1.3-6-2-14.3-4.2-17.8
c-4.1-6.5-10.1-10.7-18.5-13c-1.1-0.3-5.5-0.8-6.8-0.5c-1.1,0.2-2.6,0.4-3.6,0c-3.7-1.3-7.4-2.9-11.4-4.1c-3-0.9-6.3-0.9-9.5-1.7
c-0.7-0.2-1.6,0-2,0c-1.7-0.3-4.1-0.2-6.1-0.2H243c-0.1,0-1,0.2-1.1,0.2c-1.3,0-3.8,0.1-5.3,0.5c-4.6,1.2-8.9,2.2-12.7,4.1
c-10.7,5.4-18.1,14.5-22.6,26.3c-1.2,3.3-1.5,7.1-2.4,10.8c-0.4,1.6-0.1,3.3-0.5,4.8v3.4c-0.4,1.6-0.1,5.5,0.2,7.2
c0.1,2.3,0.2,4.6,0.4,7c0.2,0.8,0,2.2,0.1,3.1c0.2,0.9,0.1,2.3,0.1,3.4c0,4.7-1,8.9-3.1,11.8c-2.2,3-7.8,5.8-13.1,4.2
c-5.1-1.6-9.1-5.6-9.1-12.2c0-1.4,0-2.6,0.4-3.7c1.1-3.5,3.5-6.4,6.5-8c1-0.5,2.4-0.7,3.4-1.3h0.1c0-0.2,0-0.2-0.1-0.2
c-6.2,0.4-11.3,4.9-13.6,9.2c-0.6,1.1-0.8,2.2-1.2,3.5c-1.1,3.2-1.2,8.7,0,12c2,5.4,5.6,9.5,10.6,11.9c7,3.5,17.9-0.8,22.7-3.6
c3.1-1.8,6-3.3,8.9-5.3c3.7-2.6,7-5.8,10.3-8.9c1.5-1.4,3-2.7,4.2-4.3c0.5-0.5,1-1,1.6-1.4c2.7-3.6,5.9-6.7,8.5-10.3
c1.2-1.6,2.6-3.1,3.7-4.7c0.7-1,1.4-2.1,2.3-3l0,0c0-0.1,0.2-0.1,0.2-0.1c-1.7,6.8-5.8,13-9.5,18c-0.8,1.1-2,2-2.8,3.1
c-2.1,2.7-4.5,5-7,7.3c-7.1,6.5-14.9,12.2-24,16.8c-3.6,1.8-7.7,3-11.4,4.6c-1.8,0.8-3.6,1.1-5.4,1.9c-5.8,2.5-11.4,4.9-16.2,8.3
c-1.5,1.1-3.1,2.1-4.4,3.4c-0.2,0.2-1.4,1.3-1.3,1.3c-0.2,0.2-0.9,0.6-1.3,1c-0.3,0.3-0.4,0.7-0.7,1c-0.5,0.5-1.2,0.9-1.7,1.4
c-4,4.1-7.2,9.3-9.8,14.6c-1.1,2.3-1.9,5.1-2.6,7.7c-0.5,1.6-0.3,2.9-0.7,4.6c-0.7,2.9-1.2,8.8-0.5,12.1c0.3,1.2,0,2.1,0.2,3.2
c0.9,3.6,1.5,7.1,2.9,10.2c1.8,4.1,4.2,7.7,6.6,11.2c0.3,0.5,0.5,1,0.8,1.4c1,1.2,2.1,2.2,3,3.5c1.7,2.2,4.4,3.5,6.4,5.5
c0.2,0,0.2,0,0.4-0.1c0.4-0.6,0.2-1.5,0.4-2.3c0.4-1.4,0.7-2.8,1.2-4.2c1.4-3.7,3.3-7.4,4.6-11.3c0.4-1.2,0.3-2.6,0.6-4
c0.4-1.9-0.5-6.3-0.8-7.8c-0.4-1.8,0-3.5-0.5-5.3c-0.5-2.3-0.8-7.8-0.2-10.2c0.3-1.4,0-2.5,0.2-3.8c0.5-2,0.6-4.1,1.2-6
c1.6-5.2,3.6-10.1,6.5-14c1.1-1.5,2.6-2.6,3.8-4c0-0.1,0-0.1,0.1-0.4c-1.3,0.2-2.5,0.8-3.6,1.2c-3.1,1.1-5.8,2.1-8.5,3.6
c-0.8,0.5-1.7,1.2-2.5,1.7c-1.7,0.9-4.7,2.7-5.9,4.1h-0.1c0.5-1.3,2.1-2.2,3-3.1c2.1-2.1,4.3-3.8,6.8-5.5
c9.7-6.8,21.6-11.2,31.6-17.8c9.6-6.3,18.9-13.3,26.4-21.8c7.4-8.3,13.4-18,20.2-27c1.1-1.1,2.2-2.2,3.4-3.4
c2.5-2.5,5.1-5.2,7.9-7.3c1.7-1.3,3.6-2.5,5-4h0.1c-1,1.9-2.8,3.5-4,5.4c-2.7,4.3-5.3,8.7-7.7,13.2c-1.5,2.7-2.5,5.8-4.1,8.4
c-2.4,4-4.4,8.4-6.8,12.4c-3.3,5.3-7.1,10.1-11.4,14.4c-1.5,1.5-2.9,3.2-4.6,4.4c-2.8,2.1-5.5,4.2-8.4,6.1c-1.9,1.2-3.8,2.1-5.8,3.2
c-3.2,2-6.5,4.2-9.8,6c-1.5,0.8-2.9,1.5-4.3,2.4c-6.6,4.5-13.2,10.6-17,17.8c-1.7,3-3,6.4-4.1,10c-0.3,1-0.2,1.7-0.5,2.8
c-0.4,1.7-1,4.4-0.7,6.2c0.2,1.8-0.6,4.3-0.1,6.2c0.7,3,0.4,6.4,1.1,9.1v1.1c0.2,0.8,0.9,3.2,0.6,4.2c-0.5,1.8-1.9,4-2.8,5.6
c-0.3,0.7-0.4,1.3-0.7,2c-1.2,2.7-2.5,5.6-3.6,8.4c-1.2,3-2.1,6.1-3.1,9.1c-0.5,1.5-0.6,3.4-1.8,4.3c-1.5,1.2-3.3,2.1-5.3,2.8
c-1.2,0.4-3.4,0.6-4.7,0.4c-1.5-0.3-2.6,0-3.8-0.5c-4.4-1.4-7.5-4.4-8.9-8.9c-0.6-2.1-0.7-5.4,0-7.4c0.3-0.9,1.1-1.6,1.2-2.6H143
c-0.4,0.7-1.1,1.3-1.6,1.9c-0.9,1.3-1.7,3-2.3,4.6c-0.4,1-0.5,2.2-0.7,3.2v1.8c-0.4,1.9,0.2,4.4,0.7,5.9c1.9,5.4,4.2,9.1,8.9,11.6
c2.5,1.4,5.4,2.6,9.4,2.6c1.2,0,3.3,0.3,4.1-0.2c0.4,0,0.6,0,0.8,0.1c0,2.7-0.3,5.6-0.8,8c-0.6,2.4-0.4,4.7-1.2,6.7
c-1.4,3.3-3.2,6.3-4.7,9.6c-1.6,3.7-2.7,7.7-4,11.6c-1.4,4.1-1.8,8.7-2.9,13.1c-0.4,1.7-0.4,3.3-0.7,4.8c-0.6,2.4-0.4,4.9-1,7.2v1.3
c-0.7,2.9-0.5,6.5-1.2,9.5c-1.1,13.5-2.2,27-3.4,40.4v1.1c-0.9,0.1-1.9-0.4-2.8-0.7c-1.4-0.5-5.9-0.7-7.4-0.2
c-8.3,2.4-12.2,6.2-16.2,12.8c-1.4,2.4-2,5.4-3.2,8c-1.6-0.3-4.3-1.3-6.1-0.5c-2.7,1.2-4,6.4-2,9.5c1.4,2.1,4.8,1.6,7.4,2.5
c0.3,0.8,0.1,1.9,0.4,2.9c0.4,1.7,0.6,3.5,1.2,5c2.7,7.2,7.3,12.4,14.8,14.9c1.1,0.4,2.1,0.2,3.4,0.5c1.1,0.2,3.1,0.2,4.2,0
c1-0.2,1.6,0,2.5-0.2c4.5-1.2,7.6-3.4,10.3-6.4c0.6-0.6,1.3-1.2,1.8-1.9c0.7-1.2,0.3-3.9,0-5.3c-0.1-0.6,0.1-1.4-0.1-1.8
c0-0.3,0-0.5-0.1-0.7c-0.9-0.3-2-0.3-2.9-0.7c-2.6-1.3-4.7-3.3-7-4.8c-1.2-0.8-3.1-1.6-3.6-3c-0.5-1.5,0.8-2.8,1.2-3.6
c0.6-1.1,0.9-2.1,1.4-3.2c0.7-1.4,1.7-2.9,2.4-4.3c1.7-3.3,3.2-6.5,5-9.6c0.9-1.6,1.5-3.9,2.9-4.9c1.6-1.2,4.3-0.7,5.6,0.4
c0.9,0.7,1.4,1.9,2,2.9c0.9,1.4,1.6,2.9,2.3,4.6c0.3,0,0.4,0,0.6-0.1c0-2.6-1.4-5.4-2.4-7.3c-0.4-0.8-1.4-1.9-1-3.5
c5.1-18.9,12.2-35.1,17.5-53.8c1-3.4,1.1-7.1,1.9-10.7c0-1.2,0.2-2.4,0.2-3.6c0.4-1.6,0.6-4.5,0.2-6.1v-3.1
c-0.2-0.7-0.2-1.8-0.2-2.5s0.2-2.1-0.1-2.6c-0.1,0,0,0-0.2-0.1c-1,2.1-1.9,4.6-2.8,6.8c-0.4,1-0.3,1.9-0.7,2.9
c-0.6,1.7-1.7,4.3-1.7,6.4h-0.2c0-4,0.4-8.4-0.4-11.9c0-1.4,0-2.9-0.1-4.3c-0.4-1.9,0-4-0.2-6.1c0-0.8-0.3-2,0-2.8
c0.4-1,1.6-1.8,2.3-2.5c1.1-1.1,2.1-2.5,2.9-3.8c1.6-2.6,2.3-7,3.5-10.1c2-5.3,3.9-10.5,6.2-15.6c1.2-2.7,2.3-5.5,3.5-8.3
c0.5-1.1,1.5-2.5,1.6-4h0.2c0.1,0.8,0,2.3,0,3.5c0,2.8,0.2,6.1-0.4,8.4c-0.2,0.9-0.1,2.1-0.6,2.8v0.6h0.1c1.5-3.2,3.5-5.9,5.2-8.9
c2-3.8,4-7.6,5.8-11.5c0.8-1.8,1.5-3.5,2.4-5.2c0.4-0.7,0.3-1.6,1.1-1.6c1.4,3.6,3.5,6.9,5.6,10c0.7,1,2.5,2.8,2.9,3.8
c1,0,1.1-0.9,1.6-1.6c0.8-1.2,1.7-2.4,2.5-3.6c2.4-3.5,4.5-7.3,6.5-11.3c0.9-1.9,1.6-4.1,2.4-6c0.3-0.8,0.2-1.4,0.6-2
c-0.1-1.5-1.1-2.1-1.8-3c-1.1-1.5-2.3-3-3.4-4.4c-2.4-3.2-4.6-6.5-9.1-7.6c-3.6-0.9-6.6,2.8-8.3,4.1c-3.8,2.9-7,6.4-10.3,9.8
c-2.1,2.1-4.5,4.4-6.1,7h-0.4c0-1.5-0.3-3.2-0.6-4.7c-0.4-1.9,0-3.9-0.4-5.6c-0.3-1.3-0.1-4.7,0.1-5.8v-2.6c0.2-1.1,0.2-2.7,0.5-3.7
v-1.3c0.5-2.2,0.6-4.6,1.2-6.7c2.4-8.1,5.8-15.6,13-18.8c1.6-0.7,3.3-1.1,5.2-1.6c1.4-0.3,3-0.2,4.3-0.5c1.8-0.4,3.5,0.2,5.2-0.2
c2.8-0.7,5.4-1.4,7.8-2.4c1-0.4,2.1-0.6,2.9-1.3c1.2,0.1,1.2,3,1.6,4.2c0.9,3.2,1.3,6.9,2.2,10.2c0.4,1.5,0,2.9,0.5,4.4
c1,4.1,1,9.7,1,14.6c0,13.1-2.3,23.6-6.8,32.2c-1.8,3.5-4,6.3-6.2,9.4c-0.4,0.5-2.2,2.9-2.2,2.9c0.3,1.1,1.4,2,2,2.9
c1.4,2,3,3.8,4.4,5.8c4,5.5,8.3,10.8,11.6,16.9c1.8,3.4,3.3,7.1,4.6,10.9c0.7,2.1,0.8,4.3,1.3,6.5v1.1c0.8,3.5-0.3,8.4-1.1,10.9
c-1.8,5.5-4.5,9.4-7.9,13.2c-1.4,1.5-2.6,3-4.2,4.3c-0.4,0.3-0.6,0.9-1,1.2c-0.4,0.3-0.9,0.6-1.3,1c-1.4,1.4-2.8,3.1-4.4,4.3
c-0.3,0.2-0.6,0.3-0.8,0.5c-1.2,1.1-2.3,2.4-3.6,3.4v0.5c12.2,1,24.1,5.5,31.9,11c1.5,1.1,3,2.3,4.4,3.5c0.9,0.8,1.7,2,2.8,2.6v0.2
c-1.4-0.4-2.6-1.6-3.8-2.3c-2.4-1.3-4.7-2.6-7.1-3.8c-6-3.1-12.2-5.4-18.8-7.9c-1.2-0.5-2.7-1-4-1.4c-1-0.2-2-0.5-3-0.7
c-1-0.4-2.1-0.9-3.4-1c-0.5,0.6-1.9,1.4-2,1.9c-0.2,0.2-0.9,0.6-1.3,1c-1.1,1.1-2.2,2.1-3.1,3.4c-0.2,0.3-0.7,0.4-1,0.7
c-3.7,3.9-7.1,8.5-9.6,13.6c-1.6,3.2-2.5,6.9-3.6,10.7c0,0.8-0.2,1.5-0.2,2.3c-1.2,4.7-0.6,11.4,0.6,15.5c3.1,10.4,9,17.9,16.9,23.4
c1.4,1,2.9,1.6,4.3,2.5c5.5,3.4,12.3,5.1,19.2,7.2c2.3,0.7,4.7,0.8,7,1.4c2.8,0.7,5.9,0.6,8.6,1.2h1.6c1.9,0.5,4.5,0.3,6.5,0.7h2.2
c0.8,0.2,2.4,0.2,3.7,0.2h2c2,0.5,5.3,0.2,7.3,0c1.7-0.2,4.3,0.4,6.2,0c1.6-0.4,3.8-0.1,5.4-0.5h1.9c1.3-0.3,3.2,0,4.9-0.5
c2.9-0.7,6.3-0.7,9.1-1.4c1.3-0.3,2.4-0.1,3.7-0.5c4.1-1.1,8.6-1.6,12.5-2.9c0.9-0.3,1.6-0.2,2.5-0.5c2.9-0.9,6.2-1.7,9-2.6
c0.9-0.3,1.6-0.2,2.4-0.5c1.7-0.6,2.9-2.5,4.2-3.6c1-0.9,2.2-1.6,3.4-2.4c2.3-1.7,4.7-3.6,7.3-5c2.4-1.3,5.2-2.4,7.9-3.4
c1.3-0.5,4.3-0.6,5.3-1.2c0.3,0,0.3,0,0.5,0.1c-0.7,1-3.4,2.1-4.4,3c-2.2,1.9-4.5,3.8-6.8,5.5c-1.4,1.1-2.6,2.5-4.1,3.6
c-0.4,0.4-0.8,0.9-1.2,1.3c-1.5,1.1-2.9,2.4-4.2,3.7c-0.6,0.6-1.1,1.2-1.7,1.8c-1.1,0.8-1.8,2-2.9,2.8c-1.2,0.9-2,2.1-3.1,3
c-1.1,0.8-2,1.8-2.9,2.9c-1.5,1.4-3,2.9-4.4,4.3c-1.6,1.6-3.3,3.3-4.9,4.9c-0.8,0.8-1.4,1.7-2.3,2.3c0.5,0.3,1.4,0.1,2.3,0.1
c1.8-0.1,3.5-0.2,5.3-0.4c1.7-0.3,4.6-0.1,6.7-0.6c1.6-0.4,3.4-0.3,5.2-0.7c4.3-1.1,8.9-1.6,12.8-2.9c1.4-0.5,2.7-0.5,4.1-1
c3.9-1.4,8-2.7,11.6-4.3c1.5-0.7,3-0.9,4.4-1.7L369.2,451.8z M215.4,116.4c-0.7,1.8-1.3,3.9-1.9,5.8c-0.3,1-0.2,1.8-0.5,2.9
c-0.2,0.7-0.5,1.5-0.5,2.4h-0.2c0,0,0,0-0.1-0.1c0-3.9-0.2-9.3,0.6-12.7c0-0.8,0.2-1.7,0.2-2.5c2.3-8.7,5.5-16.4,11.8-21.1
c1.7-1.3,3.2-2.9,5-4c2.3-1.3,4.7-2.3,7.2-3.4c1.2-0.5,3.6-0.7,4.4-1.4h0.5c-0.4,0.7-3,1.9-3.8,2.4c-2.5,1.6-4.9,3.7-7,5.8
C224.2,97.4,219.2,106.5,215.4,116.4L215.4,116.4z M243.1,118.4c-3.9,1.6-8.2,2.4-12.4,3.6c-1.4,0.4-3.9,0.2-5,1h-0.4v-0.2
c14.4-3.7,26.2-12.3,36.8-20c2.4-1.7,4.6-3.7,7-5.5c0.7-0.5,1.2-1.3,1.9-1.8c3.6-2.7,6.8-5.9,10.2-8.9c1-0.9,3-2.4,3.5-3.6
c0,0,0.2,0,0.2,0.1c-0.2,1.1-1.1,2-1.7,2.8c-1.2,1.8-2.2,3.6-3.5,5.4c-0.7,1-1.8,2-2.5,3C268.8,105.5,256.9,112.8,243.1,118.4
L243.1,118.4z M107.8,438.1h-0.4c-1.6-3.7-0.8-7.4,1.7-9.5c0.4,0,0.8,0,1.2,0.1c-0.3,0.6-1.1,0.7-1.4,1.2
C107.5,431.9,107.8,435,107.8,438.1L107.8,438.1z M111,437.1c0.2,0.6,0.1,1,0.5,1.3c0,0.2,0,0.1-0.1,0.2l0,0c-1.7-0.4-2.8-3.1-2-5.6
c0.3-1.1,1-2.3,1.7-3.1c0.3-0.4,0.9-0.6,1.2-1h0.4C112.7,432.1,109.8,432.8,111,437.1L111,437.1z M135.7,414.1
c-1.3,1-3.7,3.2-4.7,4.6c-2.4,3.2-4.1,7-5.4,11.3c-0.5,1.5-0.3,2.8-0.7,4.4c-0.4,1.8-0.7,6.9-0.2,9c0.6,2.5,1,5,1.7,7.2
c0.7,2,1.2,3.6,2.2,5.2c0.4,0.6,0.6,1.7,1.2,2v0.2c-1.8-0.4-4.4-4-5.3-5.5c-1.2-2-2.2-4.1-2.9-6.7c-0.4-1.3-0.2-2.3-0.5-3.7
c-0.5-2.2-0.5-5.5,0-7.7c0.4-1.7,0.3-3,0.7-4.6c1.4-4.6,3.4-8.7,6-12.1c1.5-2,3.9-3.8,6.4-4.8c0.9-0.4,2.6-0.2,3.4-0.7h1.1
c-0.3,0.7-2.2,1.4-2.9,1.9L135.7,414.1z M249.2,292.6c2.4-1.1,5.1-1.4,7.6-2.3c5.6-2.1,11.6-3.4,17.5-5.4c0.9-0.2,1.8-0.3,2.8-0.5
c2.7-0.8,5.8-1.2,8.6-1.9c3.4-0.8,6.5-0.4,10-1.2c0.8-0.2,1.9,0,2.5-0.1c1.2-0.3,4.2,0,5.3,0.1c1.6,0.3,2.9-0.1,4.4,0.2
c2.3,0.5,4.8,1,6.7,1.8c1.5,0.7,3.2,1.1,3.2,3.2c-1.6,0-3.4-0.4-4.8-0.7c-1.8-0.4-3.7-0.1-5.4-0.5c-2.1-0.5-4.7,0.2-6.7-0.2
c-1.1,0-2.2,0-3.2,0.1c-2.8,0-5.7,0-8.2,0.5c-2.2,0.5-4.1,0-6.2,0.6c-5.8,1.4-12,1.7-17.6,3.1c-0.7,0-1.4,0.2-2,0.2
c-2.8,0.8-6,1.2-8.8,1.9c-1.5,0.4-4.3,0.4-5.4,1.1L249.2,292.6L249.2,292.6z M247.8,363.4c-1.9-1.2-4.2-2.2-6.4-3.1
c-1.3-0.5-2.6-0.8-3.7-1.4c0-1.4,0.8-2.4,1.3-3.2c2.1-3.5,5.7-6,10.3-3.1c4.4,2.8,7.8,8.6,10,13.6c0.7,1.5,1.2,3,1.9,4.4
c0.3,0.6,0.3,1.3,0.7,1.8l0,0c0,0.1,0,0.1,0.1,0.1c0-2.3-0.3-4.4-0.8-6.4c-0.6-2.2-0.6-4.4-1.2-6.4c-0.7-2.3-0.8-4.4-1.4-6.6
c-1.4-4.7-2.8-9.5-4.6-13.8c-1-2.5-1.9-5-2.9-7.3c-0.4-1-0.6-2.1-1.2-2.9v-0.2c1.1,0.4,2.3,2.2,3.2,2.9c2.4,1.7,5,3.4,7.7,4.8
c2.2,1.1,4.8,1.7,7.3,2.4c1.3,0.3,3.6,0.1,4.6,0.7h1c-4.8-2.2-10.3-8.8-12.7-13.4c-0.6-1.1-0.9-3.8-1.4-4.4c0-0.4,0.1-0.3,0.2-0.6
h6.8c1.3,0,2.4,0.2,2.8,0h0.6c-1.4-0.6-2.8-0.8-4.1-1.4c-3.6-1.8-6.5-4.2-8.3-7.8c-0.7-1.4-1.8-3.9-1-6c0.5-1.3,3.3-3.6,5.6-3
c2.2,0.6,4.3,2.2,6.4,3.1c1.4,0.4,2.8,0.9,4.2,1.3h0.7c-1.3-1.9-2.2-2.3-3-4.9c-0.2-0.5-0.7-1.9-0.2-2.5c0.2-1.9,2.3-1.9,3.8-2.5
c3.4-1.4,7.6-1.7,11.5-2.6c1.3-0.3,2.8-0.2,4-0.5c0.6-0.1,1.4,0.1,1.8-0.1c0.7,0,1.2,0,1.7,0.2c0.2,2.9,0.9,7.6,0.2,10.7
c-0.4,1.8,0.3,3.9-0.1,5.5c-0.8,3.2-1.8,6.4-2.8,9.5c-0.8,2.7-1.1,5.5-1.8,8.3c-0.7,3-0.7,6.6-0.7,9.8s-0.2,3.5,0.1,4.1v9.6
c0.7-0.4,0.9-1.7,1.2-2.4c1-2.3,2.1-4.6,3.1-7.1c0.6-1.4,1.4-3,1.9-4.4c0.7-1.9,1-4,1.4-6c0.5-1.9,0.5-6.5,0.1-8.5
c-0.1-1.5-0.2-3-0.4-4.4c-0.7-3.1-1.1-6.4-1.8-9.4c-0.4-1.8,0.3-3.8,0.6-5.2c0.7-3,0.2-7.2,0.2-10.4c6.3-0.2,14.2-0.5,16.8,3.4
c0.9,1.3,1.5,4.2,1,6.5c-0.7,2.8-1.6,5.6-2.4,8.3c-0.5,1.8-0.7,3.3-1.2,5c-1.8,6-2.7,12.5-4.3,18.8c-0.4,1.3-0.1,2.5-0.5,3.8
c-0.6,2.6-0.8,5.8-1.4,8.4c0,0.9-0.2,1.8-0.2,2.8c-0.6,2.5-0.5,5.4-1.2,8c-1.7,6.5-2.8,13.1-5.3,19c-2.5,0.5-5.6-0.2-8,0.4H285
c-1.6,0.3-3.6,0-5.2,0.4h-2.4c-1.2,0.3-2.6,0-4,0.2c-1.8,0.4-4.1,0.5-6,0.5c-6-6-12.4-11.5-19.8-16.1L247.8,363.4z M351.2,405.9
c-0.8-0.2-1.3,0-2-0.2c-2.4-0.7-5.3-1-7.8-1.7c-1.1-0.2-2.2-0.3-3.4-0.5c-2.4-0.6-5-0.8-7.6-1.4c-7.7-2-16-2.7-23.6-4.7
c-1.1-0.2-2.2-0.3-3.4-0.5c-3.7-1-8-1.3-11.8-2.3c-0.8,0-1.6-0.2-2.4-0.2c-3.5-0.9-7.7-0.8-11.4-1.7c-1.6-0.4-3.4-0.1-4.9-0.5H271
c-1.5-0.3-3.6-0.1-5.2-0.5h-2.6c-0.9-0.2-2.2,0-3.2-0.1c-1.6-0.4-5.7-0.3-7.3,0h-3.5c-0.6,0.1-1.7,0-2.5,0.1c-2.8,0.6-6.3,0.3-9,1
h-1.2c-1.8,0.4-4,0.5-5.6,1c-1,0.3-2.1,0-2.9,0.5h-0.8c0.4-0.6,2.8-0.9,3.6-1.2c2.3-0.8,5-1.4,7.3-2.2c0.9-0.3,1.7-0.2,2.6-0.5
c4.4-1.4,9.6-1.7,14.4-2.9c2-0.5,4.2-0.3,6.1-0.7h1.4c2.2-0.5,5-0.2,7.2-0.7c5.3-0.3,10.6-0.6,15.8-0.8c6.4-0.2,12.7-0.3,19.1-0.5
c5.8,0,11.8,0.1,17.4,0.1c1.9,0,4.1,0,5.9,0.1c1.4,0.1,3.2-0.2,4.4,0.1h3.8c3.4,0.7,7.5,0,10.9,0.7c1.6,0,3.3,0.2,4.9,0.2
c4.2,0.9,9,0.5,13.1,1.4c1.8,0.4,3.3,0,5,0.5c5.3,1.2,11.1,1.3,16.2,2.6c1.2,0.3,2.1,0.2,3.2,0.5c6.1,1.7,12.2,2.7,15.5,7.2
c0.9,1.3,3.1,4.4,2.5,7.1c-1.3,6-4.7,10-8.3,13.8c-0.5,0.5-0.9,1.2-1.3,1.8c-0.4,0.5-1.1,0.8-1.4,1.3c-1.2,1.6-2.7,3-4.1,4.4
c-1.3,1.3-2.6,2.6-4,3.8c-0.4,0.4-0.9,0.6-1.2,1.1H387c0.3-0.9,1.2-1.2,1.7-1.9c0.8-1,1.3-2.2,1.7-3.7c0.7-3.1-1.9-6.4-3.1-7.8
c-5.1-5.7-12.4-6.5-20.6-8.9c-1.7-0.5-3.3-0.5-5-1c-3.3-1-7.2-1.5-10.6-2.4L351.2,405.9z M414,189.8c0,1.1-0.1,2.6,0.2,3.4
c11.5,0,17.6-4.5,20.6-12.8c0.9-2.5,2-6.9,1.2-10.6v-1.2c-0.4-1.8-0.4-3.8-1.1-5.5c-4.1-10.6-10.6-21.4-25.4-21.2
c-0.6,0.4-2.4,0.2-3.2,0.4c-2.2,0.6-4.4,1.3-6.1,2.4c-3.1,2-5.7,5-7.4,8.4c-0.5,1-0.9,2.4-1.2,3.5c-0.2,0.8,0,1.7-0.5,2.3
c-0.1,1-1,1.3-1.7,1.8c-0.8,0.6-1.5,1.6-2.3,2.2c-0.7,0.5-1.5,1.1-2,1.8c-0.5,0-0.6,0-0.8-0.2c-0.3,0-0.3,0-0.5,0.1
c0.2,0.8,0.7,1.2,1.1,1.8c3.6,0,3.9-2.6,5.9-4.1c1.1-0.8,2-1.4,2.6-2.8c1.2-0.5,4.2,0.6,5,0.1c0.5-0.1,0.4-0.4,0.7-0.7
c0.6-0.4,2.8,0,3.7,0.4c3.5,1,8.2,1.6,10.9,3.4c1,4.1,0.4,9.6,0.4,14.5s0.3,4.6-0.1,5.3v7.6L414,189.8z M409.3,147v-0.2
c0.4,0,0.8,0,1.1-0.2c9.1,0,15.3,7.3,18.1,13.7c0.5,1.2,0.7,2.2,1.2,3.5c1.1,2.9,2.2,7.9,1.2,11.9c-0.8,3.2-1.8,6-3.4,8.4
c-0.8,1.2-2.1,2.1-2.9,3.4h-0.2c0.3-0.9,1-1.6,1.4-2.4c0.8-1.5,1.2-3.4,1.7-5.2c0.6-2.4,0.8-7,0.2-9.6c-0.3-1.4-0.1-2.4-0.5-3.7
c-1.8-6.4-4.9-11.6-9.6-15.1c-2-1.5-5.1-4.2-8.4-4.3L409.3,147z M437.5,165.7c2.2-0.3,4.4-1.8,4.9-3.8c0.9-3.1-2.4-7.3-4.2-7.8h-1.7
c-0.5,0.4-1.9,0.7-2.4,1c0,0.3,0,0.6-0.1,0.8c1.1,1,2,2.3,2.6,3.7c0.2,1,0.5,2.1,0.7,3.1c0,0.9-0.1,2.3,0.1,3H437.5z M437,156.4
c-0.5-0.4-1.2-0.6-1.7-1c-0.1-0.2,0,0,0-0.4c3,0,5.3,3.3,4.7,6.2c-0.2,1,0,1.8-0.5,2.4c-0.1,0.3-0.1,0.2-0.2,0.4h-0.2
c0-0.5,0.2-0.8,0.2-1.2c0.5-2.9-0.9-5.4-2.3-6.5V156.4z M337.8,191.1c-1.6,0.7-3.8,1.3-4.7,2.6c-0.6,0.9-1.1,3.1-0.6,4.7
c0.7,2.3,2.1,4.6,4.3,5.4c1.3,0.4,2.7-0.1,3.6-0.5c2.2-0.9,4.2-2,6.2-3.1c1.1-0.6,4.4-1.5,4.8-2.6c1.3,0,1.6,1.8,2.2,2.6
c1.5,2.1,3.2,4.2,5,6c2.4,2.4,9,6.2,14.5,5c1-0.2,1.7,0,2.6-0.2c2.5-0.8,4.9-1.7,6.8-3.1c6.5-4.7,9.9-15.6,6.7-26.3
c-1.5-5.1-3.6-8.8-6.1-12.8c-0.6-1-1.7-1.7-2.4-2.6c-3.4-4.6-9.2-8.1-17.3-8c-0.6,0.3-2.8,0.4-3.6,0.6c-1.5,0.4-3.2,1.1-4.4,1.9
c-6.8,4.2-10.8,14.4-8.5,25.7c-1.9,1.9-6.5,3.6-9.2,4.8L337.8,191.1z M337,200c-0.9-0.3-0.9-1.1-1.4-1.7c0-0.3,0-0.4,0.1-0.6
c0.7,0.4,0.8,0.9,1.3,1.4v0.8V200z M341.7,199.3c-0.3,1.3-0.1,2.2-0.8,3v0.2h-0.2c0.2-4.3,0.4-6.3-2.4-8.3c-0.7-0.5-1.4-1.1-2.4-1.2
v-0.2c0.3,0,0.4,0,0.6-0.1c3.3,0.6,6.2,2.4,5.3,6.6H341.7z M343.8,201L343.8,201c0,0.1-0.2,0.1-0.2,0.1c0-4,0.2-6.5-2.6-8.4
c-0.6-0.4-1.2-1-2-1.1l0,0c0.2-0.2,0.8-0.3,1.1-0.5c3.1,0,4.3,2.6,4.9,5C345.4,197.8,345,200.2,343.8,201L343.8,201z M347,199.2
c0,0,0,0-0.1,0.1c-0.4-1.2,0-2.9-0.5-4.2c-0.5-1.5-2.2-2.7-2.5-3.8c2.6,0,3,1.8,3.6,4c0.4,1.3,0.3,3.2-0.5,4V199.2z M350.7,196.4
c-0.3,0.3-0.5,0.6-0.7,1c-0.1,0-0.2,0-0.4,0.1c-0.3-0.8,0-1.9-0.2-2.8c-0.4-1.8-1.3-3.6-2.9-4.1c-0.6-0.3-1.5,0.2-1.9,0
c-0.7,0-0.8-0.2-1.2-0.5v-0.2c0.7-0.3,2.1-0.3,2.9-0.7C349.8,189.2,350.8,192.8,350.7,196.4L350.7,196.4z M354.9,192.1L354.9,192.1
c-0.2-0.6-0.4-1.3-0.6-2c-0.1-0.3-1.7-2.1-1.9-2.3v-0.2c0.9,0.3,1.9,1.4,2.3,2.3c0.2,0.4,0.1,1,0.4,1.3c0,0.4,0,0.8-0.1,1
L354.9,192.1z M357,191.8c-0.2,1.2,0,2.3-0.7,3l0,0c-1.1-0.5-0.2-4.1-0.7-5.5c-0.4-1.2-1.6-2.4-2.6-3c-0.5-0.3-1-0.3-1.4-0.6
c0-0.1,0-0.1-0.1-0.4h2.2c2.3,0.6,4.1,3.4,3.5,6.5H357z M370.9,192.3c-0.5,2-1.4,4.4-2.6,5.6c-0.5,0.5-1.3,0.7-1.7,1.3h-0.4
c0.2-0.6,0.8-1,1.1-1.6c0.6-0.9,0.9-2.2,1.2-3.4c0.4-1.8,0.1-4.4-0.2-5.9c-0.2-1-0.2-1.6-0.5-2.5c-1-3-3.2-6.7-5.5-8.4
c-1.2-0.9-3-1.4-4.2-2.4c-0.2,0,0.1-0.2,0.2-0.4c0.3,0,0.7,0,0.8-0.1c1.7,0,3,0.3,4.2,0.8c3.8,1.9,6.1,5.9,7.3,10.4
c0.5,1.7,0.7,4.4,0.2,6.4L370.9,192.3z M361.2,162.8h3.6c9.7,2.3,14.8,9.8,17.9,18.7c1.2,3.5,2.2,9.6,1.1,14.2
c-0.6,2.2-1.2,4.5-2.3,6.2c-0.6,0.9-2.3,2.5-2.6,3.4h-0.2c0.2-0.8,0.7-1.3,1-1.9c0.6-1.4,0.8-3.1,1.2-4.6c0.6-2.4,0.5-7.8,0-10.2
c-0.4-1.7-0.3-3.1-0.7-4.7c-1.9-6.2-4.9-11.9-9.5-15.4c-0.5-0.4-1-1-1.6-1.4c-2-1.4-4-2.1-6.7-2.9c-1.1-0.3-3.3-0.8-4.3,0h-0.6
C358.1,163.2,360.3,163.4,361.2,162.8L361.2,162.8z M353.2,179.7c0.4-0.1,0.9,0,1.2-0.2c4.3,0.4,7.4,5.3,8.5,9
c1.2,4.2-0.4,7.9-3.7,8.6c-0.1-0.2-0.1,0-0.2-0.4c-0.2-0.6,1.1-4,0.7-5.9c-0.8-3.7-2.4-6.4-5.2-8.2c-0.5-0.3-2.3-1.4-3.4-0.7h-0.2
C351,180.9,352.4,180,353.2,179.7L353.2,179.7z M347.7,187.4c0.3-0.1,0.6-0.2,1-0.4c3.5,0,4.1,2.8,4.8,5.4c0.2,0.8,0.5,2.7-0.1,3.4
l0,0c-0.1,0-0.2,0-0.4,0c-1.4-3.4-1.7-6.9-5.4-8C347.6,187.7,347.6,187.6,347.7,187.4z M412.6,234.8v-2.5c0-1.4,0-2.7-0.1-4.1
c0-1.3,0.1-2.9-0.1-4v-4.7c-0.2-1.1,0.1-2.6-0.1-3.8c-0.3-1.1,0.2-2.8-0.1-4.1c-0.7-3.1,0.2-6.9-0.5-10v-2.4c0-1.4-0.1-4.2-0.5-5.4
c-1.3-4.3-3.1-7.7-4.8-11.5c-1.9-4.3-3.8-8.6-5.6-12.8c-3.6-0.8-9.8,1.7-13.7,0c0,0,0,0-0.1,0.1c0.3,0.9,1,1.4,1.4,2.2
c0.5,0.9,1,1.9,1.4,2.8c2.5,5,3.5,11.3,3.4,18.8c1.3,1.3,4,3.9,5.8,4.4c0.2,2.2,0.2,5-0.2,7c0,0.8,0,1.6-0.1,2.4
c-0.4,1.6-0.5,3.7-0.8,5.3c-0.4,1.8-0.3,3.5-0.7,5.3c-1.2,4.8-1.9,9.6-3.6,14c-3.1,8.2-7.2,15.3-9.8,23.9
c-2.4,7.9-1.2,18.3,2.2,23.9c0.7,1.1,1.1,2.6,2,3.4l0,0c-1.1-0.1-3.3-3-4.3-3.7c-1.4-1.1-2.9-2.4-4.1-3.7c-0.2-0.2-0.2-0.4-0.4-0.6
c-1.9-1.7-3.7-3.5-5.3-5.5c-1-1.3-3.6-3.3-4.1-4.8c0-0.1,0,0-0.1-0.2l0.2-0.2c2.7,0,5.2-0.9,7.8-1v-0.4c-1.1-0.4-2.3-0.3-3.5-0.6
c-2.7-0.8-5.7-1.3-8.3-2.2c-0.5-0.2-0.8,0-1.3-0.2c-3.2-1.3-6.3-2.5-9.1-4.1c-3.6-2-6.4-5.1-9.2-7.9c-1.8-1.8-3.3-3.7-4.8-5.9
c-0.5-0.7-1.5-1.5-1.7-2.5h-0.2c0.1,1.8,1,3.5,1.4,5.2c0.5,1.9,0.5,4,1,6c0.3,1.3,0.1,4.9-0.1,6c-0.4,1.7-0.2,3.1-0.6,4.7
c-1,3.4-2,6.6-3.6,9.2c-1,1.7-1.9,4.3-3.4,5.4v0.4h0.1c0.6-1.1,1.7-1.7,2.4-2.8c1.2,0,2.9,1.9,3.8,2.5c3.2,2,6.4,4.1,9.7,5.9
c10.8,5.9,22.1,10.8,34.2,15.4c3.1,1.2,6.5,2.4,9.7,3.4c1,0.3,1.7,0.2,2.8,0.5c1.8,0.5,4.3,0.7,6.1,0c0.6-0.2,1.2-0.8,1.7-1.1
c0.5-1.8,0.7-4.1,1.2-6c0-0.7,0.2-1.4,0.2-2c1.8-5.5,2.5-11.6,4.1-17.4c0.7-2.5,0.6-5.2,1.2-7.7c0.3-3.4,0.6-6.7,1-10.1
c0.3-1.5,0-3.2,0.2-4.6v-4c0.4-1.6,0.1-3.8,0.1-5.6c0-2.5,0.4-5.6-0.1-7.8L412.6,234.8z M257.6,183.9c-0.8-1.7-2.9-4.8-3.1-6.8h-0.2
c0.1,1.5,0.6,3.1,1,4.4c0.8,3,1,5.8,2.2,8.4c1.6,3.6,3.6,6.6,5.9,9.6c1.5,2,3.9,3.1,5.6,4.9h0.1c-0.4-1.2-2.5-3.2-3.2-4.4
C262.8,194.9,260.3,189.5,257.6,183.9L257.6,183.9z M379.2,256.8c0-4.4-0.8-7.1-2.3-10c-3.8-7.3-9.6-11.9-17.3-15.4
c-2.2-1-4.6-1-7.1-1.7c-1.4-0.4-3.3-0.9-4.9-1c0.1,0.7,2.3,1.3,3.1,1.8c2.8,1.5,5.8,3,8.4,4.8c5.7,3.8,10,8.6,13.7,14.4
c1.7,2.6,2.8,5.7,4.4,8.3c0.5,0.8,0.8,1.9,1.6,2.5v0.2c0.5-0.3,0.4-2.7,0.4-4.1L379.2,256.8z M192.4,334.9c-1.3,0.4-2.6,0.9-3.8,1.3
c-0.6,0.2-1.2,0-1.7,0.4h-1.2c0.9,0.4,2,0.2,3.1,0.5c4.9,1.1,11.5-1.6,14.4-3.1c6.9-3.7,11.6-8.7,14.5-16.3c0.5-1.3,0.6-2.5,1-4
c0.6-2.3,1.5-7.7,0.8-10.7c-0.4-2-0.5-3.9-1.1-5.8c-1.7-5.1-4.4-8.8-7.2-12.6c-1.4-1.9-3.1-3.5-4.4-5.4c-0.5-0.7-1.2-2-2-2.3
c-0.5,1.5,0,3.5-0.5,5.2c-0.9,3.6-1.3,7.3-2.4,10.8c-1.2,3.8-2.2,7.6-3.6,11.3c-1.1,2.9-2.5,5.7-3.5,8.6c-0.6,1.9-0.5,4.2-0.5,6.6
c0,0.5,0.1,1.3,0.1,1.6c-0.2,1.9-1,3.7-1.9,4.9c-1.2,1.7-3.6,3.1-5.6,4c-1,0.4-2.8,0.4-3.6,1h-1c0.3,0.6,1.7,0.4,2.5,0.4
c1.8,0,3.7,0,5.4-0.6c5.4-1.6,7.9-4.2,10.6-8.5c0.6-1.1,1.5-2.4,1.7-3.8h0.2c0,0,0,0,0.1,0.1c0,8.8-4.6,13.6-10.4,16.6L192.4,334.9
L192.4,334.9z M182.8,314c-1.6,2.3-5.9,7.1-8.9,7.7v0.2c1.8,0,5.4-1.4,6.2-2.4c0.3,0,0.4,0,0.6,0.1c0.2,0.2,0,3.9,0,4.1
c0,0,0,0,0.1,0.1c2.1-4.3,4.9-7.8,7.3-11.8c3.2-5.2,5.1-11.3,7.2-17.6c0.6-2.6,1.3-5.2,1.9-7.8c0.2-0.8,0.7-2.7,0.4-3.1
C193.2,294.2,189,305.2,182.8,314L182.8,314z M287.8,363.3c0.9-2.9-1-6.3-1.2-8.8h-0.2c-0.3,2.8-2,4.4-2.6,7c-0.5,2,0.8,6.1-0.2,7.7
v0.2h0.2C284.5,366.9,287.1,365.7,287.8,363.3L287.8,363.3z M214.4,449.2c0.2-0.7,0.2-1.6,0.1-2.2c0-0.5,0-1-0.1-1.4
c-1.2-3.6-4.1-3-7.9-4c-2.4-0.6-4.8-1-7.6-1.2c-1.4-11.7-5.9-19-14.6-23.3c-2.6-1.3-7.4-2.5-11.4-1.4c-2.2,0.6-4.4,1.2-6.2,2.2
c-6.8,3.5-10.6,10.5-12.7,18.7c-0.4,1.6-0.4,3.3-0.7,4.8v2c-0.4,1.8-0.1,4.7,0.2,6.4c0.4,2,0.3,3.6,1,5.3c2.1,5.8,5.4,9.7,10.3,12.7
c3.8,2.3,10.6,3.3,15.8,1.4c5.6-2,9.8-6,12.7-10.7c1.2-2,1.9-5.1,3.4-6.8c1.5,0.1,3,0.6,4.3,1c1.4,0.2,2.8,0.3,4.2,0.5
c1.4,0.4,3.5,1.2,5.3,0.7C212.4,453.3,213.9,451.1,214.4,449.2L214.4,449.2z M170.7,428.1c-5.1,7.2-7,20.4-3,30.2
c0.9,2.1,1.8,5.3,3.5,6.5l0,0c-1.7,0-3.6-2.6-4.4-3.7c-1.2-1.6-2.4-3.2-3.2-5.2c-1.7-4-2.5-12.1-1.2-17.2c1.7-6.3,4.8-13,9.6-16.2
c1.1-0.7,2.4-1.4,3.7-1.8c0.7-0.2,1.5,0,2-0.4h1.2c0,0,0,0,0.1,0.1c-0.6,0.8-1.9,1.1-2.8,1.7c-2.1,1.5-4,3.8-5.5,5.9L170.7,428.1z
M179.8,451.4c0.7,2.1,1.7,2.9,2.8,4.4h0.1c-1.4,0-2.6-1.1-3.4-2c-1.1-1.2-2-2.4-2.6-4.1c-1.7-4.7,0.5-11.5,2.4-14.3
c1.2-1.6,2.9-3.2,4.8-4.1c0.9-0.4,2.1-0.3,2.9-0.8c0.3,0,0.6,0,0.8,0.1c-0.3,0.4-0.9,0.4-1.3,0.7c-0.8,0.8-1.6,1.6-2.4,2.4
C181,437.5,177.6,444.4,179.8,451.4L179.8,451.4z M191,439.5c-1,1.4-1.8,3.2-2.3,5c-0.5,1.7,0,4.2,0.5,5.4c0.2,0.6,1.2,1.4,0.8,2.2
v0.2c-0.9,0.2-2.3,0.3-3.1-0.2c-1.7-1.1-2.8-5.6-2-8.4c0.8-2.7,2-5.8,4.1-7.3c0.6-0.4,1.3-0.5,1.8-1c1.4,0.1,2,0.9,2.9,1.4
C193.6,438.1,191.7,438.5,191,439.5L191,439.5z M194.1,450.3c-0.9,0-0.9-0.5-1.3-1c-2.6-2.8-0.5-6.4,1-8.6c0.3,0,0.3,0,0.5-0.1
c0.3,0,0.6,0,0.8,0.1c-0.3,0.9-1.2,1.6-1.6,2.6c-0.6,1.8-0.3,5.5,0.6,6.7V450.3L194.1,450.3z M195.9,448.2c0,0.6,0.2,1.8-0.2,2
c-0.1,0,0,0-0.2,0.1c-0.7-1.1-1.2-5-0.7-6.7c0.2-0.8,0.7-1.2,0.7-2.3c1.1,0.2,0.7,2.2,0.6,3h0.2c0.3-1,0.7-2.1,1.1-3
c0.3,0,0.8-0.1,1-0.2c0.3,0,0.5,0,0.7,0.1c-0.3,1.3-1.6,2.3-2.2,3.5C196.4,445.6,196.5,447.3,195.9,448.2z M199.8,445.4
c-0.3,0.9-0.3,3.3-0.7,4v1.4c-0.3,0-0.3,0.1-0.6,0c-0.5,0-0.5-0.2-0.8-0.4c0-4,1.1-6.8,3.1-8.8c0.2,0,0.5,0,0.7,0.1c0,0,0,0,0.1,0.1
C201.6,442.7,200.2,444.2,199.8,445.4L199.8,445.4z M203.7,452.2c-0.9,0-1.9-0.7-2.2-1.4c-1.2-1.7,0.2-5.7,0.8-6.8
c0.4-0.5,0.9-1,1.3-1.6c1,0,1.4-0.1,2.4-0.1c-0.2,1-1.8,2-2.3,3.1c-0.9,2-0.4,4.7-0.1,6.8H203.7z M207.4,445.2
c-1.2,1.7-1.2,6.1,0.2,7.6v0.2c-2.5-0.6-3.2-4.7-1.9-7.3c0.7-1.4,1.7-2,2.9-2.9c0.4,0,0.8,0,1.2,0.1
C209.2,443.8,208,444.3,207.4,445.2L207.4,445.2z M149.7,439.3c0.3,0,0.3,0,0.5-0.1c0.3-0.5,1-4.4,1-4.4c-0.5,1.1-1,2.5-1.8,3.4
c0,0.7,0.1,0.8,0.4,1.2L149.7,439.3z M467.1,473.5c-0.6-2-1.8-3.8-3.5-4.7c-2-1-5.7,0.1-7.1,1c-0.7,0.4-3.9,2.5-4.9,1.9
c-0.8,0-1.3-0.5-1.4-1.2c-0.9-1.3,1.2-2.9,2-3.1c0.9-0.3,1.7,0.7,2,0.5c1.5-0.7,2.5-3.3,4-3.8v-0.2c-1.5-1-2.5-1.7-5.2-1.7
c-0.4,0.3-1,0.2-1.4,0.4c-0.9,0.4-2.1,0.9-2.8,1.6c-1.4,1.3-2.9,3-3.6,4.9c-1.4,3.8,1.2,6.9,3.6,8c2.9,1.4,7.1-1.4,8.8-2.3
c0.7-0.4,2.4-1.4,3.5-0.6c1,0.3,1,1.3,1,2.4c-0.9,1-1.6,2.5-3.4,1.9c-0.5-0.2-0.7,0-1-0.5c-1.3,0.3-2.9,3-4.1,3.8
c0.3,1.8,4.6,2.3,6.5,1.7c3.1-1,5.3-3.5,6.5-6.5C466.9,476.3,467.4,474.8,467.1,473.5L467.1,473.5z M104.6,471.7
c-0.5-1.4-3.4-3.2-4.3-4.6h-0.4c-0.7,0.9-4.3,2-5.5,2.6c-5,2.6-10.2,5.1-15.4,7.3c0.5,1.4,2.9,2.7,3.7,4c0.9,0,3.1-1.2,3.6-1.7
c0.8,0.2,0.9,0.8,1.4,1.2c1.7,1.3,2.9,3.3,4.7,4.4c-0.3,0.9-1.2,2.9-1.8,3.6c0.2,0.5,3.4,3.8,4,4.1c0,0,0,0,0.1,0.1
c1.8-4.2,3.8-8.4,5.9-12.5c1.1-2.3,2-4.6,3.1-6.7c0.3-0.7,0.4-1.4,0.8-1.9L104.6,471.7z M96.8,476.1c-0.8,1.6-1.2,3.5-2.3,4.8
c-0.1,0,0,0-0.2,0.1l-3.6-3.6c0.4-0.7,1.6-0.8,2.3-1.2c1-0.5,2-1.2,3-1.7c0.7-0.4,1.4-0.5,1.9-1h0.2c-0.3,0.9-0.9,1.7-1.3,2.5V476.1
z M448.2,487.8c-0.5-0.7-1.1-1.5-1.9-1.9c-4.4-2.3-8.9,3.2-12.5,1.8c-1-1.2-1.1-3.2,0.7-3.7c1-0.8,2.2,0,2.9,0.5
c0.3-0.5,3.8-3.5,4.3-3.7v-0.2c-1.6-1.1-2.6-2.1-5.5-2c-0.3,0.2-0.8,0-1.2,0.2c-0.7,0.2-1.5,0.6-2.2,1c-2.2,1.2-4.4,3.7-5,6.5
c-0.5,1.9,0.5,3.5,1.1,4.4c0.7,1,1.6,2,2.8,2.5c2.8,1.1,6.3-0.9,8-1.7c0.7-0.3,1.6-0.3,2.2-0.7c1.3,0,1.5,0.4,2.3,0.7
c0.1,0.4,0.6,1.1,0.2,1.6c-0.1,1.9-3.3,3.5-4.6,1.6c-0.7,0.2-4.3,3.5-4.3,3.8c0.6,1.5,4.4,2.7,6.6,1.9c3.1-1,5.6-3.1,6.8-6
c0.4-0.8,1-2.4,0.6-3.8c-0.3-1.1-0.8-1.9-1.3-2.6V487.8z M117.7,494.7c-1.3-1.1-2.8-2.1-4-3.4c0-0.1,0,0-0.1-0.2
c0.9-0.9,2-2.2,2.6-3.2c0.7,0.1,0.9,0.6,1.3,1c1.6,1.2,3.4,3.1,5,4h0.1c0.6-0.8,1.8-2.5,2.6-3.1c-0.5-1-2-1.8-2.9-2.4
c-1.8-1.3-3.4-2.9-5.3-4.3c-0.8-0.6-1.4-1.6-2.4-1.9c-0.7,1.3-1.9,2.2-2.8,3.4c-1.7,2.3-3.7,4.3-5.4,6.6c-1.4,1.8-3,3.5-4.3,5.3
c-0.4,0.6-0.8,1.2-1.3,1.7c0.4,1,1.8,1.7,2.6,2.3c2.1,1.5,3.9,3.4,6,4.9c0.6,0.4,1,1.1,1.7,1.4c0,0,0,0,0.1,0.1
c0.6-0.9,1.8-2.6,2.6-3.2v-0.2c-0.7-0.4-1.2-1.1-1.8-1.6c-1.1-0.8-2.2-1.5-3.2-2.4c-0.4-0.3-0.6-0.8-1.2-1c0.6-1.5,2.4-2.3,2.9-3.8
h0.2c0.8,0.8,1.8,1.5,2.8,2.2c0.9,0.6,1.6,1.8,2.6,2.2c0.1,0,0,0,0.2,0.1c0.6-0.9,1.5-2.3,2.4-2.9c-0.2-0.8-0.8-0.9-1.3-1.3
L117.7,494.7z M429.3,503.8c-2.1-2.9-4.5-5.7-6.6-8.6c-0.6-0.9-2.6-4.1-3.4-4.4c-0.3,0.4-3.5,2.8-4.1,3c0.2,1.3,2.7,3.8,3.5,4.9
c2.2,3,4.5,5.9,6.6,8.9c0.9,1.3,1.7,3.2,3,4.1c0,0,0,0,0.1,0.1c0.9-1.4,3.1-1.9,4.1-3.2c0,0,0,0,0.1-0.1
C431.6,507.8,430,505.1,429.3,503.8L429.3,503.8z M134.2,496.2c-0.8,0-1.6-0.2-2.4-0.2c-0.6,0.4-1.6,0.2-2.2,0.5
c-1.6,0.8-3.1,2.6-3.6,4.4c-0.9,3.2,2.5,6.8,3.7,8.3c0.5,0.6,2,2.4,1.1,3.6c-0.3,0.6-0.7,0.7-1.2,1.1c-2.1,0-2.3-1-3.4-1.9
c0-0.8-0.2-1.5,0.2-2c-0.2-1.2-3.5-2.4-4.3-3.4c-1.7,0.2-1.6,4.6-1,6c1.2,2.8,3.8,4.8,7,5.8c0.9,0.3,2.5,0.6,3.7,0.2
c1.1-0.4,2-1,2.8-1.7c0.5-0.5,1.1-1,1.4-1.7c2.7-5.9-4.5-8.1-4.4-13c0.4-0.5,0.9-0.9,1.3-1.3c0.4,0,0.8,0,1,0.1
c1.3,0.1,1.5,1.1,2.2,1.8c0,0.8,0,1.4-0.4,1.8c1.1,1.5,3.5,1.9,4.7,3.4c1.3-0.6,1.5-4.2,0.8-5.8C140,499.4,137.2,497.2,134.2,496.2
L134.2,496.2z M412.4,505.4c-0.5-1.7-0.9-3.4-1.4-5c-0.2-0.8-0.2-1.6-0.6-2.2c-0.1,0-0.1,0-0.4-0.1c-0.5,0.6-3.7,2.4-4.6,2.8
c0,1.4,0.8,2.5,1.2,3.6c0.9,2.5,1.7,5,2.6,7.4c0.5,1.3,0.6,3.1,1.4,4.1v0.2c-0.8-0.4-2.8-2.7-3.4-3.5c-0.5-0.5-1-1-1.6-1.4
c-1.3-1.7-3.1-3.1-4.4-4.8c-0.6-0.6-1.3-1.3-1.9-1.9c-2,0.4-3.3,2.3-5.2,2.9c0.2,1.5,0.9,3,1.3,4.3c0.7,2,1.2,4,1.8,5.9
c0.4,1.3,0.9,4.3,1.6,5.2v0.4c-1-0.5-1.6-2-2.4-2.8c-1.8-1.9-3.7-3.8-5.3-5.9c-0.8-1.1-2.4-1.9-2.9-3.2c-0.3,0-0.2,0-0.4,0
c-1.4,0.3-3.5,2.1-4.8,2.8c0.7,1.5,3,3,4.2,4.2c2,2,4,4.1,5.9,6.1c0.5,0.6,1.2,1,1.8,1.6c0.5,0.5,0.9,1.2,1.4,1.7
c0.1,0.1,0.4,0.1,0.5,0.2c0.7,0.7,1.2,1.9,2.2,2.3c0.4,0.1,1.1-0.6,1.6-0.8c1.3-0.8,2.7-1.6,4.1-2.3c-0.2-1.3-0.7-2.5-1.1-3.6
c-0.7-2.2-1.2-4.4-1.9-6.5c-0.4-1.2-0.4-3.1-1.2-4v-0.2c1,0.5,2.4,2.4,3.1,3.2c0.3,0.4,0.9,0.6,1.2,1c1.1,1.1,2,2.3,3.1,3.4
c1,1,2.1,1.8,2.9,3c2-0.7,3.6-2.4,5.6-3.2c-0.1-1.7-0.8-3.2-1.3-4.7c-1.1-3.3-1.9-6.7-2.9-10L412.4,505.4z M164.2,514.4
c-0.2-0.8-3.1-1.9-4-2.4c-2.2-1.2-4.4-2.6-6.6-3.8c-1.3-0.7-3.1-1.1-4-2.2c-0.7,0-2.4,3-2.4,3.8c1.9,0.3,3.1,2.1,4.9,2.5
c0,1.2-1.5,2.9-2.2,4.1c-1.4,2.7-2.8,5.4-4.3,7.9c-0.7,1.1-1,2.7-1.9,3.5c0.2,0.6,3.9,2.5,4.7,2.9c2.8-5.2,5.7-10.3,8.5-15.5l0,0
c1,0.2,1.7,0.9,2.5,1.3c0.9,0.5,1.8,0.7,2.5,1.3c0.9-0.5,1.3-2.8,2.2-3.5L164.2,514.4z M180.1,528c-0.4,0.7-0.4,1.6-0.8,2.2
c-0.3,0.2-1.5-0.4-1.9-0.6c-1-0.4-2.1-1-3-1.4s-2-0.5-2.6-1.1c-0.2-0.5,0.5-1.4,0.7-1.9c0.7-1.6,1.4-4.5,2.4-5.8
c-0.2-0.7-4.1-2.1-4.9-2.4c-2.1,4.9-4.2,10-6.4,14.8c-0.7,1.6-1.1,4.1-2.2,5.3c0.2,0.7,4,2.1,4.9,2.4c0.8-1.9,1.6-3.9,2.5-5.8
c0.4-0.8,0.4-1.9,1.1-2.5c0,0,1.3,0.4,1.7,0.6c1.9,0.8,3.9,1.7,5.9,2.5c-0.2,0.9-0.6,1.6-1,2.3c-0.9,2.1-1.6,4.1-2.5,6.1
c1.6,0.7,3.1,1.4,4.7,2c0.8-0.2,2-4.1,2.4-5c1.5-3.5,3-7.2,4.6-10.8c0.6-1.3,1.8-3,1.9-4.6c-1.9-0.2-3.2-1.7-5-1.9
C182.3,524.2,181,526.5,180.1,528L180.1,528z M381.2,527.4c-2-0.9-7.2,0-9.8-0.2c-0.3-0.2-0.6-0.5-0.8-0.7c-0.2-0.3-0.2-0.6-0.4-0.8
c0-1,0.4-1.3,0.8-1.9c1.3-0.4,3.3-1,3.8,0.7c0.7,0,4.9-1.9,5.3-2.3h0.1c-0.8-2.5-3.4-3.8-6.8-3.7c-0.5,0.3-1.2,0.2-1.8,0.4
c-0.9,0.3-2,0.7-2.9,1.2c-1.5,0.8-3.1,2.4-3.7,4.1c-1.1,3,0.7,6.3,2.4,7.3c1.4,0.9,4.6,1.2,6.8,0.7c1.1-0.2,3.2-0.5,4.1,0.1
c0.6,0.2,0.7,0.7,1.1,1.2c0,2.3-1.4,3-3.7,2.9c-0.5-0.5-1-0.9-1.4-1.3c-0.5-0.2-4.4,1.8-5.3,2.2c0.9,3,5.1,4.8,9.1,3.5
c3.2-1.1,5.8-3,6.7-6.4c0.3-1.3-0.2-2.7-0.5-3.4C383.6,529.3,382.8,528.2,381.2,527.4L381.2,527.4z M204.6,530
c-2.2-0.8-4.7-1.5-6.8-2.3c-0.9-0.3-2-0.3-2.8-0.8c-0.2,0-0.2,0-0.4,0.1c-0.8,1.1-1.1,3.6-1.6,5c-1.1,3.3-2.2,6.9-3.4,10.2
c-0.6,1.6-0.8,4.2-1.7,5.5c0.4,0.6,2.7,0.9,3.5,1.2c3,1,6.3,1.9,9.4,3c0.6-1,1.3-2.9,1.3-4.2c-2.7-0.3-5-2-7.7-2.3
c0.3-1.6,1.2-3,1.3-4.7c2.4,0.4,4.6,1.6,7,2.2c0.5-1.2,0.9-2.6,1.2-4c-2.2-0.7-4.4-1.4-6.6-2.2h-0.2c0.4-1.3,1.1-2.6,1.2-4.1
c0,0,0,0,0.1-0.1c1.8,0.2,3.5,1,5,1.6c0.8,0.3,1.7,0.3,2.4,0.7c0.2,0,0.2,0,0.4-0.1c0.7-0.9,0.5-2.5,1.2-3.4c0-0.3,0-0.4-0.1-0.6
c-0.7-0.5-1.9-0.5-2.8-0.8L204.6,530z M346.3,547.3c-0.8,0.3-1.9,0.2-2.5,0.7h-0.2c-0.4-1.6-0.9-3.1-1.3-4.7c1.7-0.6,3.6-1,5.3-1.6
c0.5-0.2,0.9-0.1,1.3-0.4h0.2c-0.1-1.5-0.9-2.5-1-4c-1.4,0-2.4,0.6-3.5,1c-1.2,0.4-2.4,0.5-3.5,1c-0.6-1.1-0.5-3.1-1.3-4.1V535
c1.8-0.4,3.6-0.8,5.4-1.4c0.7-0.2,1.5-0.7,2.4-0.7c0-1-0.6-3.3-1.1-4v-0.2c-3,0.7-6,1.6-8.8,2.5c-1.3,0.4-2.9,0.4-4,1.1
c-0.1,0-0.2,0-0.4,0.1c1.3,4.9,2.7,10.1,4.3,14.9c0.5,1.6,0.9,5.1,1.8,6.2c0.1,0,2.6-0.5,3.1-0.7c2-0.7,4.2-1.3,6.2-1.9
c1.1-0.4,2.5-0.3,3.4-1h0.2c-0.3-1.4-1-2.6-1.1-4.1c-1.8,0.1-3.6,0.9-5.2,1.4L346.3,547.3z M217.2,533.4c-1.2-0.3-2.6-0.8-4-0.8
c0,1.4-0.4,2.9-0.8,4.1c1.3,0.5,4.5,0.7,5.5,1.3h0.2c-1.2,5.6-2.5,11.2-3.7,16.8v0.6c1.7,0.4,3.4,1.1,5.3,1.1c0-1.9,0.7-3.8,1.2-5.5
c0.7-2.5,0.7-4.9,1.4-7.3c0.4-1.3,0.9-3,1-4.4c2,0.1,3.9,0.8,5.8,1.2c0.5-1.2,0.9-3,1-4.3c-2.6-0.2-5.3-1-7.7-1.7
C220.6,534,219,534,217.2,533.4L217.2,533.4z M328.6,547.3c-0.6-2.2-0.9-4.9-1.4-7.1c-0.5-1.7-0.2-4.6-1.1-5.9
c-0.5-0.3-3.9,0.7-5,0.7H321c0,1.5,0.4,2.9,0.7,4.1c0.5,1.8,0.5,3.7,1,5.3c0.3,1.2,0,2.9,0.7,3.8v0.2c-1.2-0.5-2.1-2.2-3-3.1
c-1.7-1.9-3.6-3.8-5.4-5.6c-0.9-0.9-2.2-1.8-2.9-3c-1.8,0.3-3.5,0.6-5.3,1c0,2.3,0.7,4.7,1.2,6.7c0.7,2.7,0.8,5.4,1.4,7.9
c0.5,1.8,0.5,5.9,1.3,7.1c0,0,0.9,0,1.3-0.1c1.1-0.3,2.6-0.7,3.8-0.7c-0.2-2.6-0.8-5.4-1.4-7.8c-0.5-1.8-0.3-3.7-1-5.4h0.2
c0.8,1.1,2,2,3,3c1.6,1.7,3.1,3.4,4.7,5.2c1,1.2,2.4,2,3.2,3.4c1.9,0,3.4-0.8,5.3-0.8h0.1c-0.2-2.3-0.7-4.5-1.2-6.5
c0-0.8-0.2-1.5-0.2-2.3L328.6,547.3z M237.2,537.1h-0.7c-1,7.3-2.1,14.6-3.1,21.8c1.6,0.2,3.3,0.5,4.9,0.7h0.4
c1.1-5.2,1.2-10.9,2.4-16.1c0.4-1.5,0-4.1,0.7-5.2c0-0.3,0-0.4-0.1-0.6C240.7,537.1,238.3,537.6,237.2,537.1L237.2,537.1z
M293.8,538.9c0,1.7,0.1,3.4,0.5,4.9c0.4,1.7,0.1,3.6,0.5,5.2c0.2,2.1,0.3,4.2,0.5,6.4c0.3,1.2,0.7,4.5,0.5,5.5h0.1
c1.8,0,3.6-0.2,5.4-0.4c-0.7-7.4-1.4-14.7-2.2-22.1C297.4,538.6,295.6,538.9,293.8,538.9L293.8,538.9z M264,539.6h-2
c0,0.1,0,0.2-0.1,0.4c-1,1-1.8,2-2.6,3.1c-1.5,1.9-3.5,3.5-4.8,5.5h-0.2c0.2-3.2,0.4-6.3,0.6-9.5c-0.9,0-4.5-0.6-5.2-0.2h-0.2
c0,7.1-1.3,14.6-1.3,22c0.9,0,4,0,4.6,0.4c0.3,0,0.5,0,0.7-0.1c0.5-0.8,0-2.2,0.2-3.2c0.4-1.8,0.4-4.3,0.5-6.2h0.1
c0.9,1.8,2.3,3.3,3.4,4.9c0.5,0.7,0.8,1.5,1.3,2.3c0.6,0.9,1.6,1.8,1.9,2.9c1.2,0,5.8,0.5,6.2,0.2h0.2v-0.2
c-0.8-0.7-1.2-1.8-1.8-2.6c-1.2-1.8-2.6-3.5-3.8-5.3c-0.8-1.1-1.3-2.4-2.3-3.4c0.2-1.1,1.5-1.6,2-2.4c1.5-2,3.4-3.6,4.9-5.5
c0.6-0.8,1.2-1.7,2-2.3c-0.6-0.9-3.4,0-4.3-0.6L264,539.6z M280.9,557.6c-0.2-6-0.4-11.9-0.6-17.9c-1.8,0-3.6,0-5.4,0.1
c0.2,7.4,0.5,14.7,0.7,22.1c4.1-0.1,8.2-0.2,12.4-0.4c0-1.4,0-2.9-0.1-4.1c-2.3,0-4.6,0-7,0.1V557.6z"/>
<path class="st1" d="M545.4,285.3c0-0.5-0.2-1.5-0.1-1.8c0.2-1.4,0-3,0-4.6v-2.3c0-0.4-0.3-1.9-0.2-2.3c0.2-1.4-0.1-6.1-0.5-7.7
c-0.3-1.5,0-3.3-0.2-4.4c-0.7-3-0.6-6.7-1.2-9.4v-1.2c-0.5-2.2-0.4-4.5-1-6.8c-1.7-6.9-2.2-14.4-4.3-20.9c0-0.6-0.2-1.1-0.2-1.7
c-0.6-1.9-1-3.8-1.7-5.8c-0.4-1.1-0.3-2.4-0.8-3.2c0-0.4,0-0.3-0.2-0.4c0.2-0.7-0.4-1.7-0.6-2.3c-0.3-0.8-0.2-1.5-0.5-2.3
c-0.4-1.3-1-2.8-1.4-4.1c-2.5-7.6-4.7-14-7.8-21.1c-1-2.3-1.6-4.7-2.8-6.8c-1.4-2.6-2.9-5.6-4.1-8.3c-1-2.4-2.1-4.7-3.5-6.7
c0-0.1,0-0.2,0.1-0.4c-0.2-0.5-0.8-1.1-1.1-1.6c-1.5-2.8-2.9-5.5-4.6-8.2c-0.2-0.4-0.2-0.9-0.5-1.2c-0.1,0-0.2,0-0.4-0.1v-0.5
c-0.4-0.7-1-1.5-1.4-2c-0.4-0.6-0.6-1.3-1-1.9c-0.7-1.2-1.6-2.4-2.4-3.5l0,0c-0.2,0.2-0.2,0-0.2,0h0.2c-0.1-0.2-0.2-0.3-0.3-0.5
c-0.2-0.2,0-0.6-0.2-0.8c-0.4-0.6-1-1.3-1.4-1.9c-0.2-0.3-0.1-0.7-0.4-1c-1-1.3-2-2.7-3-4v-0.4c-0.4-0.6-0.9-1.1-1.3-1.7
c-0.4-0.5-0.5-1.2-1-1.7c-0.1,0-0.2,0-0.4-0.1c-0.4-0.6-0.7-1.2-1.1-1.8c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.3-0.2-0.6-0.4-0.8
c-0.2,0-0.3-0.2-0.5-0.2c0-0.2,0-0.4-0.1-0.6c-0.5-0.8-1.4-1.5-1.9-2.3c-1.7-2.4-3.5-4.6-5.2-7l-1.3-1.3c-1-1.4-2.1-2.6-3.1-4
c-0.4-0.3-0.7-0.6-1.1-1c-1.3-1.7-2.7-3.4-4.2-4.9l0,0h-0.2c-0.6-1.4-2.4-2.6-3.5-4c-1.1-1.5-2.5-2.6-3.7-3.8
c-0.7-0.7-1.2-1.7-1.9-2.3c-1.4-1.1-3.4-2.8-4.4-4.2c-1.1,0.2-1.4,1.8-2.2,2.4c1,1.2,2.2,2.2,3.2,3.4c0.3,0.3,0.7,0.4,1,0.7
c0.8,1.1,1.8,1.8,2.8,2.8c1.9,1.9,3.6,4,5.4,6.1c0,0,0.2-0.2,0.4,0c1,1,2.2,3,3.4,4.2c3.9,4,7.3,8.4,10.6,12.8c1,1.4,2.3,2.7,3.4,4
c0.4,0.4,0.4,1,0.7,1.4c1.3,1.9,2.9,3.7,4.2,5.6c2.7,4,5.5,8.1,8,12.2c0.5,0.8,1.8,3.6,2.3,3.6c0.6,1.6,1.5,2.7,2.3,4.1
c0.4,0.7,0.5,1.3,1.1,1.8c-0.1,0,1.1,2.6,1.4,3.1c2.1,3.4,3.9,7.2,5.8,10.8c2.5,4.8,4.5,10,6.7,14.9c0,0.3,0,0.6,0.1,0.8
c1.2,2.8,2.3,6.2,3.7,8.9v0.7c0.2,0.7,0.5,1.6,0.8,2.4c1,2.4,1.7,5.1,2.6,7.8c1.4,3.9,2.3,8.6,3.8,12.5c0.3,0.9,0.2,1.6,0.5,2.5
c0.7,2.2,1.2,4.9,1.9,7.2c0.3,0.9,0.1,1.6,0.4,2.4c1,3.4,1.4,7.3,2.3,10.8c0,0.8,0.2,1.6,0.2,2.4c0.8,3,0.9,7.1,1.7,10.4v1.3
c0.7,2.8,0.4,6.3,1.1,9.1c0,1.2,0,2.4,0.1,3.6c0.7,3,0,6.6,0.6,9.8c0.2,1.2,0.1,3.7,0.1,5.5v2c0.3,1.1,0.2,3.3,0,4.4
c-0.4,1.9,0.1,5.4-0.1,7c-0.4,2.4,0,5-0.4,7c-0.2,0.9,0.1,2.1,0,2.6c-0.2,1.1-0.2,2.9-0.5,4v1.8c-0.4,1.4,0,3.2-0.5,4.8
c-0.4,1.5-0.6,3.6-0.7,5.2c-0.1,1-1,5.1-0.6,5.6l0,0h-0.2c0,2-0.4,3.6-0.8,5.2c-0.6,2.3-0.6,4.5-1.2,6.6c-0.4,1.5-0.3,2.8-0.7,4.3
c-1.2,4-1.9,8.4-3.1,12.4c-1.1,3.5-1.5,7-2.8,10.3c-1.8,4.9-3.2,10-5,14.9c-2.5,6.7-5.4,13.6-8.5,19.8c-0.3,0.6-0.3,1.1-0.6,1.8
c-0.5,1.1-1.4,2.3-1.9,3.5c-1.5,3.6-3.4,7.1-5.4,10.3c-0.5,0.9-0.8,1.8-1.3,2.8c-0.4,0.7-1.1,1.4-1.4,2.2v0.5
c-2.2,3.2-4.1,6.3-6.1,9.6c-0.4,0.6-0.6,1.4-1,2c-0.2,0-0.3,0.2-0.5,0.2v0.4c-0.6,0.9-1.5,1.7-2,2.6c-0.4,0.8-0.7,1.5-1.3,2v0.5
c-0.8,0.2-1.1,1.4-1.6,2c-0.3,0.4-0.8,0.8-1.1,1.2c-1,1.3-1.4,2.4-2.4,3.6c0.5,0.9,1.8,1.2,2.4,2c0.8-0.4,1.1-1.5,1.6-2.3
c0.3-0.5,1.1-1,1.4-1.6c0.9-1.4,1.6-2.7,2.5-4.1c0.2,0,0.3-0.2,0.5-0.2c0.8-1,1.1-2.2,1.9-3.2c0.4-0.5,0.9-0.9,1.2-1.4
c2.2-3.4,4.2-6.9,6.4-10.3c2.3-3.6,3.9-7.7,6.2-11.3c0.6-0.9,0.7-1.8,1.2-2.8c1.2-2.1,2.7-4.6,3.6-6.8c0.3-0.7,0.3-1.1,0.6-1.8
c1.2-2.5,2.6-5.4,3.8-7.9c0.3-0.6,0.2-1.1,0.5-1.7c1.2-2.4,2.2-5.3,3.4-7.8c0-0.4,0.2-0.8,0.2-1.2c0.3-0.8,0.8-1.9,1.2-2.8
c0.3-0.6,0.2-1.2,0.5-1.8c2.6-5.9,4.3-12.7,6.2-19.1c2-6.6,2.9-13.6,4.8-20.5c0.1-0.9,0.2-1.8,0.4-2.6c1.1-4.1,1.2-8.9,2.3-13.4
c0.5-1.9,0.3-4,0.7-5.8v-1.9c0.4-1.7,0.3-4,0.7-5.8v-1.9c0.7-3,0-6.8,0.7-9.8v-4.4c0.5-2.3,0.1-6.2,0.1-9.1L545.4,285.3z
M496.3,443.4v-0.2h0.1C496.4,443.4,496.4,443.4,496.3,443.4z M57.1,448.9c-0.5-0.6-0.7-1.2-1.1-1.8c-1-1.4-2.1-2.7-3.1-4.1
c-2.7-3.7-4.9-7.5-7.4-11.4c-0.7-1.1-1.8-2.2-2.4-3.4c-1.8-3.3-3.5-6.6-5.6-9.6c0,0,0-0.2,0.1-0.2c-0.2-0.5-0.8-1.6-1.1-2
c-2-3-3.3-6.4-5-9.7c-5.3-10-9.4-21-13.6-32.2c-1.9-5.2-2.9-10.8-4.9-16v-0.7c0-0.3-0.4-0.8-0.5-1.1c-0.3-0.6,0-1.2-0.2-1.8
c-1.5-3.9-2-8.6-3.1-12.7c-0.4-1.6-0.4-3.3-1-4.8v-1c-0.1-0.5-0.4-1.3-0.5-1.8v-1c-0.4-1-0.3-2.8-0.7-4.3c-0.9-3.5-0.7-7.4-1.7-11.2
v-1.7c-0.4-1.8-0.3-4.1-0.7-5.9v-1.9c-0.7-2.9,0-6.6-0.7-9.6v-4c-0.4-1.9-0.1-5.4-0.1-7.9c0-2.2,0-4.6,0.1-6.6
c0-1.4-0.2-3.2,0.1-4.4c0.3-1.3,0-3.5,0.2-4.4c0.5-2.4,0-5.5,0.6-7.3c0.4-1.3,0-3,0.2-4.3c0.7-2.8,0.5-6.1,1.2-8.9
c0.3-1,0-1.8,0.2-2.9c1.2-4.4,1.3-9.3,2.5-13.6c0.3-1,0.4-2.3,0.6-2.9c0.3-0.7,0-1.2,0.2-1.9c0.3-0.7,0.5-1.8,0.7-2.5
c0.1-0.5,0.3-1.1,0.1-1.4l0,0h0.2c0.2-1.9,0.7-3.6,1.2-5.4c0.2-0.6,0.8-1.8,0.5-2.3l0,0h0.2c0.2-2.6,1.4-5.2,2.2-7.6
c1.4-4.6,2.6-9.2,4.4-13.4c0.6-1.3,0.6-2.6,1.2-3.8c1.2-2.7,2.1-5.5,3.2-8.3c0.7-1.8,1.8-3.7,2.5-5.4c2-4.6,3.9-9,6.2-13.2
c0.9-1.6,1.7-3.4,2.5-4.9c0.2-0.4,0.2-1,0.5-1.4c2.4-3.7,4.5-7.7,6.8-11.4c0.4-0.6,0.5-1.2,0.8-1.8c1.8-2.6,4-5.9,5.9-8.8
c0.2-0.3,0.1-0.6,0.4-1c1.7-2.5,3.7-4.8,5.3-7.6c0,0,1.1-1,1.2-1.2c0.5-0.6,0.7-1.2,1.1-1.8c0.5-0.8,1.4-1.4,1.9-2.2
c0.4-0.6,0.6-1.3,1.1-1.8c0.8-0.9,1.5-1.7,2.2-2.8l0.6-0.4c3-3.8,6.2-8.1,9.5-11.2c0.4-0.4,0.9-1.3,1.3-1.8c0.1,0,0.2,0,0.4-0.1
c0.5-0.5,1-1.1,1.4-1.6c1.4-1.7,2.9-3.4,4.6-4.8c0.9-0.8,1.5-2.3,2.8-2.8c-0.1-1-1.4-2-1.9-2.8h-0.2c-0.5,0.9-2,2.1-2.8,2.8
c-0.6,0.5-1,1.3-1.6,1.9c-1.3,1.3-2.6,2.4-3.8,3.7c-1,1.1-1.8,2.3-2.8,3.4c-0.4,0.4-1,0.7-1.3,1.2c-0.4,0.5-0.6,1.1-1.2,1.4v0.4
h-0.4c-0.4,0.8-1.1,1.3-1.7,1.9c-0.3,0.3-0.4,0.8-0.7,1.2c-1.2,1.6-2.8,2.9-4,4.4c-0.8,1-1.4,2-2.2,3c-0.2,0.2-0.4,0.3-0.6,0.5
c-0.5,1-1.7,2.7-2.5,3.4v0.5c-0.2,0-0.2,0-0.4,0.1c0,0,0,0-0.1,0.1h0.1c-0.2,0.3,0-0.1-0.4,0.1c0,0.2,0,0.3-0.1,0.5
c-0.8,1.2-1.6,2.2-2.4,3.5c-0.2,0.1-0.4,0.2-0.6,0.4c-0.4,0.5-0.6,1.1-1,1.7c-0.7,1-1.7,2.1-2.4,3.1c0,0.1,0,0.2,0.1,0.4
c-0.2,0.3-0.8,0.6-1,0.8c-0.6,0.8-0.9,2-1.6,2.8c-0.4,0.5-1.1,1.2-1.4,1.8v0.5c-0.2,0-0.3,0.2-0.5,0.2c-0.4,0.5-0.5,1.1-0.8,1.7
c-0.3,0.4-0.8,0.9-1.1,1.3c-8.6,14-16.8,29.8-23.2,46.3c-2.4,6.3-4.5,12.7-6.6,19.2c-1.1,3.4-1.9,7.3-2.9,10.3
c-0.4,1.2-0.4,2.2-0.7,3.5c-1.3,4.3-1.9,9.1-3.1,13.6c0,0.7-0.2,1.4-0.2,2.2c-1.3,4.5-1.3,9.7-2.4,14.4c-0.5,2.1-0.3,4.2-0.7,6.1
v1.8c-0.8,2.9-0.3,6.7-1,9.7c-0.3,1.5,0,3.2-0.2,4.6c-0.3,1.5,0.2,3.6,0,4.8c-0.4,2.5-0.2,5.7-0.2,8.5c0,1.6-0.2,3.6,0.1,4.9
c0.3,1.2-0.1,2.9,0.1,4c0,1.9,0,3.8,0.1,5.8c0.3,1.1,0.1,2.7,0.4,3.8v1.9c0.2,0.9,0,2,0.2,3c0.8,3.1,0.5,6.9,1.2,10
c0,1,0.2,2.1,0.2,3.1c1.3,5.1,1.4,10.6,2.8,15.7c0.3,1,0,1.7,0.4,2.6c0.6,1.8,1.1,4.8,1.7,6.8c0.3,1,0.1,1.8,0.5,2.8
c1,2.4,1.2,5.2,2,7.8c1.4,4.2,2.7,9.1,4.1,12.8c0.3,0.9,0.3,1.5,0.6,2.4c1.5,3.7,2.6,7.3,4.2,10.8c0.2,0.5,0.1,0.9,0.4,1.4
c1,2.3,1.9,5,3.1,7.1c0.4,0.6,0.3,1.3,0.6,1.9c1,2.5,2.3,5.2,3.6,7.2v0.6c0.2,0.5,0.5,1.3,0.8,1.8c1.8,2.9,3.2,6.3,4.9,9.1v0.5
c0.2,0.5,0.6,1.2,1,1.8c1.6,2.7,3.2,5.5,4.8,8.2c0.5,0.7,1.3,2.9,1.9,3c0.3,0.7,0.5,1.4,1,1.9c0,0,0,0,0.1,0.1l0,0
c0-0.1,0-0.1,0.1-0.1h-0.1c0.2,0.8,0.8,1.4,1.2,1.9c0.5,0.7,0.7,1.5,1.2,2.3c0.8,1.2,1.9,2.4,2.6,3.6c2.1,3.5,4.4,6.9,7,10
c1.1,1.3,1.8,3.1,3.1,4.2c0.1,0,0,0,0.2,0.1c0.6-0.7,1.8-1.2,2.2-2c0.2-0.7-1.2-1.7-1.7-2.3L57.1,448.9z M15.4,199h0.1
c0,0.2,0,0.2-0.1,0.2V199z M43.9,430h0.2C44,430.2,44.1,430.1,43.9,430z M493.9,433.4c0.4-0.6,0.5-1.2,1-1.8
c0.4-0.6,1.1-1.3,1.4-1.9v-0.5c0.4-0.7,1.2-1.4,1.7-2.2c2.2-3.5,4.1-7.1,6.2-10.6c0.8-1.3,1.2-2.6,1.9-4c0.7-1.3,1.7-2.8,2.4-4.1
c2-3.8,3.6-7.8,5.5-11.5c0.5-1,0.6-1.9,1.1-2.9c1-2.3,2.2-4.8,3.2-7.2c0.2-0.5,1.2-3.2,1.1-3.4c1-1.6,1.6-4.4,2.5-6.6
c2.3-5.5,3.6-11.3,5.5-17.3c1.5-4.6,2.3-9.8,3.8-14.5c0.3-0.9,0.1-1.6,0.4-2.5c0.6-2.3,0.8-4.7,1.6-7.1c0.2-1.2,0.3-2.5,0.5-3.7
c1.1-3.9,1-8.4,1.9-12.5c0-1.2,0.2-2.4,0.2-3.6c1-3.9,0.3-8.9,1.2-13v-3.6c0.4-1.6,0.2-3.7,0.2-5.6v-2c0.4-1.6,0.4-6.1,0-7.7v-4.4
c0-2.4-0.2-6.3-0.5-7.9c-0.4-1.9,0.2-3.4-0.2-5c-0.3-1.1-0.2-2.8-0.5-3.8v-1.8c-1.1-4.4-0.7-9.4-1.9-13.6v-1.2
c-0.4-1.4-0.5-3.3-1-4.6c-0.1-0.4,0-0.9-0.1-1.2c0-0.4,0-0.3-0.2-0.4c0.3-0.8-0.2-1.9-0.4-2.6c-0.3-1.1-0.2-2.4-0.5-3.2
c-0.2-0.6-0.6-1.6-0.7-2.3c0.3-0.4-0.4-1.3-0.4-1.7c0-1-0.7-4.2-1.1-5.4c-0.6-2-0.9-4.1-1.6-6.1c-1.6-4.8-3-10-4.7-14.9
c-4.7-13.9-10-26.4-16.4-38.4c-1.5-2.8-2.7-5.8-4.6-8.3c-0.5-0.6-1.3-3.3-1.9-3.2c0-0.1,0-0.2,0.1-0.4c-0.4-0.9-1.4-1.7-1.8-2.5
c-0.2-0.4-0.2-0.8-0.5-1.2c-2.1-3.4-4.4-6.8-6.7-10c-0.3-0.3-0.4-1-0.6-1.3c-0.6-0.9-1.6-1.8-2.2-2.8c-0.3-0.6-0.5-1-0.8-1.6
c-4-5.5-7.9-10.8-12.5-15.7c-1.5-1.6-2.5-3.4-4.1-4.9l0,0h-0.2c-0.5-1.2-2-2.2-2.9-3.4c-2.3-3.1-5.3-5.7-7.9-8.5
c-1.1-1.2-2.7-2.2-3.6-3.6c-0.8,0.2-0.7,0.7-1.1,1.2l-1.1,1.1c-0.8,1.2-1.5,2.9-2.6,3.7v0.2c0.8,0.8,1.6,1.7,2.4,2.5h0.4
c0.2,0.9,1.1,1.3,1.6,1.9h0.4c0.2,1,2.3,2.5,3,3.2c1.3,1.4,2.7,3.4,4.2,4.9c1.2,1.2,2.5,2.4,3.5,3.8h0.4c0.2,1.2,3.5,4.3,4.3,5.3
c0.6,0.8,1,1.6,1.6,2.4c1.1,1.5,2.5,2.8,3.6,4.2c0.7,0.9,1.2,1.8,1.8,2.8c1.7,2.5,3.7,4.9,5.5,7.4c1.2,1.6,2.3,3.4,3.4,5
c0.4,0.5,0.8,1.7,1.2,1.8h0.1h-0.1c0.4,1.4,1.6,2.2,2.2,3.2c0.2,0.3,0,0.7,0.2,1c0.4,0.7,1.2,1.4,1.6,2.2c1.2,2.3,2.3,4.7,4,6.6
c-0.2,0.2,0.7,1.9,1,2.3c1.3,2.1,2.3,4.3,3.5,6.6c4.8,9.2,9.1,19.2,13,29.4c2.8,7.4,4.8,15.2,7.1,22.9c1,3.4,1.5,6.9,2.4,10.2
c0.2,0.7,0,1.2,0.2,1.9c1.2,4.4,1.5,9.4,2.6,13.9c0.2,1,0,1.7,0.2,2.6c0.7,2.6,0.5,5.9,1.2,8.5v1.8c0.3,1.1,0.2,2.7,0.5,3.8
c0.5,2.1,0,4.5,0.5,6.5c0,2.6,0.2,5.2,0.2,7.8v2.5c0.4,1.6,0.4,7.4,0,9c-0.3,1.5,0.2,3.5-0.1,4.9v2.5c-0.2,0.8,0.1,2.2-0.1,3.1
c-0.6,2.6-0.1,5.2-0.7,7.8v1.8c-0.5,2.2-0.5,5-1,7.2c0,0.3,0.1,0.7,0.1,0.8c-0.5,2-0.7,4.7-1.2,6.8c0,0.6,0,1.1-0.1,1.7
c-1.5,5.6-1.8,11.5-3.4,17c-1.1,4.1-2.2,8.4-3.4,12.4c-0.6,1.9-0.8,3.8-1.6,5.6c-0.6,1.5-0.8,3.1-1.4,4.7c-0.2,0.4-0.7,1.1-0.5,1.6
l0,0h-0.2c-0.2,1.7-1,3-1.6,4.4c-0.4,1-0.4,1.9-0.8,2.9c-1.1,2.6-2.3,5.4-3.4,7.9c-3,7.2-5.8,14.3-9.6,20.6c-0.2,0.3,0,0.6-0.2,1
c-0.2,0.5-0.8,1.1-1.1,1.6c-1.1,1.9-1.9,3.9-3.1,5.6c-0.5,0.7-0.5,1.6-1,2.3c-0.2,0-0.3,0.2-0.5,0.2c-0.8,1.1-1.3,2.4-1.9,3.6
c-0.5,0.9-1.4,1.9-1.9,2.8c-1.5,2.6-3.2,5-4.8,7.4c-1.1,1.7-2,3.4-3.4,4.8c-1,1.1-1.4,2.7-2.5,3.6c0.7,0.9,2.1,1.4,3,2.2
c0.7,0.6,1.3,1.5,2,1.9c0.4,0.3,0.7,0.2,1,0.6c1-0.2,1.4-2,2.3-2.6V440c0.7-0.2,1.7-2.2,2.2-2.9c1-1.4,2.1-2.9,3.1-4.3L493.9,433.4z
M529.2,353L529.2,353c-0.2,0.2-0.2,0-0.2,0H529.2z M525.4,366.4L525.4,366.4c-0.2,0.2-0.2,0-0.2,0H525.4z M81.4,105.7
c-0.5,0.5-1.5,1.2-1.6,1.8c0,0-0.2,0-0.2-0.1c-0.5,0.3-2.1,2.4-2.6,3c-2.8,3.1-5.9,6.2-8.3,9.7c-0.2,0.3-0.7,0.5-1,0.8
c-0.5,0.7-0.9,1.6-1.6,2.3c-0.6,0.7-1.4,1.3-1.9,2c-1.4,2-3,3.9-4.4,5.9c-0.7,1-1.2,2.3-2.2,3v0.5c-0.9,0.2-1.8,2.5-2.4,3.2
c-0.4,0.5-1.1,1.2-1.4,1.8v0.5c-0.1,0.2-0.2,0-0.4,0c-0.8,1.1-1.2,2.4-2,3.5c-0.4,0.5-0.9,0.9-1.2,1.4c-0.2,0.3,0,0.6-0.2,0.8
c-0.9,1.4-2.1,2.9-3,4.3c-5.6,9.5-10.8,19.3-15.4,29.6c-4,8.9-7.8,18.4-10.9,28.2c-1.1,3.5-1.8,7.2-3.1,10.6c0,0.6-0.2,1.1-0.2,1.7
c0,0.3-0.4,0.8-0.5,1.1v0.8c-1.2,3.2-1.5,6.9-2.5,10.4c-0.4,1.5-1.1,3.8-1,5.3c-0.6,1.1-1.4,6-1.3,7.3c-0.6,1.2-0.3,2.8-0.7,4.3
c-0.6,2.5-0.6,5.6-1.2,8.2c0,1.2-0.2,2.5-0.2,3.7c-0.7,3.1-0.2,7-1,10.2v2.5c-0.4,1.8,0.2,3.9-0.2,5.9c-0.4,1.9-0.1,5.4-0.1,7.9
c0,5.9,0.1,11.8,0.6,17.2c-0.2,0.4,0,2.2,0.1,2.9c0.2,2.5,0.4,5,0.6,7.4c0.3,2.7,0.5,6.2,1.2,8.8c0.3,1,0,1.8,0.2,2.8
c0.7,2.6,0.8,5.5,1.4,8c0.7,2.8,0.7,5.4,1.4,8.2c1.5,5.6,2.4,12.1,4.6,17.5v0.6c0.7,2.1,1.2,4.9,2.3,6.7c0,0-0.2,0.3-0.1,0.5
c0.4,1.1,0.7,2.4,1.2,3.6c0.9,2.4,1.7,5,2.8,7.2c0.1,0.5,0.2,1,0.4,1.6c1,2.3,1.7,4.7,2.8,7.1c2.5,5.5,4.9,11.1,7.4,16.6
c0.6,1.4,1.6,3,2.4,4.3c0,0.3,0.2,0.6,0.2,0.8h0.2c0,0.2,0,0.5,0.1,0.7c0.3,0.6,1,1.2,1.1,2H40c0,1.6,2.7,5.3,3.5,6.6
c3.4,5.6,6.4,11.3,10.3,16.6c0.7,0.9,1.1,1.8,1.7,2.8c0.4,0.6,1,1.2,1.4,1.8c0.4,0.6,0.8,1.6,1.3,1.7h0.1c0,0.1,0,0.2-0.1,0.4
c0.9,0.8,1.3,1.9,2,2.9c0.7,0.9,1.6,1.7,2.2,2.8c0.9-0.1,1.3-1,1.8-1.4c1.2-1.1,2.7-2.6,4.1-3.5c-0.2-1-1.2-1.7-1.8-2.4
c-0.3-0.3-0.3-0.8-0.6-1.2c-0.6-0.8-1.4-1.7-2-2.5c-1.7-2.5-3.6-5.4-5.4-8.2c-0.3-0.5-0.8-0.9-1.1-1.3c0-0.2-0.2-0.5-0.2-0.7
c-0.1-0.1-0.4,0-0.5-0.2c0-0.1,0-0.2,0.1-0.4c-0.3-0.5-1-1.1-1.3-1.6c0-0.1,0-0.2,0.1-0.4c-0.3-0.4-0.8-0.9-1.1-1.3
c-0.5-0.8-0.7-1.5-1.2-2.3c-0.1,0.1-0.2,0.1-0.4,0c0.2-0.3-1.2-2.5-1.4-3c-1.5-2.7-2.8-4.9-4.4-7.9c-2.3-4.2-4.8-8.5-6.7-13
c-0.7-1.6-1.5-3.2-2.2-4.7c-0.7-1.6-1.1-3.2-2-4.6c0.2-0.5-1.2-3.1-1.4-3.7c-1.5-3.8-2.7-7.8-4.3-11.5c-0.3-0.7-0.2-1.2-0.5-1.9
c-0.7-2.1-1.4-4.3-2.2-6.6c-2.2-6.2-3.4-13.1-5.4-19.7c-0.1-0.8-0.2-1.7-0.4-2.5c-0.8-2.8-1.2-6-1.9-8.9c-0.2-0.9,0-1.6-0.2-2.5
c-0.9-2.8-0.9-7.2-1.7-10.6c-0.4-4-0.8-8-1.2-12v-2.2c-0.3-1.1-0.1-2.7-0.4-3.8c0-1.9,0-3.8-0.1-5.8c-0.4-1.8,0.3-4.2-0.1-6.1
c-0.3-1-0.2-2.9,0-4c0.3-1.8-0.2-4,0.1-5.6c0-1.9,0-3.8,0.1-5.8c0.3-1.1,0.1-2.7,0.4-3.8v-1.9c0.8-3.4,0.4-7.6,1.2-10.9
c0.3-1.2,0-2.1,0.2-3.2c0.5-1.8,0.5-4,1-5.8c0.1-0.4,0-0.9,0-1.1c0.4-1.5,0.4-3.2,0.8-4.8c1.3-4.8,1.7-9.9,3.2-14.6
c0.2-0.7,0-1.1,0.2-1.8s0.6-1.9,0.8-2.6c0-0.4,0-0.8,0.1-1.2c0.7-1.8,1-3.7,1.7-5.9c1-3,1.9-6.3,3-9.1c0.4-1,0.3-1.9,0.7-2.9
c1.9-4.4,3.2-8.7,5-13.3c2.3-5.5,4.9-11.5,7.8-16.8c1.8-3.2,3-6.7,5-9.6c0.2-0.4,0.2-0.7,0.4-1.1c0.6-1.1,1.4-2.2,2-3.2
c0.4-0.6,0.5-1.3,0.8-1.9c1.4-2,2.8-4.8,4.3-7.1c1.2-1.8,2.7-3.6,3.7-5.4c0.5-0.8,1-1.4,1.4-2.2c0-0.2,0-0.4,0.1-0.6
c3.3-4.4,6.6-9,10-13.4c1.4-1.9,3-3.7,4.6-5.4c0-0.2,0-0.3,0.1-0.5l1.1-1.1v-0.4c0.6-0.7,2.1-1.5,2-2.4c0,0,0.2,0,0.2,0.1
c0.7-1.3,2.2-2.2,3-3.4c1.6-2.3,3.7-4.3,5.6-6.4c0.8-0.8,3.3-2.8,3.6-3.7c-1.6-1.9-3.2-3.8-4.8-5.8c-1.5,0.5-3.4,3.6-4.7,4.7
L81.4,105.7z M50.9,431L50.9,431c0.1,0.2,0.1,0.2,0,0.2V431z M24,352.5h0.1c0,0.2,0,0.2-0.1,0.2V352.5z M19.2,214.5
c-0.2,0-0.2,0-0.2-0.1h0.2V214.5z M38.2,166.6v-0.2h0.1C38.3,166.6,38.3,166.6,38.2,166.6z M503.1,142L503.1,142L503.1,142
L503.1,142z"/>
<path class="st0" d="M634.7,368.3V163.9h19v204.4H634.7z"/>
<path class="st0" d="M765.5,219.9c10.8,0,20.9,2.2,30.2,6.6c9.3,4.4,17.2,10.6,23.9,18.6v-21.8h19v145h-19v-21.8
c-6.6,8-14.6,14.2-23.9,18.6c-9.3,4.4-19.3,6.6-30.2,6.6c-14,0-26.7-3.5-38.2-10.4c-11.4-7-20.3-16.2-26.6-27.8
c-6.3-11.6-9.5-24.1-9.5-37.7c0-13.5,3.2-26.1,9.5-37.7s15.2-20.8,26.6-27.8C738.8,223.4,751.5,219.9,765.5,219.9z M768.3,354.6
c11.1,0,21.2-2.9,30.3-8.6s16.1-13.4,20.9-22.9v-54.3c-4.9-9.6-11.8-17.3-20.9-23.1c-9.1-5.8-19.2-8.7-30.3-8.7
c-10.6,0-20.4,2.7-29.3,8c-8.9,5.3-15.9,12.5-20.9,21.5s-7.5,18.8-7.5,29.3s2.5,20.3,7.5,29.3s11.9,16.2,20.9,21.5
C747.9,351.9,757.6,354.6,768.3,354.6z"/>
<path class="st0" d="M945,219.9c10.8,0,20.9,2.2,30.2,6.6c9.3,4.4,17.2,10.6,23.9,18.6v-81.2h19v204.4h-19v-21.8
c-6.6,8-14.6,14.2-23.9,18.6c-9.3,4.4-19.3,6.6-30.2,6.6c-14,0-26.7-3.5-38.2-10.4c-11.4-7-20.3-16.2-26.6-27.8
c-6.3-11.6-9.5-24.1-9.5-37.7c0-13.5,3.2-26.1,9.5-37.7s15.2-20.8,26.6-27.8C918.2,223.4,931,219.9,945,219.9z M947.8,354.6
c11.1,0,21.2-2.9,30.3-8.6s16.1-13.4,20.9-22.9v-54.3c-4.9-9.6-11.8-17.3-20.9-23.1c-9.1-5.8-19.2-8.7-30.3-8.7
c-10.6,0-20.4,2.7-29.3,8c-8.9,5.3-15.9,12.5-20.9,21.5s-7.5,18.8-7.5,29.3s2.5,20.3,7.5,29.3s11.9,16.2,20.9,21.5
C927.3,351.9,937.1,354.6,947.8,354.6z"/>
<path class="st0" d="M1076.1,433.2l26.7-66.2L1044,223.2h20.2l48.4,119.4l47.9-119.4h20.2l-84.4,210H1076.1z"/>
<path class="st0" d="M1324.6,367.9v-21.4c-6.6,8-14.6,14.2-23.9,18.6s-19.3,6.6-30.2,6.6c-14,0-26.7-3.5-38.2-10.4
c-11.4-7-20.3-16.2-26.6-27.8c-6.3-11.6-9.5-24.1-9.5-37.7c0-13.5,3.2-26.1,9.5-37.7s15.2-20.8,26.6-27.8s24.2-10.4,38.2-10.4
c10.8,0,20.9,2.2,30.2,6.6c9.3,4.4,17.2,10.6,23.9,18.6v-21.8h19v143.5c0,20.1-6.3,36.2-18.8,48.3c-12.5,12.1-29.6,18.2-51.4,18.2
c-13.1,0-25-2.6-35.9-7.7s-20.2-12.1-28.1-21L1222,392c14.3,16.5,31.4,24.8,51.2,24.8c15.3,0,27.7-4.4,37.1-13.3
C1319.9,394.6,1324.6,382.7,1324.6,367.9z M1273.4,354.6c11.1,0,21.2-2.9,30.3-8.6c9.1-5.7,16.1-13.4,20.9-22.9v-54.3
c-4.9-9.6-11.8-17.3-20.9-23.1s-19.2-8.7-30.3-8.7c-10.6,0-20.4,2.7-29.3,8s-15.9,12.5-20.9,21.5s-7.5,18.8-7.5,29.3
s2.5,20.3,7.5,29.3s11.9,16.2,20.9,21.5C1253,351.9,1262.8,354.6,1273.4,354.6z"/>
<path class="st0" d="M1401.8,433.2l26.7-66.2l-58.8-143.8h20.2l48.4,119.4l47.9-119.4h20.2l-84.4,210H1401.8z"/>
<path class="st0" d="M1551.4,271.3v97h-19v-145h19v26.5c5.2-8.5,11.8-15.6,19.6-21.3c7.8-5.7,16.9-8.5,27.2-8.5
c11.6,0,21.7,3.1,30.3,9.2c8.6,6.1,14.8,14.3,18.6,24.4c5.8-10.2,13.1-18.3,21.9-24.4c8.8-6.1,18.7-9.2,29.8-9.2
c15.9,0,28.8,5.3,38.8,16c10,10.6,15,24.1,15,40.3v92.1h-19v-91c0-11.4-3.5-21-10.4-28.7s-15.9-11.6-27-11.6
c-9.1,0-17.5,3.2-24.9,9.7c-7.5,6.5-13.9,14.8-19.3,25c0.1,1,0.1,2.5,0.1,4.5v92.1h-19v-91c0-11.4-3.5-21-10.4-28.7
s-15.9-11.6-27-11.6C1578.5,237,1563.8,248.4,1551.4,271.3z"/>
<path class="st1" d="M686.3,134.6H677l-5.9-16.2h-26.8l-5.9,16.2h-9.2l23.8-63h9.5L686.3,134.6z M646.9,111h21.8l-10.9-30.1
L646.9,111z"/>
<path class="st1" d="M696,134.6v-63h37.2v7.3h-28.8v20.3h26.5v7.3h-26.5v20.7H734v7.3L696,134.6L696,134.6z"/>
<path class="st1" d="M743.9,125.5l5.4-5.8c2.2,2.5,4.7,4.5,7.6,6.1c2.9,1.6,5.8,2.3,8.9,2.3c4.1,0,7.5-1,10.1-2.9s3.9-4.4,3.9-7.5
c0-3-1.2-5.5-3.5-7.5s-6.2-3.6-11.5-4.7c-6.4-1.4-11.3-3.6-14.6-6.6c-3.3-3.1-4.9-6.9-4.9-11.4c0-5,1.9-9.1,5.6-12.3
c3.7-3.2,8.5-4.7,14.4-4.7c7.9,0,14.5,2.7,19.8,8l-5,6.1c-4.7-4.4-9.5-6.6-14.4-6.6c-3.5,0-6.3,0.8-8.5,2.5s-3.3,3.8-3.3,6.5
c0,2.9,1.2,5.2,3.6,7.1s6,3.4,10.9,4.5c7,1.6,12.1,4,15.3,7.1s4.7,7.2,4.7,12.1c0,5.3-2,9.6-6,12.9c-4,3.3-9.4,5-16.1,5
c-4.4,0-8.5-0.9-12.4-2.7C749.7,131.1,746.5,128.7,743.9,125.5z"/>
<path class="st1" d="M810.8,134.6V78.9h-17.7v-7.3H837v7.3h-17.7v55.7H810.8z"/>
<path class="st1" d="M847.3,134.6v-63h8.5v27.7h30.7V71.6h8.5v63h-8.5v-28h-30.7v28H847.3z"/>
<path class="st1" d="M910,134.6v-63h37.2v7.3h-28.8v20.3H945v7.3h-26.5v20.7h29.6v7.3L910,134.6L910,134.6z"/>
<path class="st1" d="M973.8,134.6V78.9h-17.7v-7.3h43.8v7.3h-17.7v55.7H973.8z"/>
<path class="st1" d="M1011.3,71.6h8.5v63h-8.5V71.6z"/>
<path class="st1" d="M1044.4,71.6v27.7h5.4l19.3-27.7h9.7l-21.7,31.2l25.3,31.8h-9.9l-22.3-28h-5.7v28h-8.5v-63
C1036,71.6,1044.4,71.6,1044.4,71.6z"/>
<path class="st1" d="M1089.9,134.6v-63h8.5v55.7h28.2v7.3H1089.9z"/>
<path class="st1" d="M1137.3,71.6h8.5v63h-8.5V71.6z"/>
<path class="st1" d="M1161.9,134.6v-63h8.1l31.5,48.3V71.6h8.2v63h-8.1l-31.5-48.3v48.3H1161.9z"/>
<path class="st1" d="M1224.9,134.6v-63h37.2v7.3h-28.8v20.3h26.5v7.3h-26.5v20.7h29.6v7.3L1224.9,134.6L1224.9,134.6z"/>
<path class="st1" d="M1299.6,125.5l5.4-5.8c2.2,2.5,4.7,4.5,7.6,6.1s5.8,2.3,8.9,2.3c4.1,0,7.5-1,10.1-2.9s3.9-4.4,3.9-7.5
c0-3-1.2-5.5-3.5-7.5s-6.2-3.6-11.5-4.7c-6.4-1.4-11.3-3.6-14.6-6.6c-3.3-3.1-4.9-6.9-4.9-11.4c0-5,1.9-9.1,5.6-12.3
c3.7-3.2,8.5-4.7,14.4-4.7c7.9,0,14.5,2.7,19.8,8l-5,6.1c-4.7-4.4-9.5-6.6-14.4-6.6c-3.5,0-6.3,0.8-8.5,2.5s-3.3,3.8-3.3,6.5
c0,2.9,1.2,5.2,3.6,7.1s6,3.4,10.9,4.5c7,1.6,12.1,4,15.3,7.1c3.2,3.1,4.7,7.2,4.7,12.1c0,5.3-2,9.6-6,12.9c-4,3.3-9.4,5-16.1,5
c-4.4,0-8.5-0.9-12.4-2.7C1305.4,131.1,1302.1,128.7,1299.6,125.5z"/>
<path class="st1" d="M1384.8,71.6h8.5l14.6,50.6l13.7-50.6h8.9l-18.1,63h-8.5L1389,83.3l-14.8,51.3h-8.5l-18.1-63h8.9l13.7,50.6
L1384.8,71.6z"/>
<path class="st1" d="M1441.2,71.6h8.5v63h-8.5V71.6z"/>
<path class="st1" d="M1462.9,125.5l5.4-5.8c2.2,2.5,4.7,4.5,7.6,6.1s5.8,2.3,8.9,2.3c4.1,0,7.5-1,10.1-2.9s3.9-4.4,3.9-7.5
c0-3-1.2-5.5-3.5-7.5s-6.2-3.6-11.5-4.7c-6.4-1.4-11.3-3.6-14.6-6.6c-3.3-3.1-4.9-6.9-4.9-11.4c0-5,1.9-9.1,5.6-12.3
c3.7-3.2,8.5-4.7,14.4-4.7c7.9,0,14.5,2.7,19.8,8l-5,6.1c-4.7-4.4-9.5-6.6-14.4-6.6c-3.5,0-6.3,0.8-8.5,2.5s-3.3,3.8-3.3,6.5
c0,2.9,1.2,5.2,3.6,7.1s6,3.4,10.9,4.5c7,1.6,12.1,4,15.3,7.1c3.2,3.1,4.7,7.2,4.7,12.1c0,5.3-2,9.6-6,12.9c-4,3.3-9.4,5-16.1,5
c-4.4,0-8.5-0.9-12.4-2.7C1468.7,131.1,1465.5,128.7,1462.9,125.5z"/>
<path class="st1" d="M1514.9,125.5l5.4-5.8c2.2,2.5,4.7,4.5,7.6,6.1s5.8,2.3,8.9,2.3c4.1,0,7.5-1,10.1-2.9s3.9-4.4,3.9-7.5
c0-3-1.2-5.5-3.5-7.5s-6.2-3.6-11.5-4.7c-6.4-1.4-11.3-3.6-14.6-6.6c-3.3-3.1-4.9-6.9-4.9-11.4c0-5,1.9-9.1,5.6-12.3
c3.7-3.2,8.5-4.7,14.4-4.7c7.9,0,14.5,2.7,19.8,8l-5,6.1c-4.7-4.4-9.5-6.6-14.4-6.6c-3.5,0-6.3,0.8-8.5,2.5s-3.3,3.8-3.3,6.5
c0,2.9,1.2,5.2,3.6,7.1s6,3.4,10.9,4.5c7,1.6,12.1,4,15.3,7.1c3.2,3.1,4.7,7.2,4.7,12.1c0,5.3-2,9.6-6,12.9c-4,3.3-9.4,5-16.1,5
c-4.4,0-8.5-0.9-12.4-2.7C1520.8,131.1,1517.5,128.7,1514.9,125.5z"/>
<path class="st0" d="M661.7,464.7v7.1c-1.3-0.1-2.6-0.2-4.1-0.2c-3.3,0-5.6,0.8-7,2.2s-2.1,3.8-2.1,6.9v2.7h13.3v7.2h-13.3V530h-8.2
v-39.4h-8.1v-7.2h8.1v-2.8c0-5.5,1.4-9.6,4.1-12.2c2.7-2.7,6.9-4,12.6-4C658.5,464.3,660,464.5,661.7,464.7z"/>
<path class="st0" d="M691.9,489.7c-2.6,0-5,1-7.4,2.9s-4.4,4.4-6,7.6V530h-8.2v-46.6h8.2v7.7c4.3-5.8,9.2-8.7,14.5-8.7
c1.6,0,3.1,0.3,4.4,0.9l-0.7,7.3C695,490,693.4,489.7,691.9,489.7z"/>
<path class="st0" d="M724.8,482.3c6.6,0,12.1,2.4,16.4,7.1v-6h8.2V530h-8.2v-6c-4.3,4.7-9.8,7.1-16.4,7.1c-4.5,0-8.6-1.1-12.3-3.4
c-3.7-2.2-6.5-5.2-8.6-8.9c-2-3.7-3-7.7-3-12.1s1-8.4,3-12.1s4.9-6.7,8.6-8.9C716.2,483.5,720.3,482.3,724.8,482.3z M726.1,523.8
c3.3,0,6.2-0.8,8.9-2.5s4.7-3.9,6.1-6.7V499c-1.4-2.8-3.5-5.1-6.1-6.8c-2.7-1.7-5.7-2.6-8.9-2.6c-4.7,0-8.7,1.7-12,5
s-4.9,7.3-4.9,12.1s1.6,8.8,4.9,12.1S721.4,523.8,726.1,523.8z"/>
<path class="st0" d="M795.5,514.3v-30.9h8.2V530h-8.2v-7.2c-1.8,2.4-4,4.4-6.6,5.9s-5.4,2.3-8.5,2.3c-5.5,0-10-1.8-13.5-5.3
s-5.2-8.1-5.2-13.7v-28.7h8.2v28.2c0,3.5,1.1,6.4,3.4,8.7c2.3,2.3,5.2,3.4,8.7,3.4c2.7,0,5.3-0.9,7.7-2.7
C791.9,519.2,793.9,517,795.5,514.3z"/>
<path class="st0" d="M822,509.3c0.5,4.2,2.2,7.7,5.4,10.6c3.1,2.8,6.9,4.2,11.4,4.2c5.7,0,10.6-2.2,14.7-6.8l5.6,5.2
c-2.5,2.8-5.4,4.9-8.9,6.4s-7.2,2.2-11.2,2.2c-3.7,0-7.2-0.6-10.3-1.9c-3.2-1.3-5.9-3-8-5.2c-2.2-2.2-3.9-4.8-5.1-7.8
c-1.2-3-1.8-6.2-1.8-9.5s0.6-6.5,1.9-9.5s3-5.6,5.1-7.8c2.2-2.2,4.8-3.9,7.9-5.2c3.1-1.3,6.3-1.9,9.8-1.9c4.5,0,8.6,1.1,12.3,3.2
c3.6,2.1,6.5,4.9,8.5,8.5s3,7.5,3,11.7c0,1.2-0.1,2.4-0.4,3.6L822,509.3L822,509.3z M838.2,489.4c-3.8,0-7.3,1.2-10.3,3.7
s-4.9,5.6-5.7,9.4h31.1c-0.6-3.8-2.3-6.9-5.1-9.4C845.5,490.6,842.1,489.4,838.2,489.4z"/>
<path class="st0" d="M880.1,499.1V530h-8.2v-46.6h8.2v7.2c1.8-2.4,4-4.4,6.6-5.9s5.4-2.3,8.5-2.3c5.5,0,10,1.8,13.5,5.3
s5.2,8.1,5.2,13.7V530h-8.2v-28.2c0-3.5-1.1-6.4-3.4-8.7c-2.3-2.3-5.2-3.4-8.7-3.4c-2.7,0-5.2,0.9-7.6,2.7
C883.7,494.2,881.7,496.5,880.1,499.1z"/>
<path class="st0" d="M964.7,480.7v2.7h30.6V530h-8.2v-39.4h-22.4V530h-8.2v-39.4h-8.1v-7.2h8.1v-2.8c0-5.5,1.4-9.6,4.1-12.3
c2.7-2.7,7-4,12.7-4c1.9,0,3.5,0.1,4.9,0.4v7.1c-1.3-0.1-2.7-0.2-4.2-0.2c-3.3,0-5.7,0.8-7.1,2.2S964.7,477.6,964.7,480.7z
M985.8,470.4c0-1.5,0.5-2.8,1.6-3.8s2.3-1.6,3.8-1.6s2.8,0.5,3.8,1.6c1,1,1.6,2.3,1.6,3.8s-0.5,2.8-1.6,3.8c-1,1-2.3,1.6-3.8,1.6
s-2.8-0.5-3.8-1.6S985.8,471.9,985.8,470.4z"/>
<path class="st0" d="M1021.5,490.6v24.5c0,3,0.7,5.2,2.1,6.6c1.4,1.4,3.7,2.1,7,2.1c1.6,0,3-0.1,4.2-0.2v7.1
c-1.2,0.2-2.8,0.4-4.9,0.4c-5.7,0-9.9-1.3-12.6-3.9s-4-6.6-4-12v-24.6h-8.4v-7.2h8.4v-12.8l8.2-0.7v13.5h12.7v7.2L1021.5,490.6
L1021.5,490.6z"/>
<path class="st0" d="M1052,499.1V530h-8.2v-46.6h8.2v7.2c1.8-2.4,4-4.4,6.6-5.9s5.4-2.3,8.5-2.3c5.5,0,10,1.8,13.5,5.3
s5.2,8.1,5.2,13.7V530h-8.2v-28.2c0-3.5-1.1-6.4-3.4-8.7c-2.3-2.3-5.2-3.4-8.7-3.4c-2.7,0-5.2,0.9-7.6,2.7
C1055.6,494.2,1053.6,496.5,1052,499.1z"/>
<path class="st0" d="M1103.3,509.3c0.5,4.2,2.2,7.7,5.4,10.6c3.1,2.8,6.9,4.2,11.4,4.2c5.7,0,10.6-2.2,14.7-6.8l5.6,5.2
c-2.5,2.8-5.4,4.9-8.9,6.4s-7.2,2.2-11.2,2.2c-3.7,0-7.2-0.6-10.3-1.9c-3.2-1.3-5.9-3-8-5.2c-2.2-2.2-3.9-4.8-5.1-7.8
s-1.8-6.2-1.8-9.5s0.6-6.5,1.9-9.5s3-5.6,5.1-7.8c2.2-2.2,4.8-3.9,7.9-5.2c3.1-1.3,6.3-1.9,9.8-1.9c4.5,0,8.6,1.1,12.3,3.2
c3.6,2.1,6.5,4.9,8.5,8.5s3,7.5,3,11.7c0,1.2-0.1,2.4-0.4,3.6L1103.3,509.3L1103.3,509.3z M1119.6,489.4c-3.8,0-7.3,1.2-10.3,3.7
s-4.9,5.6-5.7,9.4h31.1c-0.6-3.8-2.3-6.9-5.1-9.4S1123.5,489.4,1119.6,489.4z"/>
<path class="st0" d="M1150,523.4l4.6-5.5c1.6,1.9,3.5,3.4,5.7,4.5s4.7,1.7,7.2,1.7c2.9,0,5.3-0.6,7-1.9c1.7-1.3,2.6-2.9,2.6-5
c0-2-0.8-3.6-2.5-4.9c-1.7-1.3-4.3-2.3-7.9-3c-10.1-1.9-15.2-6.6-15.2-14c0-3.7,1.5-6.8,4.4-9.2c3-2.4,6.8-3.7,11.6-3.7
c6,0,11.2,2.1,15.7,6.2l-4.4,5.6c-3.7-3.1-7.2-4.7-10.7-4.7c-2.4,0-4.4,0.6-6.1,1.7c-1.6,1.1-2.4,2.6-2.4,4.3c0,1.7,0.8,3.2,2.4,4.5
s4,2.2,7.2,2.9c5.4,1,9.4,2.8,12,5.2c2.6,2.4,3.9,5.5,3.9,9.4c0,4.1-1.5,7.4-4.6,9.9s-7.3,3.9-12.6,3.9c-3.8,0-7.2-0.7-10.3-2.1
C1154.6,527.7,1152,525.8,1150,523.4z"/>
<path class="st0" d="M1191.9,523.4l4.6-5.5c1.6,1.9,3.5,3.4,5.7,4.5s4.7,1.7,7.2,1.7c2.9,0,5.3-0.6,7-1.9c1.7-1.3,2.6-2.9,2.6-5
c0-2-0.8-3.6-2.5-4.9c-1.7-1.3-4.3-2.3-7.9-3c-10.1-1.9-15.2-6.6-15.2-14c0-3.7,1.5-6.8,4.4-9.2c3-2.4,6.8-3.7,11.6-3.7
c6,0,11.2,2.1,15.7,6.2l-4.4,5.6c-3.7-3.1-7.2-4.7-10.7-4.7c-2.4,0-4.4,0.6-6.1,1.7c-1.6,1.1-2.4,2.6-2.4,4.3c0,1.7,0.8,3.2,2.4,4.5
s4,2.2,7.2,2.9c5.4,1,9.4,2.8,12,5.2c2.6,2.4,3.9,5.5,3.9,9.4c0,4.1-1.5,7.4-4.6,9.9s-7.3,3.9-12.6,3.9c-3.8,0-7.2-0.7-10.3-2.1
C1196.5,527.7,1193.9,525.8,1191.9,523.4z"/>
<path class="st0" d="M1263.6,470.4c0-1.5,0.5-2.8,1.6-3.8s2.3-1.6,3.8-1.6s2.8,0.5,3.8,1.6s1.6,2.3,1.6,3.8s-0.5,2.8-1.6,3.8
s-2.3,1.6-3.8,1.6s-2.8-0.5-3.8-1.6S1263.6,471.9,1263.6,470.4z M1264.9,530v-46.6h8.2V530H1264.9z"/>
<path class="st0" d="M1295.5,499.1V530h-8.2v-46.6h8.2v7.2c1.8-2.4,4-4.4,6.6-5.9s5.4-2.3,8.5-2.3c5.5,0,10,1.8,13.5,5.3
s5.2,8.1,5.2,13.7V530h-8.2v-28.2c0-3.5-1.1-6.4-3.4-8.7c-2.3-2.3-5.2-3.4-8.7-3.4c-2.7,0-5.2,0.9-7.6,2.7
C1299.1,494.2,1297.1,496.5,1295.5,499.1z"/>
<path class="st0" d="M1401.1,467h8.5l14.6,50.6l13.7-50.6h8.9l-18.1,63h-8.5l-14.9-51.3l-14.8,51.3h-8.5l-18.1-63h8.9l13.7,50.6
L1401.1,467z"/>
<path class="st0" d="M1460.4,485.6c3.9-2.2,8.2-3.3,12.9-3.3c4.7,0,9,1.1,12.9,3.3c3.9,2.2,6.9,5.1,9,8.9s3.2,7.8,3.2,12.2
c0,4.4-1.1,8.5-3.2,12.2c-2.2,3.7-5.2,6.7-9,8.9c-3.9,2.2-8.2,3.3-12.9,3.3c-4.7,0-9-1.1-12.9-3.3s-6.9-5.1-9-8.9
c-2.2-3.7-3.2-7.8-3.2-12.2c0-4.4,1.1-8.5,3.2-12.2S1456.5,487.8,1460.4,485.6z M1461.3,518.8c3.3,3.3,7.3,5,12,5s8.7-1.7,12-5
s4.9-7.3,4.9-12.1s-1.6-8.8-4.9-12.1c-3.3-3.3-7.3-5-12-5s-8.7,1.7-12,5s-4.9,7.3-4.9,12.1S1458.1,515.5,1461.3,518.8z"/>
<path class="st0" d="M1516.6,499.1V530h-8.2v-65.7h8.2v26.2c1.8-2.4,4-4.4,6.6-5.9s5.4-2.3,8.5-2.3c5.5,0,10,1.8,13.5,5.3
s5.2,8.1,5.2,13.7V530h-8.2v-28.2c0-3.5-1.1-6.4-3.4-8.7c-2.3-2.3-5.2-3.4-8.7-3.4c-2.7,0-5.2,0.9-7.6,2.7
C1520.2,494.2,1518.2,496.5,1516.6,499.1z"/>
<path class="st0" d="M1563.8,530v-65.7h8.2V530H1563.8z"/>
<path class="st0" d="M1591.4,509.3c0.5,4.2,2.2,7.7,5.4,10.6c3.1,2.8,6.9,4.2,11.4,4.2c5.7,0,10.6-2.2,14.7-6.8l5.6,5.2
c-2.5,2.8-5.4,4.9-8.9,6.4s-7.2,2.2-11.2,2.2c-3.7,0-7.2-0.6-10.3-1.9c-3.2-1.3-5.9-3-8-5.2c-2.2-2.2-3.9-4.8-5.1-7.8
s-1.8-6.2-1.8-9.5s0.6-6.5,1.9-9.5s3-5.6,5.1-7.8c2.2-2.2,4.8-3.9,7.9-5.2c3.1-1.3,6.3-1.9,9.8-1.9c4.5,0,8.6,1.1,12.3,3.2
c3.6,2.1,6.5,4.9,8.5,8.5s3,7.5,3,11.7c0,1.2-0.1,2.4-0.4,3.6L1591.4,509.3L1591.4,509.3z M1607.6,489.4c-3.8,0-7.3,1.2-10.3,3.7
s-4.9,5.6-5.7,9.4h31.1c-0.6-3.8-2.3-6.9-5.1-9.4C1614.9,490.6,1611.5,489.4,1607.6,489.4z"/>
<path class="st0" d="M1649.5,499.1V530h-8.2v-46.6h8.2v7.2c1.8-2.4,4-4.4,6.6-5.9s5.4-2.3,8.5-2.3c5.5,0,10,1.8,13.5,5.3
s5.2,8.1,5.2,13.7V530h-8.2v-28.2c0-3.5-1.1-6.4-3.4-8.7c-2.3-2.3-5.2-3.4-8.7-3.4c-2.7,0-5.2,0.9-7.6,2.7
C1653.1,494.2,1651.1,496.5,1649.5,499.1z"/>
</svg>
</template>