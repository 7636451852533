<template>

  <div class="dx-card">
    <div class="grid-x" style="margin-top: 5px">
      <div class="auto cell">
      </div>
      <div class="shrink cell" style="margin-right: 5px;">
        <DxSelectBox
          :items="locationItems"
          display-expr="locationname"
          value-expr="id"
          v-model:value="locationFilter"
        />
      </div>
      <div class="shrink cell" style="margin-right: 5px; margin-top: 5px">
        <button class="cx-button medium" @click="manualCheckin"
          style="font-size: 13px; display: block; width: 100%">Manuell einchecken</button>
      </div>
    </div>

    <DxDataGrid
      :data-source="checkinDataSource"
      :remote-operations="true"
      :show-column-headers="false"
      style="height:600px;display:block;margin-top: 5px"
      ref="list"
    >
      <DxScrolling 
        mode="infinite" 
      />
      <DxPaging 
        :enabled="true"
        :page-size="20" 
      />
      <DxColumn
        alignment="left"
        cell-template="subjectTemplate"
        data-field="main"
        :allow-search="true"
        :allow-sorting="false"
        :allow-editing="false"
        :allow-filtering="true"
        :placeholder="'Suchen'"
      />

      <template #subjectTemplate="{ data: content }">
        <div>

          <div class="grid-x align-middle">

            <div class="shrink cell">

              <!-- avatar -->
              <div class="user-thumb" style="margin: 2px 10px 2px 5px;"
                v-bind:style="{ 'background-image': 'url(' + content.data.Avatar + '?v=' + timestamp + ')' }" >
              </div>
              <!-- /avatar -->

            </div><!-- /shrink cell main -->

            <div class="auto cell">

              <div class="grid-x align-middle">

                <div class="shrink cell">
                  <!-- row 1 -->
                  <div class="fixedwidth-listitem">
                    <p class="ck-overflow-ellipsis strong small">
                      <span>
                        {{ content.data.FirstName }} {{ content.data.LastName }}
                      </span>
                    </p>
                    <p class="ck-overflow-ellipsis -strong small">
                      <span>{{ epochToDateTime(content.data.Timestamp) }}</span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 2 -->
                  <div class="fixedwidth-listitem" style="width: 400px;">

                    <p class="ck-overflow-ellipsis strong small">
                      <span v-if='content.data.GrantAccess === "true"' class="success-color">
                        <i class="fa-light fa-right-to-bracket"></i> Check{{ content.data.Status }}
                      </span>
                      <span v-else class="alert-color">
                        <i class='fa-solid fa-octagon-xmark'></i> Verweigert
                      </span>
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      <span v-if='content.data.GrantAccess === "true"' class="success-color">
                        <i class="fa-light fa-circle-check"></i> Zugang gewährt
                      </span>
                      <span v-else class="alert-color">
                        <i class="fa-light fa-triangle-exclamation"></i> {{ content.data.Status }}
                      </span>
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 3 -->
                  <div class="fixedwidth-listitem" style="width: 300px">

                    <p class="ck-overflow-ellipsis small">
                      RFID: {{ content.data.Rfid }}
                    </p>

                    <p class="ck-overflow-ellipsis small">
                      Gerät: {{ content.data.DeviceName }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

                <div class="shrink cell">
                  <!-- row 4 -->
                  <div class="fixedwidth-listitem" style="width: 300px">

                    <p class="ck-overflow-ellipsis small">
                      Standort: {{ content.data.LocationName }}
                    </p>

                    <p class="ck-overflow-ellipsis small hide">
                      Id: {{ content.data.id }}
                    </p>

                  </div><!-- /fixedwidth-listitem -->
                </div><!-- /shrink cell -->

              </div><!-- /grid-x sub-->

            </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </div>
      </template>

    </DxDataGrid>

  </div><!-- dx-card checkin-list -->

</template>

<script>

let currentUser;
let locationItems = ref([]);
let customerId = 0;
let checkinDataSource = ref([]);
let locationFilter = ref(0);

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';

import { ref } from 'vue';
import CustomStore from 'devextreme/data/custom_store';
import { DxSelectBox } from 'devextreme-vue';
import DxDataGrid, { DxPaging, DxColumn, DxScrolling, } from 'devextreme-vue/data-grid';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } 
}); 

export default {
  name: 'customer-checkin',

  props: {
    customerData: Object,
  },

  components: {
    DxSelectBox,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxScrolling,
  },

  async mounted() {
    if(this.customerData.id) {
      customerId = this.customerData.id;
    }

    await (async () => {
      const response = await fetch(`${apihost}/vue/location/listobj/`);
      const result = await response.json();
      locationItems.value = result;
      locationFilter.value = result[0].id;
    })();

    checkinDataSource.value = new CustomStore({
      key: "id",
      load: (opts) => {
        let params = "?";
        [
          'take',
          'skip',
        ].forEach(i => {
            if(i in opts && isNotEmpty(opts[i])){
              params += `${i}=${JSON.stringify(opts[i])}&`;
            }
          });
        params += 'customerID=' + customerId;
        console.log(params); 

        return fetch(`${apihost}/vue/websocket/list/${params}`)
          .then(r => r.json())
          .then(o => {
            return {
              data: o.data,
              totalCount: o.totalCount,
              summary: []
            };
          })
          .catch(() => { throw 'Network Error' });
      }
    });
  },

  data() {
    return {
      apihost,
      currentUser,
      checkinDataSource,
      locationItems,
      locationFilter,
    };
  },

  methods: {
    epochToDateTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const dateString = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      const timeString = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      return `${timeString} ${dateString}`;
    },

    async manualCheckin() {
      let params = "?" +
        `FirstName=${this.customerData.FirstName}&` +
        `LastName=${this.customerData.LastName}&` +
        `Avatar=${encodeURI(this.customerData.Avatar)}&` +
        `GrantAccess=true&` +
        `Status=in&` +
        `LocationId=${locationFilter.value}&` +
        `CustomerId=${this.customerData.id}&` +
        `Rfid=${this.customerData.Rfid}`; 
      await fetch(`${apihost}/vue/websocket/getwebsocket/0/insert${params}`);
      this.$refs.list.instance.reload();
    }
  },

  unmounted() {

  },

};

</script>

<style>

</style>
