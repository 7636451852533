<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">
    <!-- subhide -->
    {{ customerData }}
    <div class="-hide" style="color: orange;">
      <p>customerData.id: {{ customerData.id }}</p>
      <p>customerData.Rfid: {{ customerData.Rfid }}</p>
      <p>customerData.Avatar: {{ customerData.Avatar }}</p>
      <p>customerData.Signature: {{ customerData.Signature }}</p>
      <p>customerData.LocationId: {{ customerData.LocationId }}</p>
      <p>customerData.LocationName: {{ customerData.LocationName }}</p>
      <p>customerData.Birthdate: {{ customerData.Birthdate }}</p>
    </div>
    <!-- /subhide -->

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>this.Avatar: {{ this.Avatar }}</p>
    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <form 
    :id="customerData.id"
    @submit="handleSubmit"
  >

  <div class="grid-x align-middle">
    <div class="shrink cell">
      <div style="padding: 16px 16px 16px 16px;">
        <p><i class="dx-icon -nav-icon tabnav-icon fa-light fa-user"></i>{{ currentUser.Translation.vueappNavMemberProfile }}</p>
      </div>
    </div><!-- shrink cell  -->
    <div class="auto cell">
      <!-- spacer -->
      &nbsp;
    </div><!-- auto cell  -->
    <div class="shrink cell">
      <div style="padding: 0 16px 0 16px;">
      <!-- SUBMIT BUTTON  -->
      <button :id="'submitform'+customerData.id" class="cx-button"><i class="fa-light fa-user"></i> {{currentUser.Translation.vueappCustomerProfileSubmit}}</button>
      <!-- /SUBMIT BUTTON  -->
      </div>
    </div><!-- shrink cell  -->
  </div><!-- grid-x align-middle  -->
  
  <DxForm 
    :id="customerData.id"
    :form-data="customerData"
    :read-only="false"
    @initialized="saveFormInstance"
    :show-colon-after-label="true"
    :show-validation-summary="true"
    validation-group="currentUserData"
  >

    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>


    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">

        <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

          <!-- cell 1 -->
          <div class="cell cell-margin-y large-3">
            <div class="dx-card no-paddings">

            <label class="ck-label hide">Anrede</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{currentUser.Translation.vueappCustomerPrefix}}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxSelectBox
              :id="'Title'+customerData.id"
              :items="genders"
              :input-attr="{ 'aria-label': 'Title' }"
              :value="customerData.Title"
          />
            
            <label class="ck-label hide">Vorname</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{currentUser.Translation.vueappCustomerFirstName}}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Firstname'+customerData.id"
              :value="customerData.FirstName"
              :input-attr="{ 'aria-label': 'Firstname' }"
            />

            <label class="ck-label hide">Nachname</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{currentUser.Translation.vueappCustomerLastName}}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Lastname'+customerData.id"
              :value="customerData.LastName"
              :input-attr="{ 'aria-label': 'Lastname' }"
            />

            <label class="ck-label hide">Strasse</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{currentUser.Translation.vueappCustomerStreet}}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Street'+customerData.id"
              :value="customerData.Street"
              :input-attr="{ 'aria-label': 'Street' }"
            />

            <label class="ck-label hide">PLZ/Ort</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                <p>{{currentUser.Translation.vueappCustomerZip}}</p>
              </div>
              <div class="auto cell">
                <p>{{ currentUser.Translation.vueappCustomerCity }}</p>
              </div>
            </div>
            <div class="grid-x align-middle">
              <div class="shrink cell" style="width: 100px; margin-right: 5px;">
                <DxTextBox
                  :id="'Zip'+customerData.id"
                  :value="customerData.Zip"
                  :input-attr="{ 'aria-label': 'Zip' }"
                />
              </div>
              <div class="auto cell">
                <DxTextBox
                  :id="'City'+customerData.id"
                  :value="customerData.City"
                  :input-attr="{ 'aria-label': 'City' }"
                />
              </div>
            </div>

            <label class="ck-label hide">Land</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{ currentUser.Translation.vueappCustomerCountry }}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxSelectBox
              :id="'Country'+customerData.id"
              :items="countries"
              :input-attr="{ 'aria-label': 'Country' }"
              :value="customerData.Country"
            />

            <div class="hide">

              <label class="ck-label hide">Sprache</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>{{ currentUser.Translation.vueappCustomerLanguage }}</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxSelectBox
                :id="'Language'+customerData.id"
                :items="languages"
                :input-attr="{ 'aria-label': 'Language' }"
                :value="customerData.Language"
              />

            </div>
          
            
            <div class="dx-card-end  hide">
              &nbsp;
            </div>

            </div><!-- dx-card-->
          </div><!-- cell 1-->

          <!-- cell 2 -->
          <div class="cell cell-margin-y large-3">
            <div class="dx-card no-paddings">

            <label class="ck-label hide">Email</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{ currentUser.Translation.vueappCustomerEmail }}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Email'+customerData.id"
              :value="customerData.Email"
              :input-attr="{ 'aria-label': 'Email' }">

              <DxValidator>
              <DxRequiredRule message="Email is required"/>
              <DxEmailRule message="Email is invalid"/>
              <DxAsyncRule
                :validation-callback="asyncValidation"
                message="Email is already registered"
              />
            </DxValidator>

          </DxTextBox>

            <label class="ck-label hide">Phone</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{ currentUser.Translation.vueappCustomerPhone }}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Phone'+customerData.id"
              :value="customerData.Phone"
              :input-attr="{ 'aria-label': 'Phone' }"
            />

            <label class="ck-label hide">Geburtsdatum</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>{{ currentUser.Translation.vueappCustomerBirthDate }}</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxDateBox
              :id="'BirthDate'+customerData.id"
              :value="customerData.Birthdate"
              :input-attr="{ 'aria-label': 'BirthDate' }"
              displayFormat="dd.MM.yyyy"  
              type="date"
            />

            <label class="ck-label hide">AHV-Nummer</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>AHV-Nummer</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Ahvnummer'+customerData.id"
              :value="customerData.Ahvnummer"
              :input-attr="{ 'aria-label': 'Ahvnummer' }"
            />

            <label class="ck-label hide">Krankenversicherung</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>Krankenversicherung</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Krankenversicherung'+customerData.id"
              :value="customerData.Krankenversicherung"
              :input-attr="{ 'aria-label': 'Krankenversicherung' }"
            />

            <label class="ck-label hide">Zusatzversicherung</label>
            <div class="dx-card-label grid-x align-middle -hide">
              <div class="auto cell">
              <p>Zusatzversicherung</p>
              </div>
              <div class="shrink cell">
                &nbsp;
              </div>
            </div>
            <DxTextBox
              :id="'Zusatzversicherung'+customerData.id"
              :value="customerData.Zusatzversicherung"
              :input-attr="{ 'aria-label': 'Zusatzversicherung' }"
            />
            
            <div class="dx-card-end  hide">
              &nbsp;
            </div>

            </div><!-- dx-card-->

          </div><!-- cell 2-->


          <!-- cell 3 -->
          <div class="cell cell-margin-y large-3">
            <div class="dx-card no-paddings">

              <label class="ck-label hide">Kundennummer</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>Kundennummer</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxTextBox
                :id="'Kundennummer'+customerData.id"
                :value="customerData.Kundennummer"
                :input-attr="{ 'aria-label': 'Kundennummer' }"
              />
              

              <label class="ck-label hide">RFID</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>RFID</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxTextBox
                :id="'Rfid'+customerData.id"
                :value="customerData.Rfid"
                :input-attr="{ 'aria-label': 'Rfid' }"
              />

              <label class="ck-label hide">Password</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>{{ currentUser.Translation.vueappCustomerPassword }}</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxTextBox
                :id="'Setpassword'+customerData.id"
                :value="customerData.Setpassword"
                :input-attr="{ 'aria-label': 'Setpassword' }"
              />
            
              <label class="ck-label hide">Standort</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                  <!-- <p>{{ currentUser.Translation.vueappCustomerRole }}</p> -->
                  <p>Standort</p> 
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxSelectBox
                :id="'Location'+customerData.id"
                :items="currentUser.Location"
                :input-attr="{ 'aria-label': 'Location' }"
                :value="customerData.LocationId"
                display-expr="locationname"
                value-expr="locationid"
              />

              <div class="hide">
                <label class="ck-label hide">Rolle</label>
                <div class="dx-card-label grid-x align-middle -hide">
                  <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappCustomerRole }}</p>
                  </div>
                  <div class="shrink cell">
                    &nbsp;
                  </div>
                </div>
                <DxSelectBox
                  :id="'Role'+customerData.id"
                  :items="roles"
                  :input-attr="{ 'aria-label': 'Role' }"
                  :value="customerData.Role"
                />
              </div>

              <label class="ck-label hide">Status</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>Status</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxSelectBox
                :id="'Status'+customerData.id"
                :items="itemstatus"
                :input-attr="{ 'aria-label': 'Status' }"
                :value="customerData.Status"
              />

              <div class="dx-card-end hide">
                &nbsp;
              </div>

            </div><!-- dx-card-->
          </div><!-- cell 3-->

          <!-- cell 4 Notes-->
          <div class="cell cell-margin-y large-3">

            <div class="dx-card no-paddings">
              
              <!-- <span style="white-space: pre;">{{ customerData.Notes }}</span>-->

              <label class="ck-label hide">Notizen</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>{{ currentUser.Translation.vueappCustomerNotes }}</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>
              <DxTextArea
                :id="'Notes'+customerData.id"
                :value="customerData.Notes"
                :input-attr="{ 'aria-label': 'Notes' }"
                height="372"
              />

            </div><!-- dx-card-->

          </div><!-- /cell 4 Notes-->

          <!-- cell 5 Avatar -->
          <div class="cell cell-margin-y large-3">

            <div class="dx-card no-paddings">
              <label class="ck-label hide">Avatar</label>
              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>Avatar</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>

              <ul :id="'tabsImage'+customerData.id" class="tabs hide" data-tabs>
                <li class="tabs-title -is-active"><a :href="'#panelImage_1'+customerData.id" aria-selected="true"><i class="tabnav-icon no-margin fa-light fa-image"></i><span class="hide">Image</span></a></li>
                <li class="tabs-title is-active"><a :href="'#panelImage_2'+customerData.id"><i class="tabnav-icon no-margin fa-light fa-cloud-arrow-up"></i><span class="hide">Upload</span></a></li>
              </ul>
              <div class="tabs-content" :data-tabs-content="'tabsImage'+customerData.id">

                <div class="tabs-panel -is-active" :id="'panelImage_1'+customerData.id">
                  <div class="padding-wrap">
                    <img :src="this.Avatar" :alt="customerData.Firstname" />
                  </div><!-- /padding-wrap -->
                </div><!-- /tabs-panel -->               

                <div class="tabs-panel is-active" :id="'panelImage_2'+customerData.id">
                  <div class="padding-wrap">

                      <!-- upload Picture -->
                      <div v-if="this.Avatar">
                        <img :src="this.Avatar" :alt="customerData.Firstname" />
                      </div>
                      <div v-else style="font-size: 12px;">
                        <span style="color: #ec5f67;">
                          <i class="fa-light fa-circle-exclamation"></i>
                          Bild jpg, png (5 MB)
                        </span>
                      </div>

                      <DxFileUploader
                        id="upfile"
                        name="upfile"
                        class="uploadbutton"
                        :upload-url="apihost+'/uploadavatar.php?id=' + customerData.id"
                        :accept="accept"
                        :multiple="false"
                        :upload-mode="uploadMode"
                        :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
                        :min-file-size="1024"
                        :max-file-size="10240*1024"
                        :on-files-uploaded="onFilesUploaded"
                        label-text=""
                        :select-button-text="currentUser.Translation.vueappCustomerAvatarButton"
                        @value-changed="e => files = e.value"
                      />

                      <!-- /upload Picture -->

                  </div><!-- /padding-wrap -->

                </div><!-- /tabs-panel -->               

              </div><!-- /tabs-content -->               

            </div><!-- dx-card-->

          </div><!-- cell 5 Avatar -->

          <!-- cell 6 Signature/Pdf -->
          <div class="cell cell-margin-y large-3"  :class="customerData.Role == 'Customer'  ? 'hide' : ''">

            <div class="dx-card no-paddings">

              <ul :id="'accordionSignature'+customerData.id" class="accordion is-label" data-accordion>
                
                <li class="accordion-item -is-active" data-accordion-item>
                  <a href="#" class="accordion-title -has-border-top">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        <span v-if="!signaturepng" class="alert-color"><i class="fa-light fa-triangle-exclamation"></i> </span>
                        <span v-if="signaturepng" class="success-color"><i class="fa-light fa-circle-check"></i> </span>
                        Unterschrift Mitarbeiter
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content -is-active" data-tab-content>


                    <div v-if="!signaturepng">
                      <div class="ck-alert" style="padding: 10px;">
                        <p><i class="fa-light fa-triangle-exclamation"></i> Unterschrift definieren</p>
                      </div>
                    </div>

                    <div v-if="signaturepng" class="content-wrap" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">

                    <!-- Unterschrift Kunde (signature.png) -->
                    <div class="signature-image">
                      <img :src="signaturepng+'?v=' + timestamp" />
                    </div><!-- /signature-image -->



                  <div class="signature-controls-save">
                    <!-- controls save -->
                    <ul class="menu horizontal">
                      <li>
                        <button type="button" class="cx-button tiny" @click.prevent="removeSavedStrokes()"><i class="fa-light fa-trash"></i> Unterschrift löschen</button>
                      </li>
                    </ul>
                  </div><!-- /signature-controls-save -->
                    

                  </div><!-- /content-wrap -->

                  </div> <!-- /accordion-content-->
                </li>

                <li class="accordion-item" data-accordion-item>
                  <a href="#" class="accordion-title -has-border-top">
                    <div class="grid-x align-middle" style="height: 100%;">
                      <div class="cell">
                        Unterschreiben Mitarbeiter
                      </div>
                    </div>
                  </a>
                  <div class="accordion-content" data-tab-content>
                    <!-- <p>Unterschreiben Kunde (signature-canvas)</p>  -->

                    <div class="content-wrap" style="padding: 5px 10px; border-bottom: 1px solid #dedede;">

                      <div class="signature-controls-edit" style="margin-bottom: 5px;">
                      <!-- controls edit -->

                        <ul class="menu horizontal">
                          <li>
                            <button type="button" @click.prevent="disabled = !disabled">
                            <span v-if="!disabled"><i class="fa-light fa-unlock"></i> Unlock</span>
                            <span v-else><i class="fa-light fa-lock"></i> Lock</span>
                            </button>
                          </li>
                          <li>
                            <button type="button" @click.prevent="$refs.VueCanvasDrawing.undo()"><i class="fa-light fa-arrow-rotate-left"></i> Undo</button>
                          </li>
                          <li>
                            <button type="button" @click.prevent="$refs.VueCanvasDrawing.redo()"><i class="fa-light fa-arrow-rotate-right"></i> Redo</button>
                          </li>
                          <li>
                            <button type="button" @click.prevent="$refs.VueCanvasDrawing.redraw()"><i class="fa-light fa-arrows-rotate"></i> Redraw</button>
                          </li>
                          <li>
                            <button type="button" @click.prevent="$refs.VueCanvasDrawing.reset()"><i class="fa-light fa-eraser"></i> Reset</button>
                          </li>
                        </ul>
                      </div><!-- /signature-controls-edit -->

                      <div class="signature-canvas">
                      
                        <vue-drawing-canvas
                          ref="VueCanvasDrawing"
                          :canvasId="'VueDrawingCanvas'+id"
                          v-model:image="image"
                          :width="300"
                          :height="200"
                          :stroke-type="strokeType"
                          :line-cap="lineCap"
                          :line-join="lineJoin"
                          :fill-shape="fillShape"
                          :eraser="eraser"
                          :lineWidth="line"
                          :color="color"
                          :background-color="backgroundColor"
                          :background-image="backgroundImage"
                          :watermark="watermark"
                          :initial-image="initialImage"
                          saveAs="png"
                          :styles="{
                          border: 'solid 1px #dedede',
                          }"
                          :lock="disabled"
                          @mousemove="getCoordinate($event)"
                          :additional-images="additionalImages"
                        />

                      </div><!-- /signature-canvas -->

                      <div class="signature-image hide">
                        <img :src="image" />
                      </div><!-- /signature-image -->

                      <div class="signature-controls-save">
                        <!-- controls save -->
                        <ul class="menu horizontal">
                          <li>
                            <button type="button" class="cx-button tiny" @click.prevent="getStrokes()"><i class="fa-light fa-floppy-disk"></i> Unterschrift sichern</button>
                          </li>
                        </ul>
                      </div><!-- /signature-controls-save -->

                    </div><!-- /content-wrap -->

                  </div> <!-- /accordion-content-->
                </li>

              </ul>

            </div><!-- dx-card-->

          </div><!-- /cell 6 Signature/Pdf-->

          <!-- cell 7 RFID QR-Code-->
            <div class="cell cell-margin-y large-3 hide"  :class="customerData.Rfid == ''  ? 'hide' : ''">

            <div class="dx-card no-paddings">

              <div class="dx-card-label grid-x align-middle -hide">
                <div class="auto cell">
                <p>RFID QR-Code</p>
                </div>
                <div class="shrink cell">
                  &nbsp;
                </div>
              </div>

              <div style="padding: 10px;">

              <QRCodeVue3
                :width="200"
                :height="200"
                :value="customerData.Rfid"
                :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
                :dotsOptions="{
                  type: 'square',
                  color: '#000000',
                  gradient: {
                    type: 'linear',
                    rotation: 0,
                    colorStops: [
                      { offset: 0, color: '#000000' },
                      { offset: 1, color: '#000000' },
                    ],
                  },
                }"
                :backgroundOptions="{ color: '#ffffff' }"
                :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                :cornersDotOptions="{ type: undefined, color: '#000000' }"
                fileExt="png"
                :download="true"
                myclass="my-qur"
                imgclass="img-qr"
                downloadButton="qr-download-button cx-button tiny"
                :downloadOptions="{ name: 'vqr', extension: 'png' }"
              />

            </div>

            </div>

          </div><!-- cell 7 -->


        </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid-->

          
        </div><!-- master-content -->

      </template>

    </DxForm>
  
  </form>

</template>

<script>

import { DxFileUploader } from 'devextreme-vue/file-uploader';

import Validator from 'devextreme/ui/validator';
import DxForm, {
  //DxGroupItem,
  DxSimpleItem,
  //DxButtonItem,
  DxLabel,
  DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  DxEmailRule,
  DxAsyncRule,
} from 'devextreme-vue/form';

import {
  DxValidator,

} from 'devextreme-vue/validator';

import notify from 'devextreme/ui/notify';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';

//import { ref } from 'vue';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import VueDrawingCanvas from "vue-drawing-canvas";

import QRCodeVue3 from "qrcode-vue3";

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

import { 
  genders,
  roles,
  itemstatus,
  countries,
  languages,
  abotypes,
  statuses, 
  priorities, 
} from "../constant";

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let id;
let signaturepng;
let timestamp;

let Avatar;

//let invalidEmail = 'test@dx-email.com';
let invalidEmail = '';


const sendRequest = function(value, id) {
  //console.log(value);
  //console.log(id);

  setTimeout(() => {
    fetch(apihost+'/de/vue/member/membercheckmail/' + id + '/' + value)
    .then(response => response.text())
    .then(result => {
    const data = JSON.parse(result);
    //console.log(data);
    invalidEmail = data;
    //this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
  }, 0);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value !== invalidEmail);
    }, 1000);
  });
  
};

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth


export default {
  name: 'customer-profile',

  props: {
    customerData: Object,
  },

  mounted() {

    this.Avatar = this.customerData.Avatar;
    this.signaturepng = this.customerData.Signature;

    this.tabsImage = new Foundation.Tabs($('#tabsImage'+this.customerData.id), {
    matchHeight: false
  });

  this.accordionSignature = new Foundation.Accordion($('#accordionSignature'+this.customerData.id), {
    // These options can be declarative using the data attributes
    slideSpeed: 500,
    multiExpand: false,
    allowAllClosed: true,
  });

  this.timestamp = this.printTimestamp();

  },

  components: {
    DxFileUploader,

    DxForm,
    //DxGroupItem,
    DxSimpleItem,
    //DxButtonItem,
    DxLabel,
    DxRequiredRule,
    //DxCompareRule,
    //DxPatternRule,
    //DxRangeRule,
    DxEmailRule,
    //DxStringLengthRule,
    //DxAutocomplete,
    DxAsyncRule,
    //notify,
    //DxDateBox,
    //DxTabPanel,
    //DxItem,
    //DxSelection,
    DxDateBox,
    DxTextBox,
    DxTextArea,
    DxSelectBox,

    DxValidator,

    VueDrawingCanvas,
    QRCodeVue3,
  },

  data() {
    return {
      apihost,

      msg: 'Profile (part)',
      formInstance: null,
      currentUser,

      colCountByScreen,

      signaturepng,
      id,
      timestamp,

      Avatar,

      //MENUES
      genders,
      roles,
      itemstatus,
      countries,
      languages,
      abotypes,
      statuses,
      priorities,

      //uploader
      formRefName: 'tree-view',
      multiple: false,
      accept: '*',
      uploadMode: 'instantly',
      fileTypesSource: [
        { name: 'All types', value: '*' },
        { name: 'Images', value: 'image/*' },
        { name: 'Images', value: 'image/*' },
      ],
      files: [],
      //end uploader    

      calendarOptions: { 
        displayFormat: "dd.MM.yyyy"
      },
      buttonOptions: {
        text: currentUser.Translation.vueappCustomerProfileSubmit,
        type: 'success',
        useSubmitBehavior: true,
      },
      passwordOptions: {
          mode: 'password',
          onValueChanged: () => {
            const editor = this.formInstance.getEditor('ConfirmPassword');
            if (editor.option('value')) {
              const instance = Validator.getInstance(editor.element());
              instance.validate();
            }
          },
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('Password'),
              },
            },
          ],
        },
        confirmPasswordOptions: {
          mode: 'password',
          buttons: [
            {
              name: 'password',
              location: 'after',
              options: {
                icon: apihost+'/images/icons/eye.png',
                type: 'default',
                onClick: () => this.changePasswordMode('ConfirmPassword'),
              },
            },
          ],
        },
        dateBoxOptions: {
          invalidDateMessage:
            'The date must have the following format: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidMessage: 'The phone must have a correct USA phone format',
        },
        titlePattern: /^[^0-9]+$/,
        firstnamePattern: /^[^0-9]+$/,
        lastnamePattern: /^[^0-9]+$/,
        namePattern: /^[^0-9]+$/,
        cityPattern: /^[^0-9]+$/,
        //phonePattern: /^[02-9]\d{9}$/,
        //maxDate: new Date().setFullYear(new Date().getFullYear() - 21),

        validationRules: {
          position: [
            { type: 'required', message: currentUser.Translation.vueappCustomerPrefixMessage },
          ],
          firstname: [
            { type: 'required', message: currentUser.Translation.vueappCustomerFirstNameMessage },
          ],
          lastname: [
            { type: 'required', message: currentUser.Translation.vueappCustomerLastNameMessage },
          ],
          email: [
            { type: 'required', message: currentUser.Translation.vueappCustomerEmailMessage },
          ],
          role: [
            { type: 'required', message: currentUser.Translation.vueappCustomerRoleMessage },
          ],
          country: [
            { type: 'required', message: currentUser.Translation.vueappCustomerCountryMessage },
          ],
          language: [
            { type: 'required', message: currentUser.Translation.vueappCustomerLanguageMessage },
          ],
        },

        genderEditorOptions: { items: currentUser.Constant.genders, searchEnabled: true },
        roleEditorOptions: { items: currentUser.Constant.roles, searchEnabled: true },
        statusEditorOptions: { items: currentUser.Constant.itemstatus, searchEnabled: true },
        countryEditorOptions: { items: currentUser.Constant.countries, searchEnabled: true },
        languageEditorOptions: { items: currentUser.Constant.languages, searchEnabled: true },


        //CANVAS
        initialImage: [
          {
            type: "dash",
            from: {
              x: 262,
              y: 154,
            },
            coordinates: [],
            color: "#000000",
            width: 5,
            fill: false,
          },
        ],

        x: 0,
        y: 0,
        image: "",
        eraser: false,
        disabled: false,
        fillShape: false,
        line: 3,
        color: "#000000",
        strokeType: "dash",
        lineCap: "round",
        lineJoin: "round",
        backgroundColor: "#FFFFFF",
        backgroundImage: null,
        watermark: null,
        additionalImages: [],

        //END CANVAS

      }; //return

    },
    methods: {

    printTimestamp: function () {
      return Date.now();
    },

    onFilesUploaded() {

      const id = this.formInstance.getEditor('id').option('value'); 

      let objString = '?id=' + id;
      //console.log(objString);

      fetch(apihost+'/de/vue/member/getmember/'+id+'/view'+objString)
        .then(handleErrors)
        .then(response => response.text())
        .then(result => {
        const data = JSON.parse(result);
        //console.log(data);

        //update Frontend
        this.Avatar = data.Avatar;

      })
      .catch(() => { throw 'Network error' });

      notify({
        message: currentUser.Translation.vueappCustomerProfileSubmitSuccess,
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);


      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);

    },

    async setImage(event) {
    //console.log(event);
      let URL = window.URL;
      this.backgroundImage = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawing.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawing.redraw();
    },
    getCoordinate(event) {
      //console.log(event);
      let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
      this.x = coordinates.x;
      this.y = coordinates.y;
    },

    getStrokes() {

      window.localStorage.setItem(
        "vue-drawing-canvas",
        JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      );
      //console.log("Strokes saved, reload your browser to see the canvas with previously saved image")
      
      const action = 'employeesave';
      const baseUrl  = apihost+'/de/vue/signature/'+this.customerData.id+'/'+'0'+'/'+action;
      const dataURL = this.image;

      console.log(baseUrl);

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: dataURL
      }
      }).done(function() {
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      this.timestamp = Date.now();

      setTimeout(() => {
        this.signaturepng = apihost+'/employeesignature/'+this.customerData.id+'.png?v='+Date.now();
      }, "3000");

      notify({
          message: "Unterschrift wurde gesichert",
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);

    },
    
    removeSavedStrokes() {

      window.localStorage.removeItem("vue-drawing-canvas");
      //console.log("Strokes cleared from local storage"); 

      const action = 'employeedelete';

      const baseUrl  = apihost+'/de/vue/signature/'+this.customerData.id+'/'+'0'+'/'+action;
      console.log(baseUrl);

      $.ajax({
      type: "POST",
      url: baseUrl,
      data: { 
         imgBase64: ''
      }
      }).done(function() {
        //console.log("Strokes saved, reload your browser to see the canvas with previously saved image");
        // If you want the file to be visible in the browser 
        // - please modify the callback in javascript. All you
        // need is to return the url to the file, you just saved 
        // and than put the image in your browser.
      });      

      notify({
        message: "Unterschrift wurde gelöscht",
        position: {
          my: 'center top',
          at: 'center top',
        },
      }, 'success', 3000);
      
      this.signaturepng = '';

    },

    saveFormInstance(e) {
      this.formInstance = e.component;
    },
    changePasswordMode(name) {
      const editor = this.formInstance.getEditor(name);
      editor.option(
        'mode',
        editor.option('mode') === 'text' ? 'password' : 'text',
      );
    },
    passwordComparison() {
      return this.fetchUser.Password;
    },
    checkComparison() {
      return true;
    },
    asyncValidation(params) {
      const id = this.formInstance.getEditor('id').option('value');  
      return sendRequest(params.value, id);
    },
    handleSubmit(e) {
      e.preventDefault();

      setTimeout(() => {

      if(invalidEmail){
        
        notify({
          message: currentUser.Translation.vueappCustomerEmailPattern,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'error', 3000);


      } else {

        const id = this.formInstance.getEditor('id').option('value');  

        const Title = document.getElementById("Title"+id).getElementsByTagName("input")[0].value;
        const FirstName = document.getElementById("Firstname"+id).getElementsByTagName("input")[0].value;
        const LastName = document.getElementById("Lastname"+id).getElementsByTagName("input")[0].value;
        const Street = document.getElementById("Street"+id).getElementsByTagName("input")[0].value;
        const Zip = document.getElementById("Zip"+id).getElementsByTagName("input")[0].value;
        const City = document.getElementById("City"+id).getElementsByTagName("input")[0].value;
        const Phone = document.getElementById("Phone"+id).getElementsByTagName("input")[0].value;
        const Email = document.getElementById("Email"+id).getElementsByTagName("input")[0].value;
        const BirthDate = document.getElementById("BirthDate"+id).getElementsByTagName("input")[0].value;
        const Country = document.getElementById("Country"+id).getElementsByTagName("input")[0].value;
        const Language = document.getElementById("Language"+id).getElementsByTagName("input")[0].value;
        const Role = document.getElementById("Role"+id).getElementsByTagName("input")[0].value;
        const Setpassword = document.getElementById("Setpassword"+id).getElementsByTagName("input")[0].value;
        const Rfid = document.getElementById("Rfid"+id).getElementsByTagName("input")[0].value;
        const Status = document.getElementById("Status"+id).getElementsByTagName("input")[0].value;
        const Kundennummer = document.getElementById("Kundennummer"+id).getElementsByTagName("input")[0].value;
        const LocationId = document.getElementById("Location"+id).getElementsByTagName("input")[0].value;
        const Ahvnummer = document.getElementById("Ahvnummer"+id).getElementsByTagName("input")[0].value;
        const Krankenversicherung = document.getElementById("Krankenversicherung"+id).getElementsByTagName("input")[0].value;
        const Zusatzversicherung = document.getElementById("Zusatzversicherung"+id).getElementsByTagName("input")[0].value;

        //Notes as input
        //const Notes = document.getElementById("Notes"+id).getElementsByTagName("input")[0].value;

        //Notes as textarea
        //https://stackoverflow.com/questions/6480655/line-breaks-in-a-textarea
        let Notes = document.getElementById("Notes"+id).getElementsByTagName("textarea")[0].value;
        Notes = Notes.replace(/\r?\n/g, '%0A');

        //BirthDate 
        //https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
        let getBirthDate;
        let isoDateBirthDate;

        if(BirthDate){
          getBirthDate = new Date(BirthDate); // Or the date you'd like converted.
          isoDateBirthDate = new Date(getBirthDate.getTime() - (getBirthDate.getTimezoneOffset() * 60000)).toISOString();
          //console.log(isoDateBirthDate)  // => '2015-01-26T06:40:36.181'
        }

        let objString = '?id=' + id;

        if(Title){
          objString = objString + '&Title=' + Title;
        } else {
          objString = objString + '&Title=';
        }
        if(FirstName){
          objString = objString + '&FirstName=' + FirstName;
        } else {
          objString = objString + '&FirstName=';
        }
        if(LastName){
          objString = objString + '&LastName=' + LastName;
        } else {
          objString = objString + '&LastName=';
        }
        if(Street){
          objString = objString + '&Street=' + Street;
        } else {
          objString = objString + '&Street=';
        }
        if(Zip){
          objString = objString + '&Zip=' + Zip;
        } else {
          objString = objString + '&Zip=';
        }
        if(City){
          objString = objString + '&City=' + City;
        } else {
          objString = objString + '&City=';
        }
        if(Phone){
          objString = objString + '&Phone=' + Phone;
        } else {
          objString = objString + '&Phone=';
        }
        if(Email){
          objString = objString + '&Email=' + Email;
        } else {
          objString = objString + '&Email=';
        }
        if(BirthDate){
          objString = objString + '&BirthDate=' + isoDateBirthDate;
        } else {
          objString = objString + '&BirthDate=';
        }
        if(Country){
          objString = objString + '&Country=' + Country;
        } else {
          objString = objString + '&Country=';
        }
        if(Language){
          objString = objString + '&Language=' + Language;
        } else {
          objString = objString + '&Language=';
        }
        if(Role){
          objString = objString + '&Role=' + Role;
        } else {
          objString = objString + '&Role=';
        }
        if(Notes){
          objString = objString + '&Notes=' + Notes;
        } else {
          objString = objString + '&Notes=';
        }
        if(Setpassword){
          objString = objString + '&Setpassword=' + Setpassword;
        } else {
          objString = objString + '&Setpassword=';
        }
        if(Rfid){
          objString = objString + '&Rfid=' + Rfid;
        } else {
          objString = objString + '&Rfid=';
        }
        if(Status){
          objString = objString + '&Status=' + Status;
        } else {
          objString = objString + '&Status=';
        }
        if(Kundennummer){
          objString = objString + '&Kundennummer=' + Kundennummer;
        } else {
          objString = objString + '&Kundennummer=';
        }
        if(LocationId){
          objString = objString + '&LocationId=' + LocationId;
        } else {
          //objString = objString + '&LocationId=';
        }
        if(Ahvnummer){
          objString = objString + '&Ahvnummer=' + Ahvnummer;
        } else {
          objString = objString + '&Ahvnummer=';
        }
        if(Krankenversicherung){
          objString = objString + '&Krankenversicherung=' + Krankenversicherung;
        } else {
          objString = objString + '&Krankenversicherung=';
        }
        if(Zusatzversicherung){
          objString = objString + '&Zusatzversicherung=' + Zusatzversicherung;
        } else {
          objString = objString + '&Zusatzversicherung=';
        }

        //console.log(objString);

        fetch(apihost+'/de/vue/member/getmember/'+id+'/save'+objString)
          .then(handleErrors)
          .then(response => response.text())
          .then(result => {
          const data = JSON.parse(result);
          //console.log(data);
          //this.fetchUser = data;
          this.customerRfid = data.Rfid;
        })
        .catch(() => { throw 'Network error' });
      
        notify({
          message: currentUser.Translation.vueappCustomerProfileSubmitSuccess,
          position: {
            my: 'center top',
            at: 'center top',
          },
        }, 'success', 3000);

      } //if invalidEmail

      }, 1000); //timeout

    },

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
.qr-download-button {
  margin-top: 15px;
}
</style>
