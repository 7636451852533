<template>
<svg version="1.1" id="logo-ladyfitness" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 271 93" style="enable-background:new 0 0 271 93;" xml:space="preserve">
<path class="st0" d="M87.1,50c6.6-4.7,11.8-12.2,16.4-19c1.3-1.4,1.9-5.4,4.4-3.8c0.7,0.5,2.5,0.9,1.8,2.1
C104.1,42.5,99,56.1,93.3,69.2c0.7,2,9.4-1.5,11.8-1.2c14.7-2.5,29.4-4.7,44.2-6.4c38.7-4.3,77.9-6.2,116.8-5.7
c0.2,0.9,0.1,1.9,0,2.8c-44.6,0.8-89.4,1.9-133.6,8.4c-13,1.8-25.9,4.1-38.8,6.3c-3.2,0.3-3.2,4.2-4.6,6.4
c-0.8,1.2-2.4-0.2-3.4-0.5c-2.3-0.8,2.1-4-0.5-4.2c-22.5,4.2-45,8.9-67.5,13.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.1-0.8,0.1-1.1,0
c-1.3-1.8-1.3-4,1.4-3.9c5.2-0.9,10.4-2.2,15.6-3.1c14.7-3.1,29.4-6,44.2-8.9c6.2-1.7,10.8,0.4,12.5-7.2c2.3-6.5,6-12.9,7.6-19.4
C69.4,75.7,83.7,47.2,89,29.4c0.2-2.7,2.2-2.1,4-1.3c1.9,0.6-0.9,4.3-0.9,5.8C90.7,39.2,87.8,44.6,87.1,50z"/>
<path class="st0" d="M80.6,28.4c2.1-4.3,3.2-9.2,5.1-13.6c0.7-1.6,4.2,0,4.2,1.3c-2.4,6.4-4.1,13.1-6.3,19.7
C81,43.5,78.9,51.5,76,59.2c-0.8,0.8-2.2-0.4-3.2-0.5c-1.4-0.6-0.1-2-0.5-3c-13.2,11-17.5,0.7-10.5-11.4C65.3,37,71.7,31.8,78,27
C79.2,26.5,79.4,28.5,80.6,28.4z M79,32.5c-8.2,4-14.9,13.7-16.7,22.8c1.4,2.3,4.5-1.8,6-2.7C74.1,47.4,77.1,39.8,79,32.5z"/>
<path class="st0" d="M50.1,56.7c-2.8,1.3-5.1,4.4-8.3,5.4c-4.7,2.9-9.5-1.8-8.1-6.8c1.8-8.3,8.7-14.4,15.2-19.5
c3.1-1.4,9.1-10,11.2-4.7c2.2,3.6,3.3,1.5,1.1,6.3C57.9,45,55.7,53,54.1,61c-0.5,1.6-2.5,0.2-3.6,0.1C48.6,60.4,51,58,50.1,56.7z
M56.6,35.7c-6.3,3.8-12,9.1-16.2,15.2c-0.9,1.4-5.2,8.7-1,7.6C48.2,54,53.9,44.8,56.6,35.7z"/>
<path class="st0" d="M175.6,28c20-20.6,19.1-14,10.5,8.3c-1.2,3.5-2.4,6.9-3.6,10.3c-0.7,3-1.6,4.5-4.7,2.7c-1.5-1.1,1.2-4.4,1.3-6
c1.9-6.1,4.6-12.1,6.2-18.3c-6.2,3.6-10.7,11.5-14.5,17.8c-1.4,1.5-2.1,7.3-4.4,6.7c-2.8-0.8-3-1.7-1.8-4.3c1.1-2.9,2-5.9,3.1-8.9
c1.6-4.8,3.3-9.5,5-14.3c0.8-1.3,0.6-4.3,2.5-4.2c3.5,0.8,3.2,1.5,2,4.4C177,23.8,174.6,27.1,175.6,28z"/>
<path class="st0" d="M118.4,28.9c-1.8-0.9-5.9,2.1-6.2-1.2c0-0.9-0.8-2.4,0.5-2.7c2.2-0.8,7.7-0.1,8.5-2.3c1.7-3.6,2.4-7.7,5-10.9
c3.4-5.1,10.7-4.9,11.9,1.8c0.2,2.9,0.6,7.8-3.5,4.4c-1.3-0.7-0.6-2.5-1-3.7c-0.5-3.5-3.7-0.6-4.5,1.2c-6.8,13.5,0.2,3.8,3.3,7.4
c2.9,6.9-8.2,1.7-9.1,6.9c-3,7.7-6,15.3-9,22.9c-1,2.6-2.1,5.2-3.1,7.8c-0.3,2.4-2.8,1.6-4.2,0.6c-1.1-0.6,0.3-1.8,0.3-2.6
c1.3-2.8,2.2-5.7,3.4-8.6C113,42.8,116.7,36,118.4,28.9z"/>
<path class="st0" d="M242.9,44.8c-0.9-0.1-2.2,0.4-2.7-0.6c-0.4-1.4-0.9-3.9,1.2-4c6.1,0.1,12.7-1.4,17.4-5.4
c5.2-8.7-17.6-3.9-18.2-11.6c-3.8-7.5,32.1-19.1,22.2-8.4c-5.1,0.5-10.2,2.2-14.6,4.8c-1,0.5-4.7,2.6-2.1,3.2
c5.3,1.3,11.2,1.6,15.8,4.6C270.5,38.3,251.1,44.8,242.9,44.8z"/>
<path class="st0" d="M215.7,47.7c-0.8-0.1-2.4,0.5-2.7-0.6c-0.3-1.2-0.8-2.6,0.3-3.5c6.1-0.6,13-1.2,18-5.3
c6.2-9-17.1-4.2-17.9-11.8c-0.7-8.5,15.9-12.1,22.4-13c2-0.6,1.5,3,0.8,3.9c-5.1,1.8-11,2.2-15.7,5.6c-10.3,4.9,12,3.5,14.7,8.7
C241.6,42.5,223.9,47.5,215.7,47.7z"/>
<path class="st0" d="M194.5,37.6c-0.8,3.9-1.6,11,4.1,5.6c2.2-1.2,2.8-4.1,4.7-5.2c3.8-2,3.3,0.4,2,3.1C203.2,46,194.9,53,190.5,47
c-3-6.6,0.6-14.8,4-20.8c2.7-4.1,9.5-13.6,14.2-7.1c1.7,3.2,1.1,6.7-0.9,9.5C205.2,32.3,199,38.3,194.5,37.6z M196.4,32.5
c3.1-0.9,12.6-8.3,8.8-11.2C201.6,23.9,197.4,28.1,196.4,32.5z"/>
<path class="st0" d="M29.3,60.1c0,0.9,0.1,2.4-1.3,2.2c-5.1-0.4-10.2,0.3-15.3,0.8c-1.8-0.1-3,1.4-4.5,1.8c-1.3-0.9-3.7,1.2-4-1.2
c-0.4-1.4-1-2.7,0.9-3.2c2.7-2.6,2.8-7.3,4.6-10.7c2.6-7.4,5.3-14.9,7.9-22.3c1.1-1.6,2.2-9.9,4.4-9c0.8,0.6,3,0.4,2.6,1.9
c-2.2,6.7-4.7,13.4-7,20c-2.1,5.9-4,11.8-6.3,17.7c0.4,1.9,5.6-0.7,7.4-0.1C21.4,58.6,30,56,29.3,60.1L29.3,60.1z"/>
<path class="st0" d="M165.1,5.1c0.3,0,0.6,0,0.8,0c1.5,2.1,4.1,1.4,1.6,4.6c-0.3,1.4-5.1,7.1-2.7,7.4c1.9,0.2,5.7-2.6,6.5-0.1
c1.1,2.9,0.4,3.3-2.5,3.8c-2.4,0.8-6.1,0.3-7.7,2.2c-3.3,7-4.9,14.6-6.9,22c-1,2.8-0.4,10.2-5,6.1c-0.9-5.4,2.2-11.5,3.4-16.9
c1-3.4,2.2-6.7,3.4-10.1c-0.3-2.2-4.5,1.6-4.8-1.2c-1.6-4,3-3.7,5.7-4.3c2.4-0.5,2.5-3.5,3.8-5.2C162,10.6,163.6,7.8,165.1,5.1z"/>
<path class="st0" d="M140.6,22.8c6,0.2,2.5,4.3,1.6,7.7c-2.3,6.6-4.7,13.1-7,19.6c-0.6,1.2-0.6,3.6-2,3.9c-1.1-0.1-3.8-0.6-3.3-2.2
C133.5,42.4,136.2,32.1,140.6,22.8z"/>
<path class="st0" d="M143.1,15.8c0.2-1.4,1-2.8,1.5-4.2c0.7-1,0.5-3.3,2.4-2.8c1,0.3,3.3,0.8,2.8,2.2c-1.1,2-1.6,4.2-2.7,6.2
c-0.7,1.2-1.8-0.3-2.8-0.2C143.6,16.9,143,16.6,143.1,15.8z"/>
</svg>
</template>