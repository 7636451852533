<template>
  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

    <div class="grid-x grid-margin-x">
      <div class="large-auto cell">
        <h2><i class="dx-icon nav-icon fa-light fa-right-to-bracket"></i> {{ title }}</h2>
      </div>
    </div><!-- grid-x -->

    <div class="grid-x grid-margin-x">
      <div class="cell large-3" v-for="device in devices" :key="device.id">
        <div class="dx-card" style="text-align:center;padding:10px">
          <p>
            <span style="font-weight:bold;">Gerät: </span><span>{{ device.DeviceName }}</span>
          </p>
          <p>
            <span style="font-weight:bold;">Ort: </span><span>{{ device.LocationName }}</span>
          </p>
          <p>
            <span style="font-weight:bold;">Seriennummer: </span><span>{{ device.SerialNumber }}</span>
          </p>
          <p>
            <span v-if="SerialConnected.get(device.SerialNumber)" style="font-weight:bold; color:green">Online</span>
            <span v-else style="font-weight:bold; color:red">Offline</span>
          </p>
          <button class="cx-button medium" @click="() => { sendOpenCommand(device.SerialNumber); }">Öffnen</button>
        </div>
      </div>
    </div>

    <!-- accordion for entry rules -->
    <div>
      <ul :id="'accordionEntrySettings'" class="accordion" data-accordion>
        <li class="accordion-item" data-accordion-item>
          <a href="#" class="accordion-title">Zutrittsregeln</a>
          
          <div class="accordion-content" data-tab-content>
            <div class="grid-x">
              <div class="shrink cell" style="margin-right: 8px">
                <p>RFID prüfen</p>
                <p>Vertrag prüfen</p>
                <p>Rechnung prüfen</p>
                <p>Unterschrift prüfen</p>
                <button class="cx-button medium" @click="submitEntrySettings"
                  style="font-size: 13px; display: block; width: 90%; margin-top: 10px">Speichern</button>
              </div>
              <div class="auto cell">
                <div>
                  <DxCheckBox
                    v-model:value="rfidcheck"
                    @option-changed="onEntrySettingsChange"
                  />
                </div>
                <div>
                  <DxCheckBox
                    v-model:value="contractcheck"
                    @option-changed="onEntrySettingsChange"
                    :disabled="!rfidcheck"
                  />
                </div>
                <div>
                  <DxCheckBox
                    v-model:value="invoicecheck"
                    @option-changed="onEntrySettingsChange"
                    :disabled="!rfidcheck || !contractcheck"
                  />
                </div>
                <div>
                  <DxCheckBox
                    v-model:value="signaturecheck"
                    @option-changed="onEntrySettingsChange"
                    :disabled="!rfidcheck || !contractcheck"
                  />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="dx-card checkin-list">
      <div class="grid-x">
        <div class="large-9 cell" style="margin-right: 10px">
          <DxTextBox
            placeholder="Suchen"
            v-model:value="filterSearch"
            value-change-event="keyup"
            @value-changed="onSearchChanged"
          />
        </div>
        <div class="auto cell">
          <DxSelectBox
            v-model:value="filterLocation"
            v-model:items="locations"
            value-expr="id"
            display-expr="locationname"
            @value-changed="onFilterChange()"
          />
        </div>
      </div>
      <DxDataGrid
        :data-source="checkinDataSource"
        :remote-operations="true"
        :show-column-headers="false"
        style="height:600px;display:block"
        ref="list"
      >
        <DxScrolling 
          mode="infinite" 
        />
        <DxPaging 
          :enabled="true"
          :page-size="20" 
        />
        <DxColumn
          alignment="left"
          cell-template="subjectTemplate"
          data-field="main"
          :allow-search="true"
          :allow-sorting="false"
          :allow-editing="false"
          :allow-filtering="true"
          :placeholder="'Suchen'"
        />

        <template #subjectTemplate="{ data: content }">
          <div>

          <div class="grid-x align-middle">

          <div class="shrink cell">

            <!-- avatar -->
            <div class="user-thumb" style="margin: 2px 10px 2px 5px;"
              v-bind:style="{ 'background-image': 'url(' + content.data.Avatar + '?v=' + timestamp + ')' }" >
            </div>
            <!-- /avatar -->

          </div><!-- /shrink cell main -->

          <div class="auto cell">

            <div class="grid-x align-middle">

              <div class="shrink cell">
                <!-- row 1 -->
                <div class="fixedwidth-listitem">
                  <p class="ck-overflow-ellipsis strong small">
                    <span>
                      {{ content.data.FirstName }} {{ content.data.LastName }}
                    </span>
                  </p>
                  <p class="ck-overflow-ellipsis -strong small">
                    <span>{{ epochToDateTime(content.data.Timestamp) }}</span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 2 -->
                <div class="fixedwidth-listitem" style="width: 400px;">

                  <p class="ck-overflow-ellipsis strong small">
                    <span v-if='content.data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-right-to-bracket"></i> Check{{ content.data.Status }}
                    </span>
                    <span v-else class="alert-color">
                    <i class='fa-solid fa-octagon-xmark'></i> Verweigert
                    </span>
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    <span v-if='content.data.GrantAccess === "true"' class="success-color">
                    <i class="fa-light fa-circle-check"></i> Zugang gewährt
                    </span>
                    <span v-else class="alert-color">
                     <i class="fa-light fa-triangle-exclamation"></i> {{ content.data.Status }}
                    </span>
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 3 -->
                <div class="fixedwidth-listitem" style="width: 300px">

                  <p class="ck-overflow-ellipsis small">
                    RFID: {{ content.data.Rfid }}
                  </p>

                  <p class="ck-overflow-ellipsis small">
                    Gerät: {{ content.data.DeviceName }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

              <div class="shrink cell">
                <!-- row 4 -->
                <div class="fixedwidth-listitem" style="width: 300px">

                  <p class="ck-overflow-ellipsis small">
                    Standort: {{ content.data.LocationName }}
                  </p>

                  <p class="ck-overflow-ellipsis small hide">
                    Id: {{ content.data.id }}
                  </p>

                </div><!-- /fixedwidth-listitem -->
              </div><!-- /shrink cell -->

            </div><!-- /grid-x sub-->

          </div><!-- /auto cell main -->
          </div><!-- /grid-x main -->

        </div>
        </template>

      </DxDataGrid>
    </div><!-- dx-card checkin-list -->

  </div><!-- content-block -->

  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->


</template>

<script>
import auth from "../auth";
import { apihost } from "../api";

import $ from 'jquery';
import Foundation from 'foundation-sites';

import CustomStore from "devextreme/data/custom_store";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxCheckBox } from 'devextreme-vue/check-box';
import DxDataGrid, { DxPaging, DxColumn, DxScrolling, } from 'devextreme-vue/data-grid';

import { ref } from 'vue';
import notify from 'devextreme/ui/notify';

let currentUser;
let devices = ref([]);
let SerialConnected = ref(new Map());

let filterSearch = ref("");
let filterLocation = ref(0);
let locations = ref([{id: 0, locationname: "Alle Standorte"}]);

let rfidcheck = ref(false);
let contractcheck = ref(false);
let invoicecheck = ref(false);
let signaturecheck = ref(false);

let timeoutId = undefined;

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
let checkinDataSource = new CustomStore({
    key: "id",
    load: (opts) => {
      let params = "?";
      [
        'take',
        'skip',
      ].forEach(i => {
          if(i in opts && isNotEmpty(opts[i])){
            params += `${i}=${JSON.stringify(opts[i])}&`;
          }
        });
      params = params.slice(0, -1);

      if(filterSearch.value != "") {
        params += `&filterSearch=${filterSearch.value}`;
      }

      if(filterLocation.value != 0) {
        params += `&filterLocation=${filterLocation.value}`;
      }

      return fetch(`${apihost}/vue/websocket/list/${params}`)
        .then(r => r.json())
        .then(o => {
          return {
            data: o.data,
            totalCount: o.totalCount,
            summary: []
          };
        })
        .catch(() => { throw 'Network Error' });
    }
});

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-membercheckin", "/#/dashboard");
      window.location.href = redirecturl;
    }

    this.accordionEntrySettings = new Foundation.Accordion($('#accordionEntrySettings'), {
      // These options can be declarative using the data attributes
      slideSpeed: 500,
      multiExpand: false,
      allowAllClosed: true,
    });

    (async () => {
      const response = await fetch(`${apihost}/vue/device/list/`);
      const result = await response.json();
      for(const device of result) {
        const deviceIds = JSON.parse(device.DeviceId);
        for(const deviceId of deviceIds) {
          devices.value.push({
              DeviceName: device.DeviceName,
              LocationName: device.LocationName,
              SerialNumber: deviceId,
          });

          SerialConnected.value.set(deviceId, false);
        }
      }
      this.getConnectionState();
    })();

    (async () => {
      const response = await fetch(`${apihost}/vue/device/rules/get/`);
      const result = await response.json();
      rfidcheck.value = Boolean(result.rfidcheck);
      contractcheck.value = Boolean(result.contractcheck);
      invoicecheck.value = Boolean(result.invoicecheck);
      signaturecheck.value = Boolean(result.signaturecheck);
    })();

    (async () => {
      const response = await fetch(`${apihost}/vue/location/listobj/`); 
      const result = await response.json();
      locations.value = locations.value.concat(result);
    })();

  },
  data() {
    return {
      title: currentUser.Translation.vueappNavMemberCheckin,
      currentUser,
      checkinDataSource,
      devices,
      SerialConnected,
      filterSearch,
      filterLocation,
      locations,
      rfidcheck,
      contractcheck,
      invoicecheck,
      signaturecheck,
    };
  },
  methods: {
    epochToDateTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const dateString = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      const timeString = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      return `${timeString} ${dateString}`;
    },

    sendOpenCommand(serialnumber) {
      const host = window.location.hostname.split('.')[0];
      const clientSocket = new WebSocket(`wss://node.mysportapp.ch/wss/${host}/ctl`);
      const commandObject = {
        cmd: "open",
        target: String(serialnumber),
      };

      clientSocket.addEventListener("close", (e) => {
        if(e.code == 1000) {
          notify("Befehl erfolgreich gesendet.", "success", 3000);
        } else {
          notify("Befehl konnte nicht erfolgreich gesendet werden.", "error", 3000);
        }
      });
      clientSocket.addEventListener("open", () => {
        clientSocket.send(JSON.stringify(commandObject));
      });
    },

    getConnectionState() {
      let result = Array(SerialConnected.value.size, false);
      const host = window.location.hostname.split('.')[0]; 
      const clientSocket = new WebSocket(`wss://node.mysportapp.ch/wss/${host}/ctl`);
      const commandObject = {
        cmd: "checkSerial",
        targets: devices.value.map((d) => d.SerialNumber) 
      };
      clientSocket.addEventListener("open", () => {
        clientSocket.send(JSON.stringify(commandObject));
      });
      clientSocket.addEventListener("message", (e) => {
        try{
          const replyObj = JSON.parse(e.data);
          result = replyObj.result; 
        } catch (e) { console.log(e); }
      });
      clientSocket.addEventListener("close", (e) => {
        if(e.code == 1000){
          devices.value.forEach((device, i) => {
             SerialConnected.value.set(device.SerialNumber, result[i]);
          });
        }
      });
    },

    onFilterChange(thisObj = this) {
      thisObj.$refs.list.instance.refresh(); 
    },

    onSearchChanged() {
      const filterChangeFun = this.onFilterChange;
      if(timeoutId != undefined) {
        clearTimeout(timeoutId);
      } 
      timeoutId = setTimeout(() => filterChangeFun(this), 1000);
    },

    onEntrySettingsChange() {
      console.log(rfidcheck.value, contractcheck.value, invoicecheck.value, signaturecheck.value);
      if(!rfidcheck.value) {
        contractcheck.value = false;
        invoicecheck.value = false;
        signaturecheck.value = false;
      }

      if(!contractcheck.value) {
        invoicecheck.value = false;
        signaturecheck.value = false;
      }
    },

    async submitEntrySettings() {
      let params = "?";
      params += (rfidcheck.value) ? "rfidcheck&" : "";
      params += (contractcheck.value) ? "contractcheck&" : "";
      params += (invoicecheck.value) ? "invoicecheck&" : "";
      params += (signaturecheck.value) ? "signaturecheck&" : "";
      params = params.slice(0, -1);
      const response = await fetch(`${apihost}/vue/device/rules/set/${params}`);
      const result = await response.json();
      rfidcheck.value = Boolean(result.rfidcheck);
      contractcheck.value = Boolean(result.contractcheck);
      invoicecheck.value = Boolean(result.invoicecheck);
      signaturecheck.value = Boolean(result.signaturecheck);
    }
  },
  components: {
    DxTextBox,
    DxSelectBox,
    DxCheckBox,
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxScrolling,
  },
  unmounted() {
    devices.value = [];  
  },
};

</script>
